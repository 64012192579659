<template>     
  <v-dialog v-model="dialog" persistent max-width="400">      
    <v-card>
      <v-card-title class="text-h5">
        Скасувати тарифи?
      </v-card-title>
      <v-card-text class="text-center">Ви впевнені, що хочете скасувати заплановані тарифи?
        <span class="error--text" v-if="is_job">
          <br><br>
          <v-icon color="error" class="mr-1" style="vertical-align: sub;">mdi-information-outline</v-icon>Зверніть увагу: Буде знята заявка на зміну тарифу
      </span>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="deep-orange darken-1"
          text
          @click="closeDialog()"
        >
          Ні
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary lighten-1"
          text
          @click="sendForm"
        >
          Так
        </v-btn>
      </v-card-actions>
    </v-card>   
  </v-dialog>
</template>

<script>
    import axios from "axios"
    //import { mapGetters } from "vuex"
	export default {
    name: 'modal-confirm-cancel-plan-tarif',
    props: ['abonent_id'],
		data: () => ({
            loading:false,
            dialog: false,
            is_job: false
        }),
        methods: {
            show(change_tarif){
                this.dialog = true;
                this.is_job = change_tarif.is_job
            },
            closeDialog (){
                this.dialog = false
                this.is_job = false
            },
            sendForm() {
            this.loading = true;
            axios({
                method: "get",
                url: `/api/service/cancel_change_tarif/${this.abonent_id}/`,
            })
                .then(response => {
                if(response){
                    this.$emit('update-abonent');
                    this.$router.app.$snack.show("Заплановані тарифи скасовано!")
                    this.closeDialog()
                }else{
                    this.$router.app.$snack.show("Помилка скасування запланованих тарифів!", 'deep-orange')
                }
                })
                .catch(err => {
                //console.log(err);
                    this.$router.app.$sheet.show("Помилка!", err);
                })
                .finally(() => ( this.loading = false ))
                
            },
        }
    }
</script>