<template>
  
  <div class="" >
    <keep-alive>
      <AddAbonentModal ref="addAbonent" v-on:update-abonent="updateAbonent += 1" :abonent="abonent"/>
    </keep-alive>
    <keep-alive>
      <ChangePhoneModal ref="changePhone" v-on:update-abonent="updateAbonent += 1" :abonent="abonent"/>
    </keep-alive>
    <keep-alive>
      <AddRatesModal ref="addRates" :abonent="abonent" :checkDoubleTarif="checkDoubleTarif" :service_tv="service_tv" :service_inet="service_inet" v-on:update-abonent="updateAbonent += 1"/>
    </keep-alive>
    <keep-alive>
      <AddRatesDoubleModal ref="addRatesDouble" :abonent="abonent" :checkDoubleTarif="checkDoubleTarif" :service_tv="service_tv" :service_inet="service_inet" v-on:update-abonent="updateAbonent += 1"/>
    </keep-alive>
    <keep-alive>
      <AddRatesInetModal ref="addRatesInet" :user="user" :abonent="abonent" :service_inet_billing="service_inet_billing" :abonentBilling="abonentBilling" :service_inet="service_inet" :updateAbonentBilling="updateAbonentBilling"/>
    </keep-alive>
    <keep-alive>
      <AddServiceModal ref="addService" :service_inet_billing="service_inet_billing" :checkDoubleTarif="checkDoubleTarif" :user="user" :abonent="abonent" :service_tv="service_tv" :service_inet="service_inet" v-on:update-abonent="updateAbonent += 1"/>
    </keep-alive>
    <keep-alive>
      <NoteModalAdd ref="addNotification" />
    </keep-alive>
    <keep-alive>
      <AddOrganizationModal ref="addOrganizationModal" :abonent="abonent" v-on:update-abonent="updateAbonent += 1" />
    </keep-alive>
    <keep-alive>
      <ModalConfirmTransferBalance ref="confirmTransferBalance" :pcode="abonent && abonent.user_pcode" :abonent_id="$route.params.id" v-on:update-abonent="updateAbonent += 1" />
    </keep-alive>
    <keep-alive>
      <ModalConfirmCancelPlanTarif ref="confirmCancelPlanTarif" :abonent_id="$route.params.id" v-on:update-abonent="updateAbonent += 1" />
    </keep-alive>
    <keep-alive>
      <AddMessageModal ref="addMessage"  :abonent="abonent"/>
    </keep-alive>
    <AddCreditModal ref="addCredit" :pcode="abonent && abonent.user_pcode" v-on:update-abonent="updateAbonent += 1" :service_inet_billing="service_inet_billing" :service_tv="service_tv" :service_inet="service_inet"/>
    <ConfirmDialog ref="confirmDialog"/>
    <ConfirmInfoForPlanChangeTarif ref="confirmInfoForPlanChangeTarif" :checkDoubleTarif="checkDoubleTarif" :service_tv="service_tv" :service_inet_billing="service_inet_billing"/>

    <ChangePasswordModal ref="changePassword" :user="user" :abonent="abonent" :abonent_id="abonent && abonent.id" :service_inet="service_inet" v-on:update-abonent="updateAbonent += 1"/>
    
    <v-row>
      <v-col cols="12" md="12" lg="4" class="pr-lg-10">
        
        <v-row :class="{'d-none': false }">
          <v-col cols="12" md="6" lg="12" :class="$vuetify.breakpoint.smAndDown || service_tv && service_inet_billing ? 'pb-0' : ''">
            
            <!-- ---------------- Карточка ТВ --------------------------- -->
            <CardServiceTv  ref="cardServiceTv" :abonent="abonent" :user="user" :checkDoubleTarif="checkDoubleTarif" :service_tv="service_tv" :rates="rates" :service_inet_billing="service_inet_billing" :operators="operators" :connectData="connectData" v-on:update-abonent="updateAbonent += 1"/>
            <!-- ---------------- /Карточка ТВ --------------------------- -->
            <DoubleServiceAction :checkDoubleTarif="checkDoubleTarif" :service_tv="service_tv" :service_inet_billing="service_inet_billing"/>
          </v-col>
          <v-col cols="12" md="6" lg="12" :class="$vuetify.breakpoint.smAndDown ? 'py-0' : ''">
            <!-- ---------------- Карточка Интернет --------------------------- -->
            <CardServiceInet :class="{'d-none': !service_inet_billing && loadingUpdateAbonentBilling}" v-if="abonent" :user="user" :checkDoubleTarif="checkDoubleTarif" :service_inet="service_inet" :service_tv="service_tv" :rates="rates" :operators="operators" :abonentBilling="abonentBilling" v-on:update-abonent-billing="updateAbonentBilling" :loadingUpdateAbonentBilling="loadingUpdateAbonentBilling" :service_inet_billing="service_inet_billing" :service_tv_billing="service_tv_billing"/>
            <!-- ---------------- / Карточка Интернет --------------------------- -->  
            
          
          </v-col>
        </v-row> 
        <v-skeleton-loader
        v-if="false"
          type="article"
        ></v-skeleton-loader>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="12" lg="4">
        <v-row>
          <v-col cols="12" md="6" lg="12" :class="$vuetify.breakpoint.smAndDown ? 'py-0' : ''" >
            <!-- --------------------- Картка Абонента ----------------------------- -->   
            <CardAbonentInfo :user="user" :abonent="abonent" :service_inet="service_inet" :districts="districts"/>
            <!-- --------------------- / Картка Абонента --------------------------- -->
          </v-col>
          <v-col cols="12" md="6" lg="12" :class="$vuetify.breakpoint.smAndDown ? 'py-0' : ''" >
            <!-- --------------------- Картка Абонента ----------------------------- -->   
            <CommentsHouse ref="CommentsHouse" :user="user" :abonent="abonent" />
            <!-- --------------------- / Картка Абонента --------------------------- -->
          </v-col>
          
        </v-row>
      </v-col>
      <v-spacer />
      
      <v-col cols="12" md="12" lg="4" class="pl-lg-10">
        <v-row>
          <v-col cols="12" md="6" lg="12" :class="$vuetify.breakpoint.smAndDown ? 'py-0' : ''" >
            <AbonentMemo :abonent_id="abonent && abonent.id" :abonent_memo="abonent && abonent.note && abonent.note.note || ''" :user="user" v-on:update-abonent="updateAbonent += 1"/>
          </v-col> 
          <v-col cols="12" md="6" lg="12" :class="$vuetify.breakpoint.smAndDown ? 'py-0' : ''" >
            <!-- --------------------- Картка Абонента ----------------------------- -->   
            <CardConnectInet ref="cardConnectInet" :user="user" :abonent="abonent" v-if="service_inet"  :service_inet_billing="service_inet_billing" :connectData="connectData" :realIP="abonentBilling && abonentBilling.real_ip" :loadingGetAbonentStatusConnect="loadingGetAbonentStatusConnect" v-on:get-status-connect="getAbonentStatusConnect"/>
            <!-- --------------------- / Картка Абонента --------------------------- -->
          </v-col>
          <!--<v-col cols="12" md="6" lg="12" >
            <HouseMemo :house_memo="abonent && abonent.house_note && abonent.house_note.note || ''" :abonent="abonent" :user="user" v-on:update-abonent="updateAbonent += 1"/>
          </v-col>--> 
        </v-row>
      </v-col>
    </v-row>
   
  </div>

  
</template>
<script>
import axios from "axios"
import {mapGetters} from 'vuex'
import AddAbonentModal from '@/components/Abonents/AddAbonentModal.vue'
import ChangePhoneModal from '@/components/Abonents/ChangePhoneModal.vue'
import AddRatesModal from '@/components/AbonentInfo/AddRatesModal.vue'
import AddRatesDoubleModal from '@/components/AbonentInfo/AddRatesDoubleModal.vue'
import AddRatesInetModal from '@/components/AbonentInfo/AddRatesInetModal.vue'
import CardServiceTv from '@/components/AbonentInfo/TabInfoCards/CardServiceTv.vue'
import CardServiceInet from '@/components/AbonentInfo/TabInfoCards/CardServiceInet.vue'
import CardAbonentInfo from '@/components/AbonentInfo/TabInfoCards/CardAbonentInfo.vue'
import CardConnectInet from '@/components/AbonentInfo/TabInfoCards/CardConnectInet.vue'
import ChangePasswordModal from '@/components/AbonentInfo/ChangePasswordModal.vue'
import AddServiceModal from '@/components/AbonentInfo/AddServiceModal.vue'
import AbonentMemo from '@/components/AbonentInfo/TabInfoCards/AbonentMemo.vue'
import CommentsHouse from '@/components/AbonentInfo/TabInfoCards/CommentsHouse.vue'
import NoteModalAdd from '@/components/Notifications/NoteModalAdd.vue'
import AddOrganizationModal from '@/components/AbonentInfo/AddOrganizationModal.vue'
import ModalConfirmTransferBalance from '@/components/AbonentInfo/ModalConfirmTransferBalance.vue'
import ModalConfirmCancelPlanTarif from '@/components/AbonentInfo/ModalConfirmCancelPlanTarif.vue'
import AddCreditModal from '@/components/AbonentInfo/AddCreditModal.vue'
import DoubleServiceAction from '@/components/AbonentInfo/TabInfoCards/DoubleServiceAction.vue'
import ConfirmInfoForPlanChangeTarif from '@/components/AbonentInfo/TabInfoCards/ConfirmInfoForPlanChangeTarif.vue'
import AddMessageModal from '@/components/AbonentInfo/AddMessageModal.vue'





//import HouseMemo from '@/components/AbonentInfo/TabInfoCards/HouseMemo.vue'
export default {
  name: 'abonent-tab-info',
  props: ["abonent", "overlay"],
  components: {
    AddAbonentModal,
    ChangePhoneModal,
    AddRatesModal,
    AddRatesDoubleModal,
    AddRatesInetModal,
    AddServiceModal,
    AbonentMemo,
    ChangePasswordModal,
    CardServiceTv,
    CardServiceInet,
    CardAbonentInfo,
    CommentsHouse,
    //HouseMemo,
    CardConnectInet,
    NoteModalAdd,
    AddOrganizationModal,
    ModalConfirmTransferBalance,
    ModalConfirmCancelPlanTarif,
    AddCreditModal,
    DoubleServiceAction,
    ConfirmInfoForPlanChangeTarif,
    AddMessageModal
  },
  data: () => ({
    updateAbonent: 0,
    abonentBilling: {},
    abonentBillingDataError: '',
    loadingUpdateAbonentBilling: false,
    connectData: {},
    connectDataError: '',
    loadingGetAbonentStatusConnect: false,
  }),
  computed: {
      ...mapGetters({
        rates: "filters/rates",
        //streets: "streets/streets"
        user: "auth/user",
        operators: "filters/operators",
        districts: "filters/districts",
      }),
      service_tv() {
        return this.abonent?.user_service?.find(x => x.service == 1)
      },
      service_inet() {
        return this.abonent?.user_service?.find(x => x.service == 2)
      },
      service_inet_billing() {
        return this.abonentBilling?.user_service?.find(x => x.service == 2)
      },
      service_tv_billing() {
        return this.abonentBilling?.services?.find(x => x.service == 1)
      },
      checkDoubleTarif(){
        return this.service_tv && this.service_inet_billing && this.service_tv.tarif.id == this.service_inet_billing?.tarif?.id && this.rates.find(x => x.id == this.service_tv.tarif.id).speed != 0
      },
  },
  mounted (){
    this.$nextTick(function () {
      // 
    })
  },
  created() {
    
  },
  watch: {
    updateAbonent(){
      this.processUpdateAbonent = this.updateAbonent &&  true
      this.updateAbonent && this.$emit('update-abonent') 
    },
    abonent(){
      //console.log(this.abonent)
      this.abonent?.username && this.updateAbonentBilling()  // Отримуємо дані послуги інтернет абонента
      this.abonent?.user_pcode && this.getAbonentStatusConnect() // Отримуємо дані авторизації абонента 
    }
  },
  activated() {
    if(!this.abonent || !this.$parent.active_component){
      this.connectData = {}
      this.abonentBilling = {}
    }

    //console.log("id", this.$route.params.id)
    //console.log('activated abonent card tab info')
    this.$emit('update-abonent')

  },
  deactivated(){
    //
  },
  methods: {
    getAbonentStatusConnect(){
      if(!this.abonent.username){return}
      if(!this.service_inet){return}
      this.loadingGetAbonentStatusConnect = true
      axios({
        method: "get",
        url: this.$router.app.devUrlBillingAPI,
        params: {req_cmd: "getUserStatusConnect", pcode: this.abonent.user_pcode }
      })
        .then(response => {
          //console.log(response)
          this.connectDataError = response.data.resp_errmsg
          if(response.data.resp_status == "OK"){
            this.connectData = response.data.resp_result[0]
          }else{
            this.connectData = {}
          }
        })
        .catch(err => {
          //console.log(err);
          this.$router.app.$sheet.show("Помилка!", err);
        })
        .finally(() => (this.loadingGetAbonentStatusConnect = false))
    },
    updateAbonentBilling(){
      if(!this.abonent.username){return}
      if(!this.service_inet){return}
      this.loadingUpdateAbonentBilling = true
      axios({
        method: "get",
        url: this.$router.app.devUrlBillingAPI,
        params: {req_cmd: "getUserInfo", pcode: this.abonent?.user_pcode}
      })
        .then(response => {
          //console.log(response)
          this.abonentBillingDataError = response.data.resp_errmsg
          if(response.data.resp_status == "OK"){
            this.abonentBilling = response.data.resp_result[0]
          }else{
            this.abonentBilling = {}
          }
        })
        .catch(err => {
          //console.log(err);
          this.$router.app.$sheet.show("Помилка!", err);
        })
        .finally(() => (this.loadingUpdateAbonentBilling = false))
    }
    
  }
}
</script>