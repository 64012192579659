<template>     
  <v-dialog v-model="dialog" persistent max-width="330">      
    <v-card>
      <v-card-title class="text-h5 text-center">
        Видалити організацію?
      </v-card-title>
      <v-card-text class="text-center">Ви впевнені, що хочете видалити дані про організацію абонента?</v-card-text>
      <v-card-actions>
        <v-btn
          color="deep-orange darken-1"
          text
          @click="closeDialog()"
        >
          Ні
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary lighten-1"
          text
          @click="sendForm"
        >
          Так
        </v-btn>
      </v-card-actions>
    </v-card>   
  </v-dialog>
</template>

<script>
    import axios from "axios"
    //import { mapGetters } from "vuex"
	export default {
    name: 'modal-confirm-delete-organization',
    props: ['organization'],
		data: () => ({
            loading:false,
            dialog: false,
        }),
        methods: {
            show(){
                this.dialog = true;
            },
            closeDialog (){
                this.dialog = false
            },
            sendForm() {
            this.loading = true;
            axios({
                method: "delete",
                url: `/api/cabletv/user_company/${this.organization?.id}/`,
            })
                .then(response => {
                if(response){
                    this.$emit('delete-organization');
                    this.$router.app.$snack.show("Організацію видалено")
                    this.closeDialog()
                }else{
                    this.$router.app.$snack.show("Помилка видалення огранізації", 'deep-orange')
                }
                })
                .catch(err => {
                //console.log(err);
                    this.$router.app.$sheet.show("Помилка!", err);
                })
                .finally(() => ( this.loading = false ))
                
            },
        }
    }
</script>