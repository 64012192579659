<template>
        
        <v-dialog v-model="dialog" persistent max-width="500">
            
            <v-card>
            <v-card-title class="d-flex justify-space-between">
            <span class="headline ">Додати оплату </span>
            <v-icon class="ml-3" @click="$refs.infoTypePaysDialog.open()">mdi-information-outline</v-icon>
            </v-card-title>
            
            <v-card-text>
                
            <v-container class="mt-6">
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>       
                        <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                            <v-select v-if="field.type == 'select'"
                            dense
                            outlined
                            hide-details="auto"
                            :label="field.title"
                            v-model="form_data[field.name]"
                            :name="field.name"
                            :rules="field.rules && textRules || notRules"
                            :items="field.values"
                            item-text="name"
                            item-value="id"
                            :disabled="field.disabled"
                            >
                            </v-select>

                            <v-textarea v-else-if="field.type == 'textarea'"
                                dense
                                rows="3"
                                outlined
                                hide-details="auto"
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :name="field.name"
                                :rules="field.rules && textRules || notRules"
                                :type="field.type"
                                :disabled="field.disabled"
                            ></v-textarea>
                            
                            <v-text-field v-else
                            dense
                            outlined
                            hide-details="auto"
                            :label="field.title"
                            v-model="form_data[field.name]"
                            :name="field.name"
                            :rules="field.rules && textRules || notRules"
                            :type="field.type"
                            :disabled="field.disabled"
                            >
                            </v-text-field>
                        
                        </v-col>
                            
                        
                           
                        
                    
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Додати</v-btn>
            </v-card-actions>
        </v-card>
        <InfoTypePaysDialog ref="infoTypePaysDialog"/>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    import { mapGetters } from "vuex"
    export default {
        name: 'add-pays-modal',
        components: {
            InfoTypePaysDialog: () => import('@/components/Pays/InfoTypePaysDialog.vue'),
        },
        data: () => ({
            abonent: null,
            loadingSubmit:false,
            changeForm: false,
            dialog: false,
            valid: true,
            form_data: {},
            fields: [
                {title: "Дата",     name: "date_raw",     rules: true,  type: "date",      disabled: false, value: "" },
                {title: "Сума",    name: "amount",     rules: true,  type: "number",    disabled: false, value: "",},
                {title: "Тип",      name: "type_pay", rules: true,  type: "select",    disabled: false, value: "", values: []},
                {title: "Послуга",  name: "service", rules: true,  type: "select",    disabled: false, value: "", values: []},
                {title: "Примітки", name: "memo",     rules: false,  type: "textarea", disabled: false, value: "" },
               
            ],
            
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            ...mapGetters({
                pays_type: "filters/pays_type",
                job_service: "job_types/service",
            }),
        },
        mounted(){
            this.fields.find(x => x.name == "type_pay").values = this.pays_type.filter(x => x.mask !=0 && x)
            this.fields.find(x => x.name == "service").values = this.job_service

        },
        methods: {
            show(abonent, inetPays){
                this.abonent = abonent
                console.log(inetPays)
                this.dialog = true;
                this.form_data.amount = null
                this.form_data.type_pay = 2
                this.form_data.service = inetPays && 2 || 1
                this.form_data.date_raw = new Date().toDateInputValue()
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                this.changeForm = false
                
            },
            sendForm(){
              if(this.$refs.form.validate()){
                let time = new Date().toLocaleTimeString().split(":")
                this.form_data.date = `${this.form_data.date_raw} ${time[0]}:${time[1]}:00`
                if(this.form_data.service == 1){
                  this.addPays()
                }else this.addPaysBilling()
              }
              
            },
            addPays() {
                //console.log(this.form_data)
                
                    this.loadingSubmit = true;
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "POST",
                        url: `/api/cabletv/get_user/${this.$route.params.id}/recive_pays/`,
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-pays');
                        this.$emit('update-total');
                        this.$router.app.$snack.show("Платіж створено");
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                
            },
            addPaysBilling(){
                this.loadingSubmit = true 
                
                axios({
                    method: "post",
                    url: this.$router.app.devUrlBillingAPI,
                    data: {req_cmd: "addPayment", pcode: this.abonent.user_pcode, ...this.form_data }
                })
                .then(response => {
                  if(response.data.resp_status == "OK"){
                      this.$emit('update-pays');
                      this.$emit('update-total');
                      this.$router.app.$snack.show("Платіж створено");
                      this.closeDialog()
                  }else if(response.data.resp_status == "REJECT"){
                    this.$router.app.$snack.show(response.data.resp_errmsg, 'deep-orange')
                  }else{
                      this.$router.app.$snack.show("Помилка додавання платежа", 'deep-orange')
                  }
                  })
                  .catch(err => {
                  //console.log(err);
                      this.$router.app.$sheet.show("Помилка! (billing)", err);
                  })
                  .finally(() => ( this.loadingSubmit = false ))
              
            },
        }
    }
</script>