<template>
        
    <v-dialog v-model="dialog" persistent max-width="800">      
      <v-card>
        <v-card-title class="">
        <span class="headline">Зміна Тарифу Інтернет</span>
        </v-card-title>
        <v-alert
          v-if="conflictDataError"
          rounded="0"
          class="my-0 mb-1"
          dense
          type="warning"
        >
          {{ conflictDataError }}
        </v-alert>
        <v-card-text>
        <v-container class="">
          <v-form 
          ref="form"
          v-model="valid"
          lazy-validation>
              <v-row>
                <FieldsChangeTarif ref="nowMonth"  month="now" title="ПОТОЧНИЙ МІСЯЦЬ" :tarif="service_inet_billing && service_inet_billing.tarif" :operator_id="service_inet_billing && service_inet_billing.operator" v-on:change-tarif-data="isChangeNowTarif = true, $refs.nextMonth.showBtnSpliceTarifs = true" :parent_dialog="dialog"/>
                <FieldsChangeTarif ref="nextMonth" month="next" title="НАСТУПНИЙ МІСЯЦЬ" :tarif="service_inet_billing && service_inet_billing.tarif_next" :operator_id="service_inet_billing && service_inet_billing.operator" v-on:change-tarif-data="isChangeNextTarif = true" v-on:splice-tarifs="spliceTarifs()" :parent_dialog="dialog"/>
              </v-row>
          </v-form>
        </v-container>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
            <v-spacer />
            <v-btn color="primary lighten-1" text @click="show()" v-if="isChangeNowTarif || isChangeNextTarif">Скинути</v-btn>
            <v-spacer />
            <v-btn :loading="loadingSubmit" :disabled="!isChangeNowTarif && !isChangeNextTarif"
            color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Зберегти</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

  import axios from "axios"
  import FieldsChangeTarif from '@/components/AbonentInfo/FieldsChangeTarif'
  export default {
    components:{
      FieldsChangeTarif
    },
    name: 'add-rates-internet-modal',
    props: ["user", "abonent", "service_inet_billing", "abonentBilling", "service_inet", "updateAbonentBilling"],
    data: () => ({
          isChangeNowTarif: false,
          isChangeNextTarif: false,
          conflictDataError: null,
          loadingSubmit:false,
          dialog: false,
          valid: true,
          rates: [],
        }),
        computed: {
           //
        },
        mounted(){
          //console.log("mounted modal Change Tarif")
        },
        methods: {
            show(){
              this.conflictDataError = null
              this.isChangeNowTarif = false
              this.isChangeNextTarif = false
              if(this.$refs.nowMonth && this.$refs.nextMonth){
                  this.$refs.nowMonth.show(this.rates)
                  this.$refs.nextMonth.show(this.rates)
                }
              this.loadingSubmit = false
              
              this.dialog = true
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog(){
                this.dialog = false
                this.reset() 
                this.conflictDataError = null
                
            },
            sendForm() {

              if(this.$refs.form.validate()){
                this.loadingSubmit = true;
                axios({
                  method: "post",
                  url: this.$router.app.devUrlBillingAPI,
                  data: {req_cmd: "changeUserTariff", pcode: this.abonent?.user_pcode, arg: this.getDataTarifs() }
                })
                .then(response => {
                  console.log(response)
                  if(response.data.resp_status == "OK"){
                    this.$router.app.$snack.show("Тариф змінено!")
                    this.closeDialog()
                  }else if(response.data.resp_status == "REJECT"){
                    if(response.data.resp_err_conflict){
                        this.conflictDataError = response.data.resp_err_conflict
                    }else this.$router.app.$sheet.show("Помилка!", response.data.resp_errmsg)
                  }
                  else this.$router.app.$snack.show("Помилка зміни тарифу", 'deep-orange')
                  
                })
                .catch(err => {
                  //console.log(err);
                  this.$router.app.$sheet.show("Помилка!", err);
                })
                .finally(() => (this.loadingSubmit = false, this.updateAbonentBilling()))
                
              } 
            }, 
            spliceTarifs(){
              // Преносимо дані тарифу поточного місяця на тариф наступного
              this.$refs.nextMonth.discountActive = !!this.$refs.nowMonth.discountActive
              this.$refs.nextMonth.form_data = JSON.parse(JSON.stringify(this.$refs.nowMonth.form_data))
              this.isChangeNextTarif = true
            },
            getDataTarifs(){
              let params = {}
              if(this.isChangeNowTarif){
                params['now_month'] = this.$refs.nowMonth.form_data
              }
              if(this.isChangeNextTarif){
                params['next_month'] = this.$refs.nextMonth.form_data
              }
              return params
            }
        }
    }
</script>