<template>
        
        <v-dialog v-model="dialog" persistent max-width="500">
            
            <v-card :loading="loading">
            <v-card-title class="">
            <span class="headline">Нове нагадування</span>
            </v-card-title>
            
            <v-card-text>
             
                <v-form 
                            ref="form_notification"
                            v-model="valid"
                            lazy-validation>
                                <v-row class="mt-4">  

                                    <v-col  key="notification-date" cols="12" class="py-2">
                                            <v-text-field 
                                            dense
                                            outlined
                                            hide-details
                                            label="Дата"
                                            v-model="form_data.date"
                                            type="date"
                                            :rules="textRules || notRules"
                                            >
                                            </v-text-field>
                                        </v-col> 
                                        <v-col  key="notification-operator" cols="12" class="py-2" v-if="type_modal == 'create'">
                                            <v-select
                                            dense
                                            outlined
                                            hide-details="auto"
                                            label="Оператор"
                                            v-model="form_data.user"
                                            :rules="textRules || notRules"
                                            :items="operators_groups"
                                            name="notification-operator"
                                            item-text="name"
                                            item-value="id"
                                            
                                            >
                                            </v-select>    
                                        </v-col>
                                        <v-col  key="notification-for-user" cols="12" class="py-2">
                                            <v-select
                                            dense
                                            outlined
                                            hide-details="auto"
                                            label="Персонально"
                                            v-model="form_data.for_user"
                                            clearable
                                            :items="users_not_monter"
                                            name="notification-for-user"
                                            item-text="name"
                                            item-value="id"
                                            
                                            >
                                            </v-select>    
                                        </v-col>
                                        
                                        


                                        <v-col  key="notification-text" cols="12" class="py-2">
                                            <v-textarea 
                                                dense
                                                rows="3"
                                                outlined
                                                hide-details="auto"
                                                label="Нагадати про:"
                                                v-model="form_data.reminder_text"
                                                name="notification-date"
                                                :rules="textRules || notRules"
                                                type="textarea"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col  key="notification-stars" cols="12" class="py-2" >
                                            <div class="text-center">
                                            <v-rating
                                                title="Приорітет нагадування"
                                                v-model="form_data.stars"
                                                color="warning"
                                                background-color="grey"
                                                half-increments
                                                hover
                                                length="5"
                                                size="30"
                                                dense
                                            ></v-rating>
                                            </div>
                                        </v-col>
                                        
                                    
                                </v-row>
                        </v-form>
                
            
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Створити</v-btn>
                
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import axios from "axios"
    import { mapGetters } from "vuex"
	export default {
        name: 'note-modal-add',
		data: () => ({
            type_modal: "create",
            loading:false,
            dialog: false,
            valid: true,
            loadingSubmit: false,
            form_data: {},
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        components:{
            //
        },
        computed: {
            ...mapGetters({
                operators_groups: "filters/operators_groups",
                users: "users/users",
            }),
            users_not_monter(){
                return this.users.filter(x => x.user_type == 1 || x.user_type == 3 || x.user_type == 5)
            }
        },
        watch:{
            //
        },
        methods: {
            show(type, item=false){
                this.item = item && item || false
                this.dialog = true;
                this.type_modal = type
                this.form_data.date = new Date().toDateInputValue()
            }, 
            closeDialog (){
                this.reset() 
                this.dialog = false
            },
            validate () {
                if (this.$refs.form_notification.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.form_data = {}
                this.$refs.form_notification && this.$refs.form_notification.reset();
            },
            sendForm() {
                
                if(this.$refs.form_notification.validate()){

                    if(this.type_modal == "add"){
                        this.form_data.user = this.item.id
                    }
                    
                    this.loadingSubmit = true;
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "POST",
                        url: "/api/reminder/get_reminders/",
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-notification');
                        this.$router.app.$snack.show("Нагадування створено!");
                        this.closeDialog()
                        this.$store.dispatch('notifications/fetchCountNotifications');
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                }
            },
        }
    }
</script>