const streetOldKtk = {
    796: 11, // Невская
    883: 13, // Парижская
    1415: 26, // Шостаковича
    476: 21, // Игнатьева
    611: 19, // Кремлевская
    987: 18, // Рзянкина
    1492: 10, // Нарвская
    1194: 27, // Тухачевского
    1740: 29, // Серго
    1102: 23, // Станюкевича
    1032: 16, // Свирская
    177: 2, // Булды
    243: 5, // Вишневского
    //'гал': 3, // Галана
    1444: 28, // Электрическая
    //'сл': 22, // 
    260: 6, // Вологодская
    776: 9, // Музычная
    567: 7, // Комсомольская
    819: 14, // Ногина
    1163: 8, // Товстоновскаго
    983: 15, // Ревуцкого
    1204: 4, // Угорская
    1254: 25,  // Фасадная
    578: 12, // Коротченко
    903: 17, // Петроградская
    1130: 24, // Таврическая
    1091: 20} // Социалистическая

    export default streetOldKtk