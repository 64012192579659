<template>
        
    <v-dialog v-model="dialog" persistent max-width="500">
        
        <v-card :loading="loadingSubmit">
            <v-card-title class="">
              <span class="headline ">{{ modalData.title }}</span>
            </v-card-title>
            
            <v-card-text> 
              <v-container class="mt-6">
                  <v-form 
                  ref="form"
                  v-model="valid"
                  lazy-validation>
                    <v-row>     
                      <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                          <v-text-field 
                          dense
                          outlined
                          hide-details="auto"
                          :label="field.title"
                          v-model="form_data[field.name]"
                          :name="field.name"
                          :rules="field.rules && textRules || notRules"
                          :type="field.type"
                          :disabled="loadingSubmit"
                          required
                          >
                          </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <span ><br>* Дані нарахувань за послуги не будуть передаватись у Нова-Ком</span>
              </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <template v-if="typeForm == 'change'">
                    <v-btn color="deep-orange lighten-1" text @click="$refs.confirmDelete.show()">Видалити</v-btn>
                    <v-spacer />
                </template>
                <v-btn :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">{{ modalData.btn_text}}</v-btn>
            </v-card-actions>
        </v-card>
        <ModalConfirmDeleteOrganization ref="confirmDelete" :organization="abonent && abonent.organization" v-on:delete-organization="deleteOrganization()" />
    </v-dialog>
</template>

<script>

    import axios from "axios"
    export default {
        name: 'add-organization-modal',
        props: ['abonent'],
        components:{
            ModalConfirmDeleteOrganization: () => import('@/components/AbonentInfo/ModalConfirmDeleteOrganization.vue')
        },
        data: () => ({
            typeForm: 'add',
            loadingSubmit:false,
            item: {},
            dialog: false,
            valid: true,
            form_data: {},
            modalData: {},
            defaultModalData: {
                add: {title: "Додати організацію", method: "POST", url: "/api/cabletv/user_company/", readonly: false, message: "Організацію додано!", btn_text: 'Додати'},
                change: {title: "Редагування організації", method: "PATCH", url: "/api/cabletv/user_company/", readonly: true, message: "Данні організації змінено!", btn_text: 'Змінити'}
            },
            fields: [
                {title: "Назва", name: "name",  rules: true,  type: "text", value: "" },
                {title: "Юридична адреса", name: "organization_address",  rules: false,  type: "text", value: "" },
                {title: "Р/p", name: "bank_account",  rules: false,  type: "", value: "" },
                {title: "МФО банку", name: "mfo",  rules: false,  type: "text", value: "" },
                {title: "ЄДРПОУ", name: "edrpou",  rules: false,  type: "text", value: "" },
                {title: "E-mail", name: "email",  rules: false,  type: "e-mail", value: "" },
            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            //
        },
        mounted(){
            this.modalData = JSON.parse(JSON.stringify(this.defaultModalData.add))
        },
        methods: {
            show(type){
                this.typeForm = type
                if(type == 'change'){
                    this.modalData = JSON.parse(JSON.stringify(this.defaultModalData.change))
                    this.form_data = JSON.parse(JSON.stringify(this.abonent.organization))
                }
                this.dialog = true;
            },
            validate () {
              if (this.$refs.form.validate()) {
                  this.snackbar = true;
              }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset()
                this.modalData = JSON.parse(JSON.stringify(this.defaultModalData.add))
                this.form_data = {}
            },
            sendForm(){
              if(this.$refs.form.validate()){
                this.loadingSubmit = true
                axios({
                    method: this.modalData.method,
                    url: `${this.modalData.url}${this.typeForm == 'change' && this.abonent.organization.id + '/' || ''}`,
                    data: {...this.form_data, user: this.abonent.id }
                })
                  .then(response => {
                  if(response){
                      this.$emit('update-abonent');
                      this.$router.app.$snack.show(this.modalData.message)
                      this.closeDialog()
                  }else{
                      this.$router.app.$snack.show("Помилка", 'deep-orange')
                  }
                  })
                  .catch(err => {
                  //console.log(err);
                      this.$router.app.$sheet.show("Помилка!", err);
                  })
                  .finally(() => ( this.loadingSubmit = false ))
                }
            },
            deleteOrganization(){
                this.$emit('update-abonent')
                this.closeDialog()
            }
        }
    }
</script>