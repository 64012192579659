/*const codeReason = {
    1: "User Request. User initiated the disconnect (logout).",
    2: "Lost Carrier. DCD was dropped on the port.",
    3: "Lost Service. Service can no longer be provided; for example, the user’s connection to a host was interrupted.",
    4: "Idle Timeout. Idle timer expired.",
    5: "Session Timeout. Subscriber reached the maximum continuous time allowed for the service or session.",
    6: "Admin Reset. System administrator reset the port or session.",
    7: "Admin Reboot. System administrator terminated the session on the NAS; for example, prior to rebooting the NAS.",
    8: "Port Error. NAS detected an error on the port that required ending the session.",
    9: "NAS Error. NAS detected an error (other than on the port) that required ending the session.",
    10: "NAS Request. NAS ended the session for a non-error reason.",
    11: "NAS Reboot. NAS ended the session due to a non-administrative reboot.",
    12: "Port Unneeded. NAS ended the session because the resource usage fell below the low threshold; for example, the bandwidth-on-demand algorithm determined that the port was no longer needed.",
    13: "Port Preempted. NAS ended the session to allocate the port to a higher-priority use.",
    14: "Port Suspended. NAS ended the session to suspend a virtual session.",
    15: "Service Unavailable. NAS was unable to provide the requested service.",
    16: "Callback. NAS is terminating the current session in order to perform callback for a new session.",
    17: "User Error. Error in the user input caused the session to be terminated.",
    18: "Host Request. Login host terminated the session normally."
}*/
const codeReasonUkr = {
    1: "Запит користувача. Користувач ініціював відключення (вихід).",
    2: "Загублений перевізник. DCD був скинутий на порт.",
    3: "Втрачена служба. Послуга більше не надається; наприклад, з'єднання користувача з хостом було перервано.",
    4: "Час простою. Таймер простою минув.",
    5: "Час очікування сеансу. Абонент досяг максимального безперервного часу, дозволеного для послуги або сеансу.",
    6: "Скидання адміністратора. Системний адміністратор скидає порт або сеанс.",
    7: "Перезавантаження адміністратора. Системний адміністратор припинив сеанс на NAS; наприклад, перед перезавантаженням NAS.",
    8: "Помилка порту. NAS виявив помилку на порту, яка вимагає завершення сеансу.",
    9: "Помилка NAS. NAS виявив помилку (окрім порту), яка вимагала завершення сеансу.",
    10: "Запит NAS. NAS завершив сеанс через причину, не пов’язану з помилкою.",
    11: "Перезавантаження NAS. NAS завершив сеанс через неадміністративне перезавантаження.",
    12: "Порт непотрібний. NAS завершив сеанс, оскільки використання ресурсу впало нижче низького порогу; наприклад, алгоритм пропускної здатності на вимогу визначив, що порт більше не потрібен.",
    13: "Порт упереджено. NAS завершив сеанс для призначення порту для використання з вищим пріоритетом.",
    14: "Порт призупинено. NAS завершив сеанс, щоб призупинити віртуальний сеанс.",
    15: "Послуга недоступна. NAS не зміг надати запитану послугу.",
    16: "Зворотний дзвінок. NAS завершує поточний сеанс, щоб виконати зворотний виклик для нового сеансу.",
    17: "Помилка користувача. Помилка під час введення користувачем призвела до завершення сеансу.",
    18: "Запит хоста. Хост входу завершив сеанс нормально."
}


export default codeReasonUkr