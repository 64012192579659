<template> 
    <v-card id="card-comments-house" class="mb-4" outlined elevation="0" :loading="loading">
      <keep-alive>
        <ModalAddCommentHouseSet ref="addCommentHouseSet" v-on:update-job="updateCommentHouse"/>
      </keep-alive>    
      <keep-alive>
        <ModalChangeCommentHouseSet ref="changeCommentHouseSet" v-on:update-job="updateCommentHouse"/>
      </keep-alive>  
      <v-toolbar
          color="secondary lighten-1"
          flat dark
          dense>
            <v-toolbar-title ><h4 class="mr-6 accent--text">Коментарі будинку</h4></v-toolbar-title>
            <v-spacer />
            <v-btn 
              :color="showDeleteComment ? 'accent' : 'grey'"
              :title="showDeleteComment ? 'Приховати видалені' : 'Показати видалені'"
              icon  
              @click="showDeleteComment = !showDeleteComment"
            >
              <v-icon>mdi-delete-empty</v-icon>
            </v-btn>
            <v-btn 
              color="accent"
              icon  
              @click="$refs.addCommentHouseSet.show({street_name: abonent.street.street, street: abonent.street.id, house: abonent.house, letter: abonent.letter})"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn 
              :loading="loading"
              color="accent"
              icon  
              @click="updateCommentHouse"
            >
              <v-icon>autorenew</v-icon>
            </v-btn>
            <v-btn
              v-if="$vuetify.breakpoint.smAndDown"
              color="accent"
              icon
              @click="showCommentHouse = !showCommentHouse"
            >
              <v-icon>{{ showCommentHouse && 'mdi-chevron-up' || 'mdi-chevron-down'}}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text v-if="showCommentHouse">
            <HouseCommentsDetails v-on:update-comment="updateCommentHouse()" :commentsHouse="house_comments" :showDeleteComment="showDeleteComment"/>
            <div v-if="house_comments.length == 0" class="text-center">
              <span>Коментарі відсутні</span>
            </div>
            
          </v-card-text>

            
            <v-overlay :value="loading" absolute >
              <v-progress-circular
                indeterminate
                size="48"
              ></v-progress-circular>
            </v-overlay>
        </v-card> 
        
</template>

<script>
import axios from "axios"
import HouseCommentsDetails from '@/components/Job/HouseCommentsDetails.vue'
import ModalAddCommentHouseSet from '@/components/Job/ModalAddCommentHouseSet.vue'
import ModalChangeCommentHouseSet from '@/components/Job/ModalChangeCommentHouseSet.vue'


export default {
  props: ["user", "abonent"],
  data: () => ({
    showDeleteComment: false,
    showCommentHouse: true,
    loading: false,
    house_comments: []
  }),
  components:{
    HouseCommentsDetails,
    ModalAddCommentHouseSet,
    ModalChangeCommentHouseSet
  },
  computed: {
      //
  },
  mounted(){
    //console.log("Mounted CardConnectInet")
  },
  watch:{
    abonent(){
      this.abonent?.street?.id && this.updateCommentHouse() 
    }
  },
  activated(){
    this.house_comments =[]
  },
  
  methods:{
    //updateCommentHouse(){
      //this.$emit("update-status-connect")
    //},
    updateCommentHouse(){
      this.loading = true
      axios.get(`/api/service/house_comments/?street=${this.abonent.street.id}&house=${this.abonent.house}&letter=${this.abonent.letter}`)
      .then( response =>{
          //console.log(response.data) 
          this.house_comments = response.data.results
      })
      .catch(() => {
          //
      })
      .finally(() => (this.loading = false));
            }
    
  },
  
}
</script>