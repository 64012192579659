<template>
<div>
    <v-card class="mb-4" outlined elevation="0">
        <v-toolbar
          color="secondary lighten-1"
          flat
          dense>
            <v-toolbar-title><h4 class="mr-6 accent--text">Примітки</h4></v-toolbar-title>
            <v-spacer />
            
            <v-btn 
            v-if="user && user.user_type != 2 && changeMemo"
            @click="saveMemo" color="accent primary--text"  small>
            Зберегти
          </v-btn>
          <v-btn
              v-if="$vuetify.breakpoint.smAndDown"
              icon
              color="accent"
              @click="showCard = !showCard"
            >
              <v-icon>{{ showCard && 'mdi-chevron-up' || 'mdi-chevron-down'}}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-textarea
            v-if="showCard"
            class="memo_abonent"
            :loading="loading"
            rows="9"
            solo
            v-model="memo"
            hide-details
            name="input-7-4"
            label=""
            elevation="0"
          ></v-textarea>
        </v-card>
        <!--<v-row justify="center" class="my-5">
          <v-btn 
            v-if="user && user.user_type != 2"
            @click="saveMemo" color="primary lighten-1" class="mx-2" small>
            Зберегти
          </v-btn>
        </v-row>-->
    </div>
</template>

<script>
import axios from "axios"
export default {
    name: 'abonent-memo',
    props: ["abonent_memo", "abonent_id", 'user'],
    data: () => ({
        showCard:true,
        changeMemo: false,
        memo: '',
        loading: false
    }),
    activated(){
      this.memo = this.abonent_memo
      this.changeMemo = false
    },
    watch: {
        abonent_memo(val){
          //console.log(val)
          this.memo = val
        },
        memo(){
          this.changeMemo = this.abonent_memo != this.memo && true || false
        }
    },
    methods:{
      async saveMemo(){
        this.loading = true  
        await axios({
            method: "post",
            headers: { "Content-type": "application/json; charset=UTF-8" },
            url: `/api/cabletv/get_user/${this.abonent_id}/note/`,
            data: {"note": this.memo,}
          })
            .then(() => {
              //console.log(response)
              this.$emit('update-abonent');
              this.changeMemo = false
              this.$router.app.$snack.show("Збережено")
            })
            .catch(err => {
              //console.log(err); 
              this.$router.app.$sheet.show("Помилка!", err);
            })
            .finally(() => (this.loading = false))
      }
    }
}
</script>
<style lang="css" scoped>
  .memo_abonent >>> textarea{
    font-size: 14px;
    line-height: 20px;
    /*color: #303030*/;
  }
  
</style>