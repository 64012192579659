<template>
    <div>
        <span v-if="service_tv && service_inet_billing" class="d-flex">
            <v-spacer />
            <v-btn
            v-if="checkDoubleTarif"
            :color="$vuetify.theme.dark ? 'grey lighten-1' : 'grey darken-2'"
            small
            class="mt-3"
            :class="{'mb-4 mt-0': $vuetify.breakpoint.smAndDown}"
            text
            
            
            @click="splitDoubleService()"
            >
                <v-icon small left>mdi-arrow-expand</v-icon>
                Розділити послуги
            </v-btn>
            <v-btn
            v-else-if="service_tv && service_inet_billing"
            :color="$vuetify.theme.dark ? 'primary lighten-1' : 'primary'"
            small
            class="mt-3"
            :class="{'mb-4 mt-0': $vuetify.breakpoint.smAndDown}"
            text
            
            
            @click="joinDoubleService()"
            >
                <v-icon small left >mdi-vector-link</v-icon>
                Об'єднати послуги
            </v-btn>
            <v-spacer />

        </span>

        
    </div>
    
</template>

<script>

export default {
    props: ["service_tv", "service_inet_billing", "checkDoubleTarif"],
    data: () => ({
        //
    }),
    
    methods:{
        
        
        async joinDoubleService(){
            let question = await this.$parent.$refs.confirmInfoForPlanChangeTarif.open("double")
            if(question) this.$parent.$refs.addRatesDouble.show('join') 
        },
        async splitDoubleService(){
            let question = await this.$parent.$refs.confirmInfoForPlanChangeTarif.open("double")
            if(question) this.$parent.$refs.addRatesDouble.show('split') 
        }
    }
}
</script>
