var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 row-cursor",class:_vm.$vuetify.breakpoint.smAndDown ? _vm.$vuetify.theme.dark && 'td_background_dark' || 'td_background':'',attrs:{"headers":_vm.headers,"loading-text":"Завантаження...","no-data-text":"Немає даних для відображення","server-items-length":_vm.paginations.count,"items":_vm.sms,"options":_vm.options,"footer-props":_vm.footer,"loading":_vm.loading,"focusable":"","disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"secondary lighten-1","dark":"","dense":""}},[_c('v-toolbar-title',{},[_c('h3',{staticClass:"mr-6 accent--text"},[_vm._v("Повідомлення")])]),_c('v-spacer')],1)]},proxy:true},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.sms.indexOf(item) + 1))])]}},{key:"item.is_sent",fn:function(ref){
var item = ref.item;
return [(item.is_sent)?_c('span',{staticClass:"d-flex"},[_c('v-icon',[_vm._v("mdi-check")]),(item.is_viber)?_c('v-img',{staticClass:"shrink ml-3 ",attrs:{"alt":"viber","contain":"","src":require("@/assets/viber.svg"),"width":"24"}}):_c('v-icon',{staticClass:"ml-3"},[_vm._v("mdi-message-bulleted")])],1):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.status && ((item.status.describe) + "\n\n Дата статусу: " + (item.date_status))}},[_vm._v(_vm._s(item.status && item.status.status_code))])]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" із "+_vm._s(itemsLength)+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }