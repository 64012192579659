<template>
  
  <div>
    
    <v-row justify="center" class="">
      <v-col>
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Немає даних для відображення"
          :server-items-length="paginations.count"
          :items="sms"
          :options.sync="options"
          :footer-props="footer"
          :loading="loading"
          :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
          class="elevation-1 row-cursor"
          focusable
          disable-sort
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-1" dark dense>
              <v-toolbar-title class=""><h3 class="mr-6 accent--text">Повідомлення</h3></v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.index="{ item }">
              <span>{{ sms.indexOf(item) + 1 }}</span>
        </template>
        <template #item.is_sent="{ item }">
          <span v-if="item.is_sent" class="d-flex" >
              <v-icon>mdi-check</v-icon>
              <v-img
               v-if="item.is_viber"
                alt="viber"
                class="shrink ml-3 "
                contain
                src="@/assets/viber.svg"
                width="24"
              />
              <v-icon v-else class="ml-3">mdi-message-bulleted</v-icon>
          </span>
        </template>

        <template #item.status="{ item }">
              <span :title="item.status && `${item.status.describe}\n\n Дата статусу: ${item.date_status}`">{{ item.status && item.status.status_code }}</span>
        </template>
        
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
          <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
            {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      </v-row>
  </div>
</template>

<script>
  import axios from "axios"
  export default {
    props: ['abonent'],
    name: "abonent-tab-sms",
    data: () => ({
      updateTable: 0,
      sms: [],
      loading: false,
      options: {},
      paginations: {},
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'Дата', value: 'date',  },
        { text: 'Назва', value: 'name', sortable: false, },
        { text: 'Телефон', value: 'phone', sortable: false, },
        { text: "Текст", value: 'data.text' },
        { text: "Відправлено", value: 'is_sent' },
        { text: "Статус", value: 'status' },
        //{ text: "Дата статусу", value: 'date_status' },
        
        
      ],
      footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
      },
    }),
    watch: {
      //
    },
    activated() {
     
     this.log = []
     this.checkGetSms()
   },
    methods: {
      checkGetSms(){
        if(this.options.page == 1){
          this.getSms()
        }else{
          this.options.page = 1;
        }
      },
      filterFormat() {
        const temp = {};
        temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
        temp.itemsPerPage = this.options.itemsPerPage;
        temp.page = this.options.page;
        return temp; 
      },
      getSms(){
        this.loading = true;
        axios.get(`${this.$router.app.devUrlServiceSms}/api_messages/user_messages/${this.$route.params.id}/`, {params: this.filterFormat()}) // this.$route.params.id
        .then( response =>{
          //console.log(response.data)
          this.sms = response.data.results
          this.paginations = response.data.paginations
        })
        .catch(() => {
          //console.log(error);
          //this.errored = true;
        })
        .finally(() => (this.loading = false))
        
      }
    },
  }
</script>

  
