<template>
  <v-form ref="form">
      
        
          <v-text-field
            v-model="phoneData.phone"
            :label="phoneData.memo || `Телефон ${keyPhoneData + 1}`"
            :rules="textRules || notRules"
            :loading="loading"
            hide-details="auto"
            type="text"
            outlined
            dense
            :disabled="globalEventChangePhoneData && !eventChangePhoneData"
          >
            <template v-slot:prepend>
              
            </template>

            <template v-slot:append>
              <v-tooltip
                v-if="phoneData.spam"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-chip
                  v-if="false"
                    v-on="on"
                    color="red"
                    outlined
                    small
                  >
                    
                  </v-chip>
                  <v-icon v-on="on" color="red" small>mdi-email-off-outline</v-icon>
                </template>
                Розсилка заборонена
              </v-tooltip>

              <v-tooltip
                v-if="phoneData.main"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-chip
                    v-on="on"
                    :color="$vuetify.theme.dark ? 'accent' : 'error'"
                    outlined
                    class="ml-1"
                    small
                  >
                    Основний
                  </v-chip>
                </template>
                Основний номер
              </v-tooltip>

              <v-menu
              v-model="menuPhone"
                offset-y
                bottom
                left
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="ml-3"
                  >
                    mdi-dots-vertical
                  </v-icon>
                </template>
                <v-list dense>
                  <v-list-item v-if="!phoneData.main"
                    @click="menuSettingMain()"
                  >
                    <v-list-item-title>
                      Зробити основним
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="menuSettingSpam()"
                  >
                    <v-list-item-title>
                      {{ phoneData.spam ? 'Дозволити розсилку' : 'Заборонити розсилку' }}
                    </v-list-item-title>
                  </v-list-item>
                  
                    
                  <v-edit-dialog 
                    :return-value.sync="phoneData.memo"
                    cancel-text="Ні"
                    save-text="Так"
                    large
                    @save="menuPhone = false, !eventChangePhoneData && sendPhone()"
                    @cancel="menuPhone = false"
                  >
                    <v-list-item key="4" @click="1+1">
                      <v-list-item-title>
                        {{ phoneData.memo ? 'Змінити примітку' : 'Додати примітку' }}
                      </v-list-item-title>
                    </v-list-item>
                    <template v-slot:input>
                      <v-text-field
                        v-model="phoneData.memo"
                        label="Примітка"
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                    
                  
                </v-list>
              </v-menu>

              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon 
                    v-on="on"
                    @click="emitChangePhone"
                  >
                    {{ phoneData.phone_delete && 'mdi-arrow-u-left-top'  || 'mdi-minus' }}
                  </v-icon>
                </template>
                {{ phoneData.phone_delete && 'Скасувати видалення'  || 'Видалити номер' }}
              </v-tooltip>

            </template>

            <!--<template v-slot:append-outer >
              
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon 
                    v-on="on"
                    @click="emitChangePhone"
                  >
                    {{ phoneData.phone_delete && 'mdi-arrow-u-left-top' ||  keyPhoneData == 0 && 'mdi-plus' || 'mdi-minus' }}
                  </v-icon>
                </template>
                {{ phoneData.phone_delete && 'Скасувати видалення' || keyPhoneData == 0 && 'Додати номер' || 'Видалити номер' }}
              </v-tooltip>

            </template>-->
          </v-text-field>
        
      
      <v-row 
        v-if="eventChangePhoneData"    
        class="justify-center mt-2 mb-5">
          
          <v-btn
            v-if="phoneData.phone_delete"
            color="red lighten-1"
            class="ml-2"
            small
            dark
            @click="sendPhone(method='DELETE')"
          >
            <v-icon left>
              mdi-delete
            </v-icon>
            Видалити
          </v-btn>
          <v-btn
            v-else
            color="primary "
            :class="$vuetify.theme.dark ? '' : 'lighten-1'"
            small
            @click="sendPhone()"
          >
            <v-icon left>
              mdi-content-save
            </v-icon>
            Зберегти
          </v-btn>
      </v-row>
    
  </v-form>
</template>

<script>
import axios from "axios"
export default {
    props: ["userID", "phoneData", "keyPhoneData", "activatedParrent", "countMainPhones", "globalEventChangePhoneData"],
    data: function () {
        return {
          menuPhone: false,
          phoneDataLocal: this.phoneData,
          eventChangePhoneData: false,
          loading: false,
          textRules: [
              v => !!v || "Це поле обов'язкове",
          ],
          notRules: [
              v => !!v || true ,
          ],
        }
    },
    mounted(){
      //console.log("mounted FieldPhone3")
    },
    computed: {
      //
    },
    watch: {
      phoneData:{
        handler (val, oldVal) {
          //console.log(console.log("phone GHANGE!"))
          //this.eventChangePhoneData = oldVal && true
          oldVal && 1+1
        },
        deep: true,
      },
      'phoneData.phone_delete'(val, oldVal){
        console.log(val)
        if(!val && oldVal){
           this.eventChangePhoneData =  false
        } else if(val == true) this.eventChangePhoneData =  true
      },
      activatedParrent(){
        //Обработчик активации модального окна
        console.log("activatedParrent()")
        this.eventChangePhoneData = false

      },
      'phoneData.phone'(val){
        //console.log(val)
        if(val == '3') this.phoneData.phone = '+3'
        else if(val == '8') this.phoneData.phone = '+38'
        else if(val == '0') this.phoneData.phone = '+380'
        this.eventChangePhoneData =  true
      },
      eventChangePhoneData(){
        this.$emit('event-change-phone-data', this.eventChangePhoneData)
        
      }
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
            this.snackbar = true;
        }
      },
      reset () {
          this.$refs.form.reset()
      },
      menuSettingMain(){
        //console.log(this.countMainPhones)
        //if(!this.phoneData.main && this.countMainPhones > 0) this.$router.app.$sheet.show("Помилка!", "Не можна обирати більше одного основного номера!");
        this.phoneData.main = !this.phoneData.main 
        !this.eventChangePhoneData && this.sendPhone()
      },
      menuSettingSpam(){
        this.phoneData.spam = !this.phoneData.spam
        !this.eventChangePhoneData && this.sendPhone()
      },
      menuSettingMemo(){
        //
      },
      emitChangePhone(){
          this.phoneData.phone_delete && this.$emit('back-phone') || this.$emit('change-phone')
      },
      savePhone(){
        if(this.$refs.form.validate()){
          this.eventChangePhoneData =  false
        }
      },
      sendPhone(method=false) {
        //console.log(this.$refs.form.validate())
        
        if(this.$refs.form.validate() || method == 'DELETE'){
          this.loading = true;
          let url_send, method_send, message_response

          if(this.phoneData.id){
            url_send = `/api/cabletv/phones/${this.phoneData.id}/`
            method_send = method || 'PUT'
            
          }else{
            this.phoneData["user"] = this.userID
            url_send = '/api/cabletv/phones/'
            method_send = 'POST'
          }
          message_response = method && "Номер видалено" || "Номер Змінено"
          //console.log(method_send)
      
          axios({
            headers: { "Content-type": "application/json; charset=UTF-8" },
            method: method_send,
            url: url_send,
            data: this.phoneData
          })
          .then(() => {
            //console.log(response)   
            this.$router.app.$snack.show(message_response)  
            this.eventChangePhoneData =  false
            this.$emit('update-phone')
          })
          .catch(err => {
            //console.log(err);
            this.$router.app.$sheet.show("Помилка!", err);
          })
          .finally(() => (this.loading = false))
        }  
      },
    },
  
}
</script>