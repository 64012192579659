<template>
  
  <div>
    
    <v-row justify="center" class="">
      <v-col  >
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Немає даних для відображення"
          :server-items-length="paginations.count"
          :items="job"
          :options.sync="options"
          :footer-props="footer"
          :loading="loading"
          :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
          class="elevation-1 row-cursor"
          focusable
        >
          <template v-slot:top>

            <v-toolbar flat color="secondary lighten-1" dark dense>
              <v-toolbar-title class=""><h3 class="mr-6 accent--text">Заявки</h3></v-toolbar-title>
            
              <v-spacer></v-spacer>
              
              <v-btn 
                v-if="user"
                icon @click="$refs.addJob.show('add', abonent)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <!--<v-btn icon @click="1+1">
                <v-icon>mdi-filter</v-icon>
              </v-btn>-->

            </v-toolbar>
            
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.index="{ item }">
              <span>{{ job.indexOf(item) + paginations.start_index }}</span>
        </template>
        
        <template #item.order_service="{ item }">
        <span class="d-flex justify-center" >
          <v-icon v-if="item && item.order_service == 'Телебачення'" color="green darken-2" >mdi-television</v-icon> 
          <v-icon v-else color="light-blue darken-2" >mdi-web</v-icon> 
        </span>
      </template>
      <template #item.order_type="{ item }">
          {{ item.order_type }}
        <v-chip
          v-if="item.need_check" 
          x-small
          class="white--text"
          color="orange"
        >
        <v-icon left x-small>mdi-alert-circle</v-icon> Необроблена
        </v-chip>
      </template>
      <template #item.order_status="{ item }">
        <v-chip
        small
          class="white--text"
          :color="getStatusColor(item.order_status)"
          @click="onClickRow(item)"
        >{{ item.order_status }}</v-chip> 
      </template>

      <template #item.order_text="{ item }"> 
        <v-tooltip bottom color="primary" :disabled="$vuetify.breakpoint.mobile || item.order_comment.length == 0">
            <template v-slot:activator="{ on, attrs }">
              <span
                class="link-cursor"
                v-bind="attrs"
                v-on="on"
                @click="$refs.addJobComment.show(item)"
              >
                <v-badge
                    :content="item.order_comment_count"
                    color="orange"
                    :value="item.order_comment_count > 0"
                    overlap
                    :inline="item.order_comment_count > 0"
                    style="line-height: 1.5;"
                >
                    {{ item.order_text }}
                </v-badge>
              </span>
              </template>
              <span>{{ item.order_comment.length > 0 && 'Переглянути коментарі' || '+ Додати коментар' }}</span>
              
              </v-tooltip>
      </template>

      <template #item.user_recive="{ item }">
        <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ item.user_recive }}
            </span>
          </template>
          <span>{{ item.date_recive }}</span>
        </v-tooltip>
      </template>
      
      <template #item.user_worker="{ item }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
              <v-badge
              :content="'+' + (item.order_worker.length - 1)"
              :value="item.order_worker.length > 1"
              color="grey"
              overlap
              :inline="item.order_worker.length > 1"
              style="line-height: 1.5;"
            >
            {{ item.user_worker && item.user_worker.first_name }} {{ item.user_worker && item.user_worker.last_name }} {{ item.order_status == 'Знята' && !item.user_worker && ' ' || '' }}
            
            </v-badge>
                
              </span>
            </template>
            <span><span v-if="item.order_worker.length != 0">{{ showOrderWorker(item.order_worker) }}<br></span><span v-if="item.date_done">Звіт: {{ item.date_done }}</span></span>
          </v-tooltip>
      </template>

      <template #item.order_settings="{ item }">
        <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn  v-bind="attrs" v-on="on"
          @click="1+1" :color="$vuetify.breakpoint.mobile ? 'primary lighten-1' : ''" :icon="!$vuetify.breakpoint.mobile" small> 
          <span v-if="$vuetify.breakpoint.mobile">Опції</span>
          <v-icon v-else>mdi-dots-vertical</v-icon>
        </v-btn>
        </template>
        <v-list dense>
          <v-list-item  v-if="user && user.user_type != 2" @click="onClickRow(item)">
            <template v-if="item.order_status != 'Знята' && item.order_status != 'Виконана'">
              <v-icon class="pr-3">mdi-pencil</v-icon>
              <v-list-item-title >Редагувати / Зняти</v-list-item-title>
            </template>
            <template v-else>
              <v-icon class="pr-3">mdi-history</v-icon>
              <v-list-item-title>Історія заявки</v-list-item-title>
            </template>
          </v-list-item>
          <v-list-item  @click="$refs.addJobComment.show(item)">
            <v-icon class="pr-3">mdi-comment-text-outline</v-icon>
            <v-list-item-title>Коментарі</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="user && user.user_type != 2" @click="$refs.addJobTag.show(item)"> 
            <v-icon class="pr-3">mdi-tag</v-icon>
            <v-list-item-title>Додати тег</v-list-item-title>
          </v-list-item>
          <v-list-item  @click="$refs.addJobCommentSet.show(item)">
            <v-icon class="pr-3">mdi-comment-plus-outline</v-icon>
            <v-list-item-title>Додати коментар до заявки</v-list-item-title>
          </v-list-item>
          <v-list-item  @click="$refs.addCommentHouseSet.show(item)">
            <v-icon class="pr-3">mdi-comment-plus-outline</v-icon>
            <v-list-item-title>Додати коментар до будинку</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="item.need_check"  @click="$refs.jobConfirmCheckJob.show(item)">
            <v-icon class="pr-3">mdi-check-all</v-icon>
            <v-list-item-title>Перевірено</v-list-item-title>
          </v-list-item>
          <v-list-item target="_blank"  :href="`https://t.me/share/url?url=${encodeURIComponent(`${item.user && item.user.operator_group.name}`)}&text=${encodeURIComponent(`\n${'**' + item.address + '**'} \n${item.order_text}\ncrmtv.altair.kr.ua/ab/${item.user.id}`)}`">
            <v-icon class="pr-3">mdi-share-variant</v-icon>
            <v-list-item-title>Поділитись</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
        
      </template>

      <template #item.tags="{ item }">
        <div @click="$refs.addJobTag.show(item)">
        <template v-for="tag in item.tags">
          <v-icon :key="tag.id" :title="tag.name" :color="tag.color" class="mr-1">
            mdi-tag
          </v-icon>
        </template>
      </div>
      </template>

        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
          <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
            {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      </v-row>

      <keep-alive>
        <JobModalAdd ref="addJob" v-on:update-job="updateTable += 1" v-on:update-abonent="$emit('update-abonent')"/>  
      </keep-alive>
      <keep-alive>
        <JobModalAddComment ref="addJobComment" :updateTable="updateTable"/>
      </keep-alive>
      <keep-alive>
        <JobModalAddCommentSet ref="addJobCommentSet" v-on:open-modal="openModalAddComment()" v-on:update-job="updateTable += 1"/>
      </keep-alive> 
      <keep-alive>
        <ModalAddCommentHouseSet ref="addCommentHouseSet" v-on:open-modal="openModalAddComment()" v-on:update-job="updateTable += 1"/>
      </keep-alive> 
      <keep-alive>
        <JobModalConfirmCheckJob ref="jobConfirmCheckJob" v-on:update-job="updateTable += 1"/>
      </keep-alive>   
      <keep-alive>
        <ModalSetTagJob ref="addJobTag" v-on:update-job="updateTable += 1"/>
      </keep-alive>
  </div>
</template>

<script>
  import axios from "axios"
  import JobModalAdd from '@/components/Job/JobModalAdd.vue'
  import JobModalAddComment from '@/components/Job/JobModalAddComment.vue'
  import JobModalAddCommentSet from '@/components/Job/JobModalAddCommentSet.vue'
  import ModalAddCommentHouseSet from '@/components/Job/ModalAddCommentHouseSet.vue'
  import JobModalConfirmCheckJob from '@/components/Job/JobModalConfirmCheckJob.vue'
  import ModalSetTagJob from '@/components/Job/ModalSetTagJob.vue'


  export default {
    props: ['abonent', 'user'],
    name: "abonent-tab-job",
    components: {
      JobModalAdd,
      JobModalAddComment,
      JobModalAddCommentSet,
      ModalAddCommentHouseSet,
      JobModalConfirmCheckJob,
      ModalSetTagJob
    },
    data: () => ({
      updateTable: 0,
      job: [],
      paginations: {},
      loading: false,
      options: {},
      statusColor: {
        Прийнята:	"accent",
        Виконана:	"green",
        Знята:	"grey",
        Виконується: "primary lighten-1"
      },
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'Дата', value: 'date_due', },
        { text: 'Послуга', value: 'order_service', },
        { text: 'Тип', value: 'order_type', },
        { text: 'Заявка', value: 'order_text', },
        { text: 'Прийняв', value: 'user_recive', },
        { text: 'Статус', value: 'order_status',  },
        { text: 'Виконавець', value: 'user_worker', },
        { text: 'Звіт', value: 'order_done_text', sortable: false, },
        { text: '', value: 'tags',  sortable: false,},
        { text: '', value: 'order_settings', sortable: false}, 
      ],
      footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
      },
    }),
    watch: {
      options: {
        handler () {
          this.getJob();
        },
        deep: true,
      },
      'abonent.user_pcode': function (val) { 
        val && this.getJob() 
      },
      updateTable(){
        this.updateTable && this.getJob(); 
      },
    },
    activated() {
      this.job = []
      //this.abonent?.user_pcode && this.checkGetJob()
      this.checkGetJob()
    },
    deactivated(){
      //
    },
    methods: {
      showOrderWorker(order_workers){
      let workerNames = ''
      order_workers.forEach(x => {workerNames += ', ' + x.name })
      return workerNames.replace(', ', '')
    },
      checkGetJob(){
        if(this.options.page == 1){
          this.getJob()
        }else{
          this.options.page = 1;
        }
      },
      onClickRow(item){
        this.user && this.user.user_type != 2 && this.$refs.addJob.show('change', item)
      },
      getStatusColor(status){
        return this.statusColor[status]
      },
      filterFormat() {
        const temp = {};
        temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
        temp.itemsPerPage = this.options.itemsPerPage;
        temp.page = this.options.page;
        return temp; 
      },
      getJob(){
        this.loading = true;
        axios.get(`/api/service/get_service/${this.$route.params.id}/`, {params: this.filterFormat()}) // this.$route.params.id
        .then( response =>{
          //console.log(response.data)
          this.job = response.data.results
          this.paginations = response.data.paginations
        })
        .catch(() => {
          //console.log(error);
          //this.errored = true;
        })
        .finally(() => (this.loading = false)); 
      }
    },
  }
</script>

  
<style scoped>
.text-ex-space{
  white-space: nowrap !important /* Запрещаем перенос слов */
}
.link-cursor {
    cursor: pointer !important
  }
</style>