<template>     
  <v-dialog v-model="dialog" persistent max-width="400">      
    <v-card :loading="loading">
      <v-card-title class="text-h5 text-center">
        Перенести залишок?
      </v-card-title>
      <v-card-text class="text-center">Ви впевнені, що хочете перенести залишок коштів у розмірі <span class="error--text">{{ amount }} грн.</span> {{ typeInet && 'з інтернету на телебачення' || 'з телебачення на інтернет'  }}?
      <span class="error--text" v-if="responseInfo">
        <br><br>
        <v-icon color="error" class="mr-1" style="vertical-align: sub;">mdi-information-outline</v-icon>Зверніть увагу: {{ responseInfo }}
      </span>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="deep-orange darken-1"
          text
          @click="closeDialog()"
        >
          Ні
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          color="primary lighten-1"
          text
          @click="sendForm"
        >
          Так
        </v-btn>
      </v-card-actions>
    </v-card>   
  </v-dialog>
</template>

<script>
    import axios from "axios"
    //import { mapGetters } from "vuex"
	export default {
    name: 'modal-confirm-transfer-balance',
    props: ['pcode', "abonent_id"],
		data: () => ({
            loading:false,
            dialog: false,
            typeInet: false,
            operatorAlienService: null,
            responseInfo: '',
            amount: null
            
        }),
        methods: {
            show(typeInet, operatorAlienService){
                this.operatorAlienService = operatorAlienService
                this.typeInet = typeInet
                if(this.typeInet) this.checkTransferBalanceBilling()
                else this.checkTransferBalance()
            },
            closeDialog (){
              this.operatorAlienService = null
              this.responseInfo = ''
              this.amount = null
              this.dialog = false
            },
            checkTransferBalance(){
              axios({
                method: "get",
                url: `/api/cabletv/get_user/${this.abonent_id}/transferbalance/?distributor=${this.operatorAlienService}`,
              })
                .then(response => {
                if(response){
                  this.responseInfo = response.data.info
                  this.amount = response.data.amount
                  this.dialog = true
                }else{
                    this.$router.app.$snack.show("Помилка отримання данних", 'deep-orange')
                }
                })
                .catch(err => {
                    this.$router.app.$sheet.show("Помилка!", err);
                })
            },
            checkTransferBalanceBilling(){
              axios({
                  method: "post",
                  url: this.$router.app.devUrlBillingAPI,
                  data: {req_cmd: "checkTransferBalance", pcode: this.pcode, arg: this.operatorAlienService }
              })
                  .then(response => {
                  if(response.data.resp_status == "OK"){
                    this.responseInfo = response.data.resp_result?.info
                    this.amount = response.data.resp_result?.amount
                    
                    this.dialog = true
                  }else if(response.data.resp_status == "REJECT"){
                      this.$router.app.$snack.show(response.data.resp_errmsg, 'deep-orange')
                  }else{
                      this.$router.app.$snack.show("Помилка отримання данних", 'deep-orange')
                  }
                  })
                  .catch(err => {
                      this.$router.app.$sheet.show("Помилка! (billing)", err);
                  })
                  
            },
            sendTransfer(){
              this.loading = true
              axios({
                method: "post",
                url: `/api/cabletv/get_user/${this.abonent_id}/transferbalance/`,
              })
                .then(response => {
                if(response){
                    this.$router.app.$snack.show("Залишок коштів перенесено")
                    this.$emit('update-abonent');
                    this.closeDialog()
                }else{
                    this.$router.app.$snack.show("Помилка переносу залишків", 'deep-orange')
                }
                })
                .catch(err => {
                    this.$router.app.$sheet.show("Помилка!", err);
                })
                .finally(() => ( this.loading = false ))
            },
            sendTransferBilling(){
              axios({
                  method: "post",
                  url: this.$router.app.devUrlBillingAPI,
                  data: {req_cmd: "transferBalance", pcode: this.pcode, arg: this.operatorAlienService }
              })
                  .then(response => {
                  if(response.data.resp_status == "OK"){
                    this.$router.app.$snack.show("Залишок коштів перенесено")
                    this.$emit('update-abonent');
                    this.closeDialog()
                  }else if(response.data.resp_status == "REJECT"){
                      this.$router.app.$snack.show(response.data.resp_errmsg, 'deep-orange')
                  }else{
                    this.$router.app.$snack.show("Помилка переносу залишків", 'deep-orange')
                  }
                  })
                  .catch(err => {
                      this.$router.app.$sheet.show("Помилка! (billing)", err);
                  })
            },
            sendForm() {
              if(this.typeInet) this.sendTransferBilling()
              else this.sendTransfer()  
            },
        }
    }
</script>