<template>
  
  <div> 
    <keep-alive>
        <Filters ref="filtersConnect" v-on:apply-filters="getUserConnect()" for="connects_abonent" />
      </keep-alive>
    <v-row justify="center" class="">
      <v-col  >
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Нема данних для відображення"
          :server-items-length="paginations.count"
          :items="connects"
          :options.sync="options"
          :footer-props="footer"
          :loading="loading"
          :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
          class="elevation-1 row-cursor"
          focusable
          
          disable-sort
        >
          <template v-slot:top>

            <v-toolbar flat color="secondary lighten-1" dark dense>
              <v-toolbar-title class=""><h3 class="mr-6 accent--text">Підключення</h3></v-toolbar-title>
            
              <v-spacer></v-spacer>
              <v-btn 
                icon 
                :loading="loading"
                @click="getUserConnect()">
                <v-icon>autorenew</v-icon>
              </v-btn>
              <v-btn  
                icon 
                :disabled="loading"
                @click="$refs.filtersConnect.show()"
              >
                <v-badge
                :value="countFilters > 0"
                color="accent"
                text="primary"
                :content="countFilters"
                dark
              >
                <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </v-toolbar>
            
          </template>
          <template
            v-if="countFilters > 0"
            v-slot:header="{ props: { headers } }"
          >
            <thead>
              <tr>
                <th :colspan="headers.length">
                  Фільтри: 
                  <keep-alive>
                    <FiltersChips :filtersComponents="filtersComponents" v-on:clear-filter="clearFilter" :is_close="true" v-on:open-filters="$refs.filtersConnect.show()"/>
                  </keep-alive>
                </th>
              </tr>
            </thead>
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.index="{ item }">
              <span>{{ connects.indexOf(item) + paginations.start_index }}</span> 
        </template>
        <template #item.reason="{ item }">
              <span :title="item.reason && 'Причина розриву сесії: \n\n' + codeReason[item.reason]">
                <v-badge  color="primary lighten-3" inline :content="item.reason"></v-badge>
              </span>
        </template>
        <template #item.traffic="{ item }">

              <v-chip v-if="item.traffic" small outlined :color="convertBytes(item.traffic)[1]">{{ convertBytes(item.traffic)[0] }}</v-chip>
        </template>
        

        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
          <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
            {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      </v-row>
  </div>
</template>

<script>
  import axios from "axios"
  import Filters from '@/components/Abonents/Filters.vue'
  import FiltersChips from '@/components/Abonents/FiltersChips.vue'
  import codeReason from '@/components/AbonentInfo/codeReason.js'

  export default {
    name: "abonent-tab-user-connect",
    props: ["abonent"],
    components: {
      Filters,
      FiltersChips
    },
    data: () => ({
      codeReason: codeReason,
      updateTable: 0,
      connects: [],
      paginations: {},
      countFilters: 0,
      loading: false,
      options: {},
      headers: [
        //{ text: '#', value: 'index', sortable: false,  width: 50},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'Код', value: 'reason', },
        { text: 'Вхід', value: 'datein', },
        { text: 'Вихід', value: 'dateout', },
        
        { text: 'Трафік', value: 'traffic', },
        { text: 'Отримана IP', value: 'ip', },
        { text: 'Caller ID', value: 'caller', },
        { text: 'Пристрій', value: 'hostname', },
      ],
      footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
      },
    }),
    watch: {
      options: {
        handler () {
          this.getUserConnect();
        },
        deep: true,
      },
      "abonent.username"(){
        if(this.$route.params.id == this.abonent?.id){
          this.checkGetUserConnect()
        }
      }
    },
    activated() {
      //console.log(this.abonent.username)
      this.connects = []
      if(this.$route.params.id == this.abonent?.id){
        this.checkGetUserConnect()
      }
    },
    deactivated(){
      //
    },
    methods: {
      
      checkGetUserConnect(){
        if(this.options.page == 1){
          this.getUserConnect()
        }else{
          this.options.page = 1;
        }
      },
      clearFilter(filter_name){
        this.$refs.filtersConnect.filters.find(x => x.name == filter_name).value = ''
        this.$refs.filtersConnect.apply()
      },
      getFiltersOfComponents(){
        return this.$refs.filtersConnect.filters.filter(x => x.value && x.value != '') 
      },
      filterFormat() {
        this.countFilters = 0
        const temp = {};
        temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
        temp.itemsPerPage = this.options.itemsPerPage;
        temp.page = this.options.page;
        
        //if(this.search) temp.search = this.search
        
        /* получение значений фильтрации и просчет колличества фильтров */
        this.filtersComponents = this.getFiltersOfComponents()
        this.filtersComponents.forEach(filter => temp[filter.name] = filter.value + '');
        this.countFilters = this.filtersComponents.length
        
        return temp; 
      },
      getUserConnect(){
        this.loading = true;
        axios({
            method: "get",url: this.$router.app.devUrlBillingAPI,
            params: {req_cmd: "getUserConnect", pcode: this.abonent?.user_pcode, ...this.filterFormat() },
            
        })
        .then( response =>{
          //console.log(response.data)
          if(response.data.resp_status == "OK"){
            this.connects = response.data.resp_result.result
            this.paginations = response.data.resp_result.paginations
          }else{
            this.connects = []
            this.paginations = {}
          }
        })
        .catch(() => {})
        .finally(() => (this.loading = false)) 
      },
      convertBytes(bytes){
        if(!bytes) return ['0 bytes', 'grey']
        if(bytes > 10**12) return [(bytes * 10**-12).toFixed(1) + ' Тб', 'orange']
        else if(bytes > 10**9) return [(bytes * 10**-9).toFixed(2) + ' Гб', 'blue']
        else if(bytes > 10**6) return [(bytes * 10**-6).toFixed(2) + ' Мб', 'green']
        else  return [(bytes * 10**-6).toFixed(3) + ' Мб', 'deep-orange']
      }
    },
  }
</script>

  
<style scoped>
.text-ex-space{
  white-space: nowrap !important /* Запрещаем перенос слов */
}
</style>