<template>
    <div class="d-flex justify-space-between align-center">
        <span v-if="checkDoubleTarif" class="d-flex">
            <template v-if="service_inet_billing && service_inet_billing.status && service_tv.status">
                <v-chip small  text-color="white" color="success" ><v-icon small left>mdi-television</v-icon>+<v-icon class="pl-1 pr-3" small>mdi-web</v-icon>Підключений</v-chip>
            </template>
            <template v-else>
                <v-chip x-small class="mr-2 mb-1" text-color="white" :color="service_tv.status ? 'success': 'red' " ><v-icon small left>mdi-television</v-icon>{{ service_tv.status && "Підключений" || "Відключений" }}</v-chip>
                <v-chip x-small class=" mb-1" text-color="white" :color="service_inet_billing && service_inet_billing.status ? 'success': 'red' " ><v-icon  small left>mdi-web</v-icon>{{ service_inet_billing && service_inet_billing.status && "Підключений" || "Відключений" }}</v-chip>
            </template>
            
        </span>
        <span v-else>
            <v-chip small class="mr-2 mb-1" text-color="white" :color="service_tv.status ? 'success': 'red' " >{{ service_tv.status && "Підключений" || "Відключений" }}</v-chip>
        </span>
        <!-- Тимчасово прибираємо тимчасову активацію
            <v-chip v-if="checkDoubleTarif && service_tv.temporary_activate" :title="`Активний до: ${service_tv.temporary_activate.date_due}`"  class="ml-2" small color="primary"><v-icon small class="mr-1">mdi-timer-check-outline</v-icon>Активований</v-chip>
        -->
    </div>
</template>
<script>
export default {
    props: ["service_tv", "service_inet_billing", "checkDoubleTarif"]
}
</script>
