<template>
	<div class="add-order-modal">
        <v-dialog v-model="dialog" persistent max-width="500">
            
            <v-card>
            <v-card-title>
            <span class="headline">Додати тег</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form 
                ref="form"
                lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-select 
                      @keyup.enter="sendTagJob()"
                      menu-props="auto"
                      v-model="item_job.tags"
                      :items="tags"
                      item-text="name"
                      item-value="id"
                      label="Теги"
                      no-data-text="Немає данних"
                      multiple
                      clearable
                      persistent-hint
                      @change="changeTags = true"
                    >
                    </v-select>
                    </v-col>
                  </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer></v-spacer>

                <v-btn :loading="loadingSubmit" :disabled="!changeTags" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendTagJob()">
                    Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
			
	</div>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"
export default {
    data: () => ({
      changeTags: false,
      item_job: {},
      dialog: false,
      loadingSubmit: false,
    }),
    computed:{
    ...mapGetters({
            tags: "tags/order_tags"
        }),
    },
    methods:{
      show(item){
          this.item_job = JSON.parse(JSON.stringify(item))
          this.changeTags = false
          this.dialog = true;
      },
      closeDialog (){
          this.dialog = false
          this.item_job = {}
      },
      sendTagJob(){
        this.loadingSubmit = true
        axios({
            method: "PUT",
            url: `/api/service/add_tag/${this.item_job.id}/`,
            data: {tags: this.item_job.tags},
        })
        .then(() => { this.$router.app.$snack.show('Теги заявки змінено!'); this.$emit('update-job'); this.closeDialog(); })
        .catch(err => { this.$router.app.$sheet.show("Помилка!", err) })
        .finally(() => { this.loadingSubmit = false })
      }
    }
}
</script>
