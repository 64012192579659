<template>
    <v-tooltip bottom color="secondary darken-1">
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="is_nextMonth" style="vertical-align: sub;" class="ml-1" color="orange" v-on="on">mdi-information</v-icon>
            <span v-else
            style="cursor: pointer;"
            v-bind="attrs"
            v-on="on"
            >
            {{ tarifInet }}
            </span>
         </template>
        
        <div v-if="tarifInetData"> 
            <span>{{ is_nextMonth ? 'Тариф на наступний місяць:' : 'Тариф на Поточний місяць:' }}</span>
                <v-divider class="my-2"/>
                <h3>{{ tarifInetData.name }}</h3>
            <br>
            <div class="d-flex justify-space-between">
                <span class="mr-auto">Вартість тарифу:</span> 
                <span class="ml-5 text-end">{{ tarifInetData.price }}</span>
                <br>
            </div>
            
            <template v-if="tarifBillingData">
            <div class="d-flex justify-space-between">
                <span class="mr-auto">Знижка:</span> 
                <span class="ml-5 text-end">{{ tarifBillingData.discount }} %</span>
                <br>
            </div>
            <div class="d-flex justify-space-between">
                <span class="mr-auto">Сума Абонплати:</span> 
                <span class="ml-5 text-end"><b>{{ tarifBillingData.fee }}</b></span>
                <br>
            </div>
            </template>
            <br>
            <div class="d-flex justify-space-between">
                <span class="mr-auto">Швидкість:</span> 
                <span class="ml-5 text-end">{{ tarifInetData.speed }} Мб/с</span>
                <br>
            </div>
            
            
            <div >
                <v-chip color="accent" outlined small label v-if="tarifInetData.promo" class="mr-2 mt-4">Акція</v-chip>
                <v-chip color="accent" outlined small label v-if="tarifInetData.is_special" class="mr-2 mt-4">Спеціальний</v-chip>
                <v-chip color="accent" outlined small label v-if="tarifInetData.service == 1 && tarifInetData.speed" class="mr-2 mt-4">ПП</v-chip>
                <v-chip color="accent" outlined small label v-if="tarifInetData.package == 1 " class="mr-2 mt-4">УПП</v-chip>
                <v-chip color="accent" outlined small label v-if="tarifInetData.package == 2 " class="mr-2 mt-4">Стандарт</v-chip>
                <v-chip color="accent" outlined small label v-if="tarifInetData.real_ip" class="mr-2 mt-4">Real IP</v-chip> 
            </div>
        </div>
    </v-tooltip>
</template>

<script>
export default {
    props: ['is_nextMonth', 'tarifInet', 'tarifInetData', 'tarifBillingData']
}
</script>
