<template>
  
  <v-container class="abonent_info mt-2" :style="{'max-width': '1500px'}"> 
      <v-toolbar
        flat
        color="background"
      >
        <v-btn
          v-if="!is_modal"
          @click="closeCard"
          icon
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          v-else
          @click="is_modal && $emit('dialog-close')"
          icon
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <!--<v-toolbar-title class="d-inline-flex" :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''"><h2 class="mr-6 primary--text">{{ abonent && `${streets && streets.find(x => x.id == abonent.street && x).name} ${abonent.house}${abonent.letter || ''} ${abonent.flat}` || ''}}</h2></v-toolbar-title>-->
        <v-toolbar-title class="d-inline-flex" style="white-space: break-spaces;" :class="{'subtitle-1' :$vuetify.breakpoint.smAndDown}"><h2 v-if="!overlayTab" class="mr-6 primary--text">{{ abonent && `${abonent.street.street} ${abonent.house}${abonent.letter || ''}${abonent.corpus > 0 && ' (к.'+abonent.corpus+')' || ''} ${abonent.flat}${abonent.tenand > 0 && ' (н.'+abonent.tenand+')' || ''}` || ''}}</h2></v-toolbar-title>

        <v-spacer v-if="$vuetify.breakpoint.lgAndUp"></v-spacer>
        
        
        <v-col cols="6" v-if="$vuetify.breakpoint.lgAndUp">
          <v-tabs
            v-model="tab"
            background-color="background"
            ref="navbarAbonentInfo"
            centered
            show-arrows
          >
            <template v-for="i in abonentMenu">
            <v-tab
              :key="i.title"
              @click="is_component = i.component"
            >
              <v-badge :value="i.component == 'abonentTabJob' && abonent && abonent.orders" :content="abonent && abonent.orders"> <span class="font-weight-bold"><v-icon v-if="i.icon">mdi-message-outline</v-icon><template v-else>{{ i.title }}</template></span></v-badge>
            </v-tab>
            </template>
            
            <!--<v-menu
              v-if="more.length"
              bottom
              left
              open-on-hover
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="align-self-center mr-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="font-weight-bold">ще</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>

              <v-list class="">
                <v-list-item
                  v-for="i in more"
                  :key="i.title"
                  @click="addItem(i)"
                >
                  <span class="font-weight-bold">{{ i.title }}</span>
                </v-list-item>
              </v-list>
            </v-menu>-->
            
          </v-tabs>
        </v-col>
        <!-------------------------- Mobile --------------------------------------------------------------------->
        <template 
          v-slot:extension 
          v-if="$vuetify.breakpoint.mdAndDown"
        >
          <v-tabs
            v-model="tab"
            ref="navbarAbonentInfo"
            class="my-4"
            centered
            center-active
            show-arrows
          >
            <template v-for="i in abonentMenu"> <!--[...abonentMenu, ...more]-->
            <v-tab
              :key="i.title"
              @click="is_component = i.component"
            >
              <span class="font-weight-bold">{{ i.title }}</span>
            </v-tab>
            </template>
          </v-tabs>
        </template>
        <!-------------------------- / Mobile --------------------------------------------------------------------->
      </v-toolbar>

      <transition name="page" mode="out-in">
        <keep-alive>
        <component :overlay="overlayTab" class="my-4" :is="is_component" :user="user" :abonent="abonent" v-on:update-abonent="updateAbonent"></component>
        </keep-alive>
        
      </transition>
    
  </v-container>
</template>

<script>
import TableCalls from '@/components/CallCenter/TableCalls.vue'

// @ is an alias to /src
import axios from "axios"
import '@/components/AbonentInfo'
import {mapGetters} from 'vuex'
export default {
  name: 'AbonentInfo',
  props: ["is_modal"],
  components: {
    TableCalls
  },
  computed: {
      ...mapGetters({
        //streets: "streets/streets"
        user: "auth/user"
        
      }),
      service_inet() {
        return this.abonent?.user_service?.find(x => x.service == 2)
      },
  },
  data: () => ({
    active_component: false,
    tab: null,
    abonent: null,
    processUpdateAbonent: false,
    overlayTab: true,
    is_component: "abonentTabInfo",
    breadcrumbs: [],
    abonentMenu:[
      {title: "Інфо", component: "abonentTabInfo"},
      {title: "Платежі", component: "abonentTabPays"},
      {title: "Заявки", component: "abonentTabJob"},
      {title: "Входи", component: "abonentTabConnect"},
      {title: "Дзвінки", component: "TableCalls"},
      {title: "Лог", component: "abonentTabLog"},
      {title: "Пов.", component: "abonentTabSMS", icon:false},
    ],
    more:[
      //{title: "Входи", component: "abonentTabConnect"},
      //{title: "Дзвінки", component: "TableCalls"},
      {title: "Лог", component: "abonentTabLog"},
      {title: "Повідомлення", component: "abonentTabSMS"}
    ]
  }),
  watch:{

    /*tab(val, oldVal){
      //console.log(this.tab)
      console.log('watch: this.tab')
      oldVal && val == 0 && this.active_component && this.updateAbonent()
    },
    "$route.params"(val, oldVal){
      console.log('watch: this.$route.params')
      oldVal && val.id && this.updateAbonent()
    }*/
  },
  mounted (){
    //console.log(this.$route.params)
    //if (this.is_modal) this.updateAbonent()
  },
  activated() {
    //console.log(this)
    //console.log('activated: abonent page')
    //console.log("id", this.$route.params.id)
    this.active_component = true
    this.abonent = null
    this.overlayTab = true
    this.$router.app.$loading.start()
    //this.updateAbonent()
    

  },
  deactivated(){
    //console.log("deactivated")
    this.active_component = false
    this.tab = 0
    this.is_component = "abonentTabInfo"
    //this.abonent = null
    if(this.abonent){
      this.abonent.user_pcode = null
    }
    
  },
  methods: {
    closeCard(){
      this.$router.go(-1)
    },
    createTabs(){
      const tabConnections = this.abonentMenu.find(x => x.component == "abonentTabConnect")
      const tabConnectionsIndex = tabConnections && this.abonentMenu.indexOf(tabConnections) || null
      if(this.service_inet){
        !tabConnectionsIndex && this.abonentMenu.splice(3,0,{title: "Входи", component: "abonentTabConnect"})
      }else{
        tabConnectionsIndex && this.abonentMenu.splice(tabConnectionsIndex, 1)
      }
    },

    updateAbonent(){ 
      //console.log("fetch Abonent")
      this.processUpdateAbonent = true
      axios.get(`/api/cabletv/get_user/${this.$route.params.id}/`)  
      .then( response =>{
          //console.log(response.data)
          this.abonent = response.data  
          this.$store.commit('tabs_abonents/updateTabsAbonents', {id: this.abonent.id, street: this.abonent.street.street, house: this.abonent.house, letter: this.abonent.letter, flat: this.abonent.flat, date_view: new Date().toLocaleString()})
          this.formatPhones() // 
      })
      .catch(() => {
        //console.log(error);
        //this.errored = true;
      })
      .finally(() => (this.$router.app.$loading.finish(), this.overlayTab = false, this.processUpdateAbonent = false, this.createTabs()))
    },
    formatPhones(){
      this.abonent?.user_phones.reverse() 
    },
    toTab(tab){
      this.tab = tab
      this.is_component = this.abonentMenu[tab].component
    },
    toTabConnection(){
      let tab = this.abonentMenu.find(x => x.component == "abonentTabConnect")
      if(tab){
        this.is_component = "abonentTabConnect"
        this.tab = this.abonentMenu.indexOf(tab)
      }
    },
    addItem (item) {
      const removed = this.abonentMenu.splice(this.abonentMenu.length -1, 1)
      this.abonentMenu.push(
        ...this.more.splice(this.more.indexOf(item), 1),
      )
      this.more.push(...removed)
      this.tab = this.abonentMenu.length - (this.service_inet && 1 || 2)
      this.$nextTick(() => { this.is_component = item.component })
    },
  }
}
</script>
<style scoped>

</style>

