<template>
    
    <v-row justify="center" class="mt-0 mb-2">
        <v-chip-group v-model="listFiltersTarif" column multiple :active-class="$vuetify.theme.dark ? 'accent--text':'primary lighten-1'">
            <template v-if="service == 'double'">
                
                <v-chip :class="{'d-none': !is_special_tarif_for_distributor}" title="Спеціальні тарифи"  label small  key="is_special">СТ</v-chip>
                <v-chip label small  key="promo">Акція</v-chip>
                <v-menu offset-y open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip  v-bind="attrs" v-on="on" label small  key="speed">{{filterSpeedData && listFiltersTarif.indexOf(2) != -1 && filterSpeedData + ' Мб/с' || 'Швидкість'}}</v-chip>
                    </template>
                    <v-list dense >
                        <v-list-item @click="changeFilterSpeedData(5)"><v-list-item-title>5 Мб/с</v-list-item-title></v-list-item>
                        <v-list-item @click="changeFilterSpeedData(10)"><v-list-item-title>10 Мб/с</v-list-item-title></v-list-item>
                        <v-list-item @click="changeFilterSpeedData(60)"><v-list-item-title>60 Мб/с</v-list-item-title></v-list-item>
                        <v-list-item @click="changeFilterSpeedData(100)"><v-list-item-title>100 Мб/с</v-list-item-title></v-list-item>
                        <v-list-item @click="changeFilterSpeedData(200)"><v-list-item-title>200 Мб/с</v-list-item-title></v-list-item>
                        <v-list-item @click="changeFilterSpeedData(300)"><v-list-item-title>300 Мб/с</v-list-item-title></v-list-item>
                        <v-list-item @click="changeFilterSpeedData(500)"><v-list-item-title>500 Мб/с</v-list-item-title></v-list-item>
                    </v-list>
                </v-menu>
                <v-chip  label small key="real_ip">Real IP</v-chip>
            </template>
            <template v-else-if="service == 'tv'">
                <v-chip :class="{'d-none': !is_special_tarif_for_distributor}" title="Спеціальні тарифи"  label small  key="is_special">СТ</v-chip>
                <v-chip  label small  key="package1">УПП</v-chip>
                <v-chip   label small  key="package2">Стандарт</v-chip>
            </template>
            <template v-else-if="service == 'inet'">
                <v-chip :class="{'d-none': !is_special_tarif_for_distributor}" title="Спеціальні тарифи"  label small  key="is_special">СТ</v-chip>
                <v-chip label small  key="promo">Акція</v-chip>
                    <v-menu offset-y open-on-hover>
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip  v-bind="attrs" v-on="on" label small  key="speed">{{filterSpeedData && listFiltersTarif.indexOf(2) != -1 && filterSpeedData + ' Мб/с' || 'Швидкість'}}</v-chip>
                        </template>
                        <v-list dense >
                            <v-list-item @click="changeFilterSpeedData(5)"><v-list-item-title>5 Мб/с</v-list-item-title></v-list-item>
                            <v-list-item @click="changeFilterSpeedData(10)"><v-list-item-title>10 Мб/с</v-list-item-title></v-list-item>
                            <v-list-item @click="changeFilterSpeedData(60)"><v-list-item-title>60 Мб/с</v-list-item-title></v-list-item>
                            <v-list-item @click="changeFilterSpeedData(100)"><v-list-item-title>100 Мб/с</v-list-item-title></v-list-item>
                            <v-list-item @click="changeFilterSpeedData(200)"><v-list-item-title>200 Мб/с</v-list-item-title></v-list-item>
                            <v-list-item @click="changeFilterSpeedData(300)"><v-list-item-title>300 Мб/с</v-list-item-title></v-list-item>
                            <v-list-item @click="changeFilterSpeedData(500)"><v-list-item-title>500 Мб/с</v-list-item-title></v-list-item>
                        </v-list>
                    </v-menu>
                    <v-chip  label small key="real_ip">Real IP</v-chip>
            </template>
            
        </v-chip-group>
    </v-row>
    
</template>

<script>
import axios from "axios"
export default {
    props: ["service", "is_special_tarif_for_distributor"],
    data: () => ({
        listFiltersTarif: [],
        filterSpeedData: null,
        loadingFiltersTarif: false,
        rates: [],
        
    }),
    watch:{
        listFiltersTarif(){
            this.apllyFiltersTarifs()
        }
    },
    activated(){
        this.listFiltersTarif = []
        this.filterSpeedData = null
    },
    methods:{
        apllyFiltersTarifs(){
            if(this.listFiltersTarif.length == 0) {
                this.$emit('create-values-rates')
                return
            }
            this.loadingFiltersTarif = true;
            axios({
                headers: { "Content-type": "application/json; charset=UTF-8" },
                method: "GET",
                url: `/api/cabletv/get_list_tarifs/`,
                params: this.getFiltersParams(),
            })
            .then((response) => {
                console.log(response);
                //
                this.rates = response.data
                this.$emit("aplly-filters-tarifs")
            })
            .catch(err => {
                //reject(err)
                //console.log(err);
                this.$router.app.$sheet.show("Помилка!", err);
                
                //console.log(this.$route.query.next)
            })
            .finally(() => (this.loadingFiltersTarif = false))
        },
        changeFilterSpeedData(speed){
            this.filterSpeedData = speed
            this.listFiltersTarif.indexOf(2) == -1 && this.listFiltersTarif.push(2) || this.apllyFiltersTarifs()
        },
        getFiltersParams(){
            let params = {}
            if(!this.is_special_tarif_for_distributor) params['is_special'] = false
            if(this.listFiltersTarif.indexOf(0) != -1) params['is_special'] = true
            
            if(this.service == 'double'){
                params['two_play'] = true
                if(this.listFiltersTarif.indexOf(1) != -1) params['promo'] = true
                if(this.listFiltersTarif.indexOf(2) != -1 && this.filterSpeedData) params['speed'] = this.filterSpeedData
                if(this.listFiltersTarif.indexOf(3) != -1) params['real_ip'] = true
            }
            else if(this.service == 'tv'){
                params['service'] = 1
                params['two_play'] = false
                if(this.listFiltersTarif.indexOf(1) != -1 && this.listFiltersTarif.indexOf(2) != -1) params['package'] = '1,2'
                else if(this.listFiltersTarif.indexOf(1) != -1) params['package'] = 1
                else if(this.listFiltersTarif.indexOf(2) != -1) params['package'] = 2
            }
            else if(this.service == 'inet'){
                params['is_speed'] = true
                params['two_play'] = false
                if(this.listFiltersTarif.indexOf(1) != -1) params['promo'] = true
                if(this.listFiltersTarif.indexOf(2) != -1 && this.filterSpeedData) params['speed'] = this.filterSpeedData
                if(this.listFiltersTarif.indexOf(3) != -1) params['real_ip'] = true
            }
            
            return params
        }
    }
}
</script>
