<template>      
  <v-dialog v-model="dialog" persistent :max-width="450">
    <v-card>
      <v-card-title class="">
        <span v-if="user_phones.length != 0" class="headline">Змінити телефон</span>
        <span v-else class="headline">Додати телефон</span>
      </v-card-title>
      <v-card-text :class="$vuetify.breakpoint.smAndDown ? '':''">
      <v-container class="mt-1 pt-1">
        <v-form 
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" class="pt-6"> 
              <v-row v-if="user_phones.length != 0 && dialog"> 
                <v-col v-for="(phone, keyPhone) in user_phones" :key="phone.id" cols="12" class="py-2">
                  <FieldPhone 
                    :countMainPhones="countMainPhones" 
                    :userID="abonent && abonent.id" 
                    :activatedParrent="showDialog" 
                    :phoneData="user_phones[keyPhone]" 
                    :keyPhoneData="keyPhone" 
                    :globalEventChangePhoneData="globalEventChangePhoneData"
                    v-on:update-phone="emitUpdatePhone" 
                    v-on:change-phone="changePhone(keyPhone)" 
                    v-on:back-phone="backPhone(keyPhone)" 
                    v-on:event-change-phone-data="changeGlobalEventChangePhoneData"
                  /> 
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <div class="text-center mt-3"><v-btn @click="addFieldPhone()" color="primary lighten-1" icon><v-icon>mdi-plus</v-icon></v-btn></div>
      </v-container>
      </v-card-text>
      <v-card-actions>
          <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
          <v-spacer />
      </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
  import FieldPhone from '@/components/Abonents/FieldPhone.vue'
	export default {
    name: 'change-phone-modal',
    components: {
        FieldPhone
    },
    props: ["abonent"],
		data: () => ({
      globalEventChangePhoneData: false,
      loadingSubmit: false,
      item: {},
      dialog: false,
      showDialog: 0,
      valid: true,
      countChangePhones: 0,
      activatedParrent: true,
      user_phones: [],
      form_data: {},
      
      
      textRules: [
        v => !!v || "Це поле обов'язкове",
      ],
      notRules: [
        v => !!v || true ,
      ],
            
    }),
    computed: {
      countMainPhones(){
        return this.user_phones.length != 0 && this.user_phones.filter(x => x.main).length || 0
      }
    },
    mounted(){
      //
    },
    watch:{
      user_phones:{
        handler () {
            //console.log("user_phones GHANGE!")
            //this.countChangePhones += 1
        },
        deep: true,
      },
      abonent:{
        handler () {
          //console.log('AddAbonentModal chane abonent')
          this.dialog && this.show() // Обновляем данные модального окна
        },
        deep: true,
      },
      'form_data.phone'(val){
        //console.log(val)
        if(val == '3') this.form_data.phone = '+3'
        else if(val == '8') this.form_data.phone = '+38'
        else if(val == '0') this.form_data.phone = '+380'
      }

    },
    methods: {
        show(){
          this.showDialog += 1
          let item = this.abonent
          this.globalEventChangePhoneData = false
          
          this.user_phones = JSON.parse(JSON.stringify(item.user_phones))
          //this.user_phones.length == 0 && this.addFieldPhone()
          this.countChangePhones = 0
          this.dialog = true;
        },
        validate () {
          if (this.$refs.form.validate()) {
              this.snackbar = true;
          }
        },
        reset () {
          this.$refs.form.reset()
        },
        closeDialog (){
            this.dialog = false
            this.showMoreFields = false
            this.reset()  
        },
        clearModal(){
          //
        },
        addFieldPhone(){
          let check_phones = this.user_phones.length == 0
          this.user_phones.push({main: check_phones, phone: '', memo: '', spam: false,})
        },
        changePhone(keyPhone){
          
          if(this.user_phones[keyPhone].id){
              this.user_phones[keyPhone].phone_delete = true
              //this.countChangePhones += 1
              //console.log(this.user_phones, 'hide phone')
          }else{
              this.user_phones.splice(keyPhone, 1)
              //console.log(this.user_phones, 'delete')
          } 
          
        },
        backPhone(keyPhone){
          this.user_phones[keyPhone].phone_delete = false
          //this.countChangePhones += 1
        },
        emitUpdatePhone(){
          this.$emit('update-abonent')
        },
        changeGlobalEventChangePhoneData(event){
          this.globalEventChangePhoneData = event
        }
      
    }
}
</script>