<template>
        
        <v-dialog v-model="dialog" persistent max-width="450">
            
            <v-card>
            <v-card-title class="">
            <span class="h6">Додати коментар до заявки</span>
            </v-card-title>
             <div class="text-center mb-10">
                <v-chip label outlined color="primary lighten-1">
                    <v-icon left>
                        mdi-map-marker-outline
                    </v-icon>
                    {{form_data.address }}
                </v-chip>         
            </div>
            
           
            <v-card-text >
             
            
                        <v-form 
                            ref="form_comment"
                            v-model="valid"
                            lazy-validation>
                                <v-row>  
                                    <v-col cols="12" class="py-2">
                                        <v-btn-toggle
                                            v-model="form_data.tag"
                                            dense
                                            color="primary accent-3"
                                            group
                                            
                                            class="d-flex justify-center"
                                        >
                                        <template v-for="(tag, index) in colors">
                                            <v-btn icon :value="tag.id" :key="index">
                                                <v-icon :color="tag.color">label</v-icon>
                                            </v-btn>
                                        </template>

                                        </v-btn-toggle>
                                        
                                    </v-col>
                                    
                                    <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                                        <v-select v-if="field.type == 'select'"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :label="field.title"
                                        v-model="form_data[field.name]"
                                        :name="field.name"
                                        :rules="field.rules && textRules || notRules"
                                        :items="field.values"
                                        :item-text="field.name == 'user_worker' ? 'first_name':'name'"
                                        item-value="id"
                                        :disabled="field.disabled"
                                        :clearable="field.clearable"
                                        >
                                        </v-select>

                                        <v-textarea v-else-if="field.type == 'textarea'"
                                            dense
                                            rows="3"
                                            outlined
                                            hide-details="auto"
                                            :label="field.title"
                                            v-model="form_data[field.name]"
                                            :name="field.name"
                                            :rules="field.rules && textRules || notRules"
                                            :type="field.type"
                                            :disabled="field.disabled"
                                        ></v-textarea>
                                        
                                        <v-text-field v-else
                                        dense
                                        outlined
                                        hide-details="auto"
                                        :label="field.title"
                                        v-model="form_data[field.name]"
                                        :name="field.name"
                                        :rules="field.rules && textRules || notRules"
                                        :type="field.type"
                                        :disabled="field.disabled"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col  cols="12" class="py-2">
                                        <v-file-input
                                            v-model="file"
                                        prepend-icon=""
                                            id="myFile"
                                            dense
                                            outlined
                                            accept="image/png, image/jpeg, image/bmp"
                                            chips
                                            label="Зображення"
                                            
                                        ></v-file-input>
                                    </v-col>
                                </v-row>
                        </v-form>
                    
            
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" :loading="loading" @click="sendForm">Додати</v-btn>
                
                
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    
    import axios from "axios"
    import { mapGetters } from "vuex"
	export default {
        name: 'job-modal-add-comment-set',
		data: () => ({
            file: [],
            tab: null,
            loading:false,
            dialog: false,
            valid: true,
            item: null,
            form_data: {},
            fields: [
                {title: "Тема",     name: "comment_subj",     rules: true,  type: "select",   disabled: false, value: "", values: []},
                {title: "Опис",    name: "comment",     rules: true,  type: "textarea", disabled: false, value: "" },

            ],
            color_tag: 'deep-orange',
            colors: [
                //{color: 'grey', name_color: 'Grey', id: 0},
                {color: 'deep-orange', name_color: 'Orange', id: 1},
                {color: 'primary', name_color: 'Primary', id: 2},
                {color: 'orange', name_color: 'Orange 2', id: 3},
                {color: 'green', name_color: 'Green', id: 4},
                {color: 'pink', name_color: 'Pink', id: 5},
                {color: 'grey darken-4', name_color: 'Black', id: 6},
                
            ],

            
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            ...mapGetters({
                user: "auth/user",
                job_comment_type: "job_types/comment_type"
            }),
        },
        mounted(){
            this.createJobTypes()
        },
        methods: {
            show(item){
                this.file = [],
                this.item = item
                this.form_data.order = item.id
                this.form_data.address = item.address
                this.form_data.tag = 0
                this.dialog = true;
            },
            validate () {
                if (this.$refs.form_comment.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form_comment && this.$refs.form_comment.reset();
                //this.$refs.form_close && this.$refs.form_close.reset();
            },
            closeDialog (){
                
                this.reset() 
                this.dialog = false
                //console.log(this.tab)
                
            },
            createJobTypes(){
                this.fields.find(x => x.name == "comment_subj").values = this.job_comment_type
            },
            async createFormData(){
                const body = new FormData()
                this.file && body.append('pictures', this.file)
                
                for (let i in this.form_data){
                    this.form_data[i] && body.append(i, this.form_data[i])
                }
                //body.append(...this.form_data)
                return body
            },
            async sendForm() {
                //console.log(this.form_data)
                if(this.$refs.form_comment.validate()){
                    const body = await this.createFormData()
                    this.loading = true;
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "POST",
                        url: "/api/service/create_comment/",
                        data: body,
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-job');
                        this.$router.app.$snack.show("Коментар додано!"); 
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loading = false))
                }
            },
            
            
        }
    }
</script>