<template>
  <v-card class="mb-4" outlined elevation="0">
    <!-- Toolbar Картки Інтернет -->
    <v-toolbar
      :color="service_inet_billing ? !checkDoubleTarif && 'accent' || 'secondary lighten-1' : 'grey '"
      :class="$vuetify.theme.dark ? '' : 'lighten-2'"
      dense
      flat
    >
      <v-toolbar-title>
        
        <h3 v-if="!checkDoubleTarif" class="mr-6 secondary--text">Інтернет</h3>
        <h4 v-else class="mr-6 accent--text">Додатково</h4>
      </v-toolbar-title>
      <v-spacer />
      
      <!--<v-btn 
          v-if="user && user.user_type != 2 && service_inet"
          :loading="loadingUpdateAbonentBilling"
          color="secondary"
          icon  
          title="Поновити данні з білінгу"
          @click="updateAbonentBilling"
        >
          <v-icon>autorenew</v-icon>
      </v-btn>-->
      <template v-if="user && user.user_type != 2">
        <v-btn
          v-if="!service_inet || service_inet.status == null"
          color="secondary"
          class=""
          icon
          title="Додати сервіс Інтернет"
          @click="$parent.$refs.addService.show('add_inet', 'inet')"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-menu v-else buttom offset-y>
            <template v-slot:activator="{ on }">
              <v-btn 
                v-on="on"
                class="" 
                :color="checkDoubleTarif ? 'accent' : 'secondary'"
                icon
                title="Опції"
              >
                <v-icon >mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="$parent.$refs.addService.show('change_inet', 'inet')">
                <v-icon class="pr-3">mdi-pencil</v-icon>
                <v-list-item-title>Редагувати сервіс Інтернет</v-list-item-title> 
              </v-list-item>
              <v-list-item v-if="service_tv && service_inet_billing && checkBalance" 
              @click="$parent.$refs.confirmTransferBalance.show(true, service_tv.operator)"
              >
                <v-icon class="pr-3">mdi-swap-horizontal</v-icon>
                <v-list-item-title>Перенос залишків</v-list-item-title> 
              </v-list-item>
              <v-list-item 
              @click="$parent.$refs.addCredit.show('inet')"
              >
                <v-icon class="pr-3">mdi-credit-card-plus-outline</v-icon>
                <v-list-item-title>Додати кредит</v-list-item-title>
              </v-list-item>
              
          </v-list>
        </v-menu>
      </template>

      <template v-else> <!-- для монтера-->
        <v-btn
          :color="checkDoubleTarif ? 'accent' : 'secondary'"
          class=""
          icon
          title="Додати кредит"
          @click="$parent.$refs.addCredit.show('inet')"
        >
          <v-icon>mdi-credit-card-plus-outline</v-icon>
        </v-btn>
      </template>

      <v-btn
        v-if="service_inet && $vuetify.breakpoint.smAndDown || checkDoubleTarif"
        :color="checkDoubleTarif ? 'accent' : 'secondary'"
        icon
        @click="showCardInet = !showCardInet"
      >
        <v-icon>{{ showCardInet && 'mdi-chevron-up' || 'mdi-chevron-down'}}</v-icon>
      </v-btn> 
    </v-toolbar>
    <!----------------------------->
    <!-- Блок списку полів -->
    <v-list dense v-if="service_inet && showCardInet">
      <v-subheader v-if="checkDoubleTarif" class="primary--text">СЕРВІС ІНТЕРНЕТ: </v-subheader>
      <!-- Поле: Статус -->
      <template v-if="!checkDoubleTarif">
        <v-list-item>
          <v-list-item-content class="align-self-start pt-3" style="max-width: 150px;">
            <v-list-item-title >Статус:</v-list-item-title>
          </v-list-item-content> 
          <v-list-item-content>
            <span v-if="service_inet_billing">
              <v-chip @click="$vuetify.goTo('#card-connect-inet')" small class="mr-2 mb-1" text-color="white" :color="service_inet_billing && service_inet_billing.status ? 'success': 'red' " >{{ service_inet_billing && service_inet_billing.status && "Підключений" || "Відключений" }}</v-chip>
              <v-chip v-if="abonentBilling && abonentBilling.not_check_sub_fee" small color="error" class="white--text mb-1">Аб.плата не знята</v-chip>
            </span>
          </v-list-item-content>
          </v-list-item>
          <v-divider />
      </template>
      <!------------------>
      
      <!-- Поле: Кредит -->
      <template v-if="service_inet_billing && service_inet_billing.balance_additional.credit && service_inet_billing.balance_additional.credit != '0.00'">
        <v-list-item>
          <v-list-item-content class="align-self-start pt-3" style="max-width: 150px;">
            <v-list-item-title >Кредит:
              <v-icon :title="`Надано до: ${service_inet.sheduled_credit.date_due}`" v-if="service_inet.sheduled_credit" style="vertical-align: sub;" class="ml-2" color="orange">mdi-clock-outline</v-icon>
            </v-list-item-title>
          </v-list-item-content> 
          <v-list-item-content>
            <span >
              <v-chip @click="$parent.$refs.addCredit.show('inet')" :title="`Опис: ${service_inet_billing.balance_additional.credit_descr}`"  small  class="white--text mt-1 mr-1" color="orange" style="vertical-align: baseline;">
                <v-icon small color="" left>mdi-credit-card-outline</v-icon>
                {{ service_inet_billing.balance_additional.credit }} грн.
              </v-chip>
            </span>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </template>      
      <!------------------>

      <!-- Згенерований Список полів statusInternetItems -->
      <template v-for="(item, index) in statusInternetItems">
        <!-- Не показуємо поле Тариф, якщо подвійна послуга -->
        <template v-if="!checkDoubleTarif || item.name != 'tarif'"> 
        <!---------------------------------------------------->  
          <v-divider v-if="index != 0" :key="item.title + 1"></v-divider>
          
          <v-list-item :key="item.title">
            <!-- Кастомні Заголовки полів -->
            <v-list-item-content style="max-width: 150px;">
              <v-list-item-title >
                {{ item.title }}
                <template v-if="item.name == 'tarif' ">
                  <!-- Tooltip: Деталі запланованого тарифу -->
                  <template v-if="isNotSameTarif">
                    <DetailTarifInet :is_nextMonth="true" :tarifInet="tarifInetNextMonth" :tarifInetData="tarifInetNextMonthData" :tarifBillingData="service_inet_billing && service_inet_billing.tarif_next"/>
                  </template>
                  <template v-if="isSameNextTarifInetInTv">
                    <DetailTarif :is_nextMonth="true" :tarif=isSameNextTarifInetInTv.next_inet.next_tarif :tarifNextData="isSameNextTarifInetInTv" :toServiceInet="true" />
                  </template>
                  <template v-if="isSameNextDoubleTarif">
                    <DetailTarif :is_nextMonth="true" :tarif=isSameNextDoubleTarif.next_tarif :tarifNextData="isSameNextDoubleTarif" :toServiceInet="true" />
                  </template>
                  <!------------------------------------------>
                  <template v-if="service_inet_billing && service_inet_billing.balance_additional && service_inet_billing.balance_additional.discount != 0">
                    <v-tooltip bottom color="secondary darken-1">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" style="vertical-align: sub;" class="ml-1" color="primary lighten-2">mdi-percent-circle</v-icon>
                      </template>
                      <span>Знижка: <b>{{service_inet_billing.balance_additional.discount}}%</b></span>
                    </v-tooltip>
                  </template>
                </template>
              </v-list-item-title>
            </v-list-item-content>
            <!------------------------------>
            <!-- Кастомні значення полів -->
            <v-list-item-content>

              <template v-if="item.name == 'user_enterprise_name'">
                <span class="deep-orange--text">{{ abonentBilling && abonentBilling.user_enterprise_name }} </span>
              </template>

              <template v-else-if="item.name == 'balance'">
                <span class="d-flex justify-space-between align-center"> 
                  <span>
                    <!-- Значення балансу -->
                    <v-tooltip bottom color="secondary darken-1" content-class='custom-tooltip'>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          <span :class="{'error--text': checkMinusBalanceInet}"> {{ service_inet_billing && service_inet_billing.balance }} грн. </span>
                          
                        </span>
                      </template>
                      <!-- Тіло Tooltip: Усі баланси -->
                      <span>
                        <template v-if="service_inet_billing">
                          <strong>ІНТЕРНЕТ</strong><br>
                          <template v-for="(item, index) in balanceList">
                            <div :key="index" class="d-flex justify-space-between">
                              <span class="mr-auto" :class="item.bold ? 'font-weight-bold' : ''">{{ item.title }}</span> 
                              <span class="ml-4 text-end" :class="{ 'deep-orange--text' : negativeBalance(item.item),  'font-weight-bold' : item.bold }">{{ service_inet_billing.balance_additional && service_inet_billing.balance_additional[item.item] }}</span>
                              <br>
                            </div>
                          </template>
                        </template>
                      </span>
                      <!------------------------------->
                    </v-tooltip>
                    <!---------------------->
                  </span>
                  <!-- Список активованих опцій cardItemsChipsNK (Новаком,...) -->
                  <span>
                    <v-chip 
                      v-if="service_inet_billing && service_inet_billing.novakom && novakomData[service_inet_billing.novakom]"
                      :title="novakomData[service_inet_billing.novakom].name"
                      :color="novakomData[service_inet_billing.novakom].color"
                      class="pa-1 ml-1"
                      outlined
                      small
                      pill
                    >
                      {{ novakomData[service_inet_billing.novakom].title }}
                    </v-chip>
                  
                </span>
                  <!------------------------------------------------------------->
                </span>
              </template>

              <template v-else-if="item.name == 'tarif'">
                <span class="d-flex justify-space-between align-center">
                  <!-- Tooltip: Деталі поточного тарифу -->
                  <DetailTarifInet :is_nextMonth="false" :tarifInet="tarifInet" :tarifInetData="tarifInetData" :tarifBillingData="service_inet_billing && service_inet_billing.tarif"/>
                  <!-------------------------------------->
                  <v-btn
                  v-if="!checkDoubleTarif"
                  title="Змінити Тариф"
                  color="primary"
                  small
                  icon
                  @click="checkPlanTarif()"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </span>
              </template>

              

              <template v-else-if="item.name == 'date_contract'">
                <span color="primary lighten-1" :title="`№ Договору: ${ service_inet_billing && service_inet_billing.contract }`">{{ service_inet_billing && service_inet_billing[item.name] }}</span> 
              </template>

              <template v-else>
                {{ service_inet_billing && service_inet_billing[item.name] }}
              <!--<v-list-item-title v-text="item.value"></v-list-item-title>-->
              </template>
            </v-list-item-content>
            <!----------------------------->
          </v-list-item>
        </template>
      
      </template>
      <!--------------------------------------------------->
      <!-- Поле: Послуга Real IP -->
      <template v-if="abonentBilling && abonentBilling.real_ip">
        <v-divider />
        <v-list-item>
          <v-list-item-content class="align-self-start pt-3" style="max-width: 150px;">
            <v-list-item-title >Послуга Real IP:</v-list-item-title>
          </v-list-item-content> 
          <v-list-item-content>
            {{ abonentBilling.real_ip }}
          </v-list-item-content>
          </v-list-item>
      </template>
      <!------------------>
      <template v-if="!checkDoubleTarif">
        <v-divider />
        <v-list-item>
          <v-list-item-content class="align-self-start pt-3" style="max-width: 150px;">
            <v-list-item-title >Постачальник:</v-list-item-title>
          </v-list-item-content> 
          <v-list-item-content>
              {{ service_inet_billing && operators.find(x => x.id == service_inet_billing.operator).name}}
          </v-list-item-content>
        </v-list-item>
          
      </template>
    </v-list>
    <!----------------------->
    <v-card-actions v-if="cardItemsChips.find(item => abonentBilling && abonentBilling[item.name])">
      <template v-for="(item, index) in cardItemsChips">
        <v-chip
          v-if="service_inet && abonentBilling[item.name]"
          :title="abonentBilling[item.name]"
          :color="item.color"
          :key="index"
          class="ma-2"
          outlined
          small
          pill
        >
          {{ item.title }}
        </v-chip>
      </template>
    </v-card-actions>
    <v-overlay z-index=4 :value="loadingUpdateAbonentBilling" absolute >
      <v-progress-circular
        indeterminate
        size="48"
      ></v-progress-circular>
    </v-overlay>
  </v-card> 
</template>
<script>
import DetailTarifInet from '@/components/AbonentInfo/TabInfoCards/DetailTarifInet.vue'
import DetailTarif from '@/components/AbonentInfo/TabInfoCards/DetailTarif.vue'
export default {
    props: ["user", "service_inet", "operators", "rates", "abonentBilling", "loadingUpdateAbonentBilling", "service_inet_billing", "service_tv_billing", "service_tv", "checkDoubleTarif", "activeComponentAbonentInfo"],
    data: () => ({ 
        showCardInet: true,
        loading: false,
        novakomData: {0: {name: "Не передавати дані", title: ""}, 1: {name: "Нульові значення передаються у Нова-Ком", title: "НK", color: "blue"}, 2: {name: "Баланс (стан рахунку) абонента передається у Нова-Ком", title: "НБ", color: "red"}, 3: {name: "Баланс (стан рахунку) абонента передається у Нова-Ком без переплати", title: "НП", color: "green"}},
        cardItemsChips: [
          //{title: "НK", discript: 'Данні абонента передаються у НоваКом',     name: "novakom", color: "blue"},
          //{title: "НБ", discript: 'Баланс (стан рахунку) абонента передається у НоваКом',     name: "balance_novakom", color: "red"},
          //{title: "Real IP", discript: 'Реальна IP адреса',  name: "real_ip", color: "blue"},
          //{title: "Юр. Особа", discript: 'Реальна IP адреса',  name: "user_enterprise_name", color: "deep-orange"},
        ],
        cardItemsChipsNK: [
          {title: "НK", discript: 'Данні абонента передаються у Нова-Ком',     name: "novakom", color: "blue"},
          {title: "НБ", discript: 'Баланс (стан рахунку) абонента передається у Нова-Ком',     name: "balance_novakom", color: "red"},
        ],
        statusInternetItems: [
        //{ title: 'Статус:',        name: "status", info_billing: true,},
        { title: 'Баланс:',        name: 'balance', info_billing: true, },
        //{ title: 'Кредит:',        name: 'user_credit', info_billing: true, },
        { title: 'Тариф:',         name: "tarif", info_billing: true, },
        //{ title: '№ Договору:',    name: "contract", info_billing: true, },
        { title: 'Дата договору:', name: "date_contract", info_billing: true, },
        //{ title: 'Юр. Особа:', name: "user_enterprise_name", info_billing: true, },
        { title: 'Особовий рахунок:', name: "user_id", info_billing: false, },
        //{title: "Постачальник:",       name: "operator", info_billing: false, type: "text"},
        ],
        balanceList: [
          { title: "Вартість тарифу:  ", item: "tarif_cost" },
          { title: "", item: "" },
          { title: "Сума на початок місяця:  ", item: "balance_start_month" },
          { title: "Сума Абонплати:  ", item: "monthly_fee" },
          { title: "Сума Послуг:  ", item: "monthly_services" },
          { title: "Платежі за місяць:  ", item: "monthly_pays" },
          { title: "", item: "" },
          { title: "Поточний баланс:  ", item: "current_balance", bold: true},
          { title: "Кредит: ", item:  "credit" },
          { title: "Знижка: ", item:  "discount" },
          { title: "Борг:  ", item: "debt" },
          { title: "", item: "" },
          { title: "Сума до сплати:  ", item: "amount_to_pay" },
        ],
        detailNextTarif: [
          { title: "", item: "" },
          { title: "Вартість тарифу:  ", item: "price" },
          { title: "Знижка: ", item:  "discount" },
          { title: "Сума Абонплати:  ", item: "fee" }
        ],

    }),
    components:{ DetailTarifInet, DetailTarif },
    computed:{
      tarifInet(){ 
        try{ return this.service_inet_billing?.tarif && this.rates.find(x => x.id == this.service_inet_billing?.tarif.id).name
        }catch{ return this.service_inet_billing?.tarif?.id == '0' && 'Інший' || '' }
      },
      tarifInetData(){ 
        try{ return this.service_inet_billing?.tarif && this.rates.find(x => x.id == this.service_inet_billing?.tarif.id)
        }catch{ return null }
      },
      tarifInetNextMonthData(){ 
        try{ return this.service_inet_billing?.tarif_next.id && this.rates.find(x => x.id == this.service_inet_billing?.tarif_next.id)
        }catch{ return null }
      },
      tarifInetNextMonth(){ 
        try{ return this.service_inet_billing?.tarif_next.id && this.rates.find(x => x.id == this.service_inet_billing?.tarif_next.id).name
        }catch{ return this.abonentBilling?.user_next_tariff == '0' && 'Інший' || '' }
      },
      isNotSameTarif(){
        return this.service_inet_billing?.tarif.id != this.service_inet_billing?.tarif_next?.id || this.service_inet_billing?.tarif.discount != this.service_inet_billing?.tarif_next?.discount
      },
      isSameNextTarifInetInTv(){
        /* Перевіряємо чи є заплановані зміни тарифу інтернет в базі телебачення */
        let next_tarif = null
        if(this.service_tv?.change_tarif && this.service_tv.change_tarif[0]?.next_inet){
          next_tarif = this.service_tv.change_tarif[0]
        }
        return next_tarif || false
      },
      isSameNextDoubleTarif(){
        let next_tarif = null
        let check_next_tarif_double = false
        if(this.service_tv?.change_tarif && this.service_tv.change_tarif[0]?.next_tarif){
          next_tarif = this.service_tv.change_tarif[0]
          check_next_tarif_double = next_tarif?.next_tarif.service == 1 && next_tarif?.next_tarif.speed != 0 && true || false
        }
        return check_next_tarif_double && next_tarif || false
      },
      isMountSubPay(){
        return (this.service_inet_billing?.user_next_balance && Number(this.service_inet_billing?.user_next_balance) || 0) > (this.service_inet_billing?.user_current_balance && Number(this.service_inet_billing?.user_current_balance) || 0)
      },
      checkBalance(){
        return this.service_inet_billing?.balance && Number(this.service_inet_billing?.balance) > 0 || false
      },
      checkMinusBalanceInet(){
        let current = this.service_inet_billing?.balance_additional?.current_balance && Number(this.service_inet_billing.balance_additional.current_balance) || 0
        let credit = this.service_inet_billing?.balance_additional?.credit && Number(this.service_inet_billing.balance_additional.credit) || 0
        return (current + credit) < 0
      }
    },
    watch:{
      checkDoubleTarif(){
        if(this.checkDoubleTarif){
          this.showCardInet = false
        }else this.showCardInet = true
      }
    },
    methods: {
      goToTab(){
        this.$parent.$parent.toTab(1)
      },
      updateAbonentBilling(){
        this.$emit("update-abonent-billing")
      },
      negativeBalance(balance){
        return balance && this.service_inet_billing?.balance_additional[balance].indexOf('-') != -1 || false
      },
      async checkPlanTarif(){
        let question = await this.$parent.$refs.confirmInfoForPlanChangeTarif.open("inet")
        if(question) this.$parent.$refs.addRatesInet.show()
      }
    }
}
</script>