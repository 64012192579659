<template>
  
  <div>
    <keep-alive>
      <AddPaysModal ref="addPays" v-on:update-pays="updateTable += 1" v-on:update-total="updateTotal()"/>
    </keep-alive>
    <keep-alive>
        <Filters ref="filtersPays" v-on:apply-filters="checkGetPays()" for="pays_abonent" />
      </keep-alive>
      <ConfirmDialog ref="confirmDialog"/>
    <v-row justify="center" v-show="viewActPays">
      <v-col  cols=12 md=10 lg=8>
        <keep-alive>
          <TableActPays v-on:view-pays="viewActPays = false" :viewActPays="viewActPays"   :inetPays="inetPays" :abonent="abonent" :service_tv="service_tv" :service_inet="service_inet" />
        </keep-alive>
      </v-col>
    </v-row>
    <v-row justify="center" v-show="!viewActPays">
        
      
      <v-col  cols=12 md=9 >
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Немає даних для відображення"
          :items="pays"
          :options.sync="options"
          :server-items-length="paginations.count"
          :loading="loading"
          :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
          class="elevation-1"
          :footer-props="footer"
          focusable
        >
          <template v-slot:top>

            <v-toolbar flat color="secondary lighten-1" dark dense>
              <v-toolbar-title class=""><h3 class="mr-6  accent--text">Платежі</h3></v-toolbar-title>
            
              <v-spacer/>
              <v-btn v-if="service_tv" :icon="$vuetify.breakpoint.mobile" :outlined="!$vuetify.breakpoint.mobile" small :color="!inetPays ? 'accent' : 'grey'"
              :class="!inetPays ? 'primary--text' : ''"
              @click="inetPays = false, checkGetPays()"
              >
                 <v-icon v-if="$vuetify.breakpoint.mobile">mdi-television</v-icon> 
                 <span v-else>ТБ</span>
              </v-btn>
              <v-btn v-if="service_inet" :icon="$vuetify.breakpoint.mobile" :outlined="!$vuetify.breakpoint.mobile" small :color="inetPays ? 'accent' : 'grey'"
              :class="inetPays ? 'primary--text' : ''"
              class="ml-3"
              @click="inetPays = true, checkGetPays()"
              >
              <v-icon v-if="$vuetify.breakpoint.mobile">mdi-web</v-icon> 
                 <span v-else>Інтернет</span>
                
              </v-btn> 
              <!--<v-select
                v-model="selectYear"
                :items="filterYears"
                style="max-width: 100px"
                item-text="title"
                item-value="title"
                hide-details
                outlined
                dense
              >
              <template v-slot:prepend-item>
                <v-subheader>РОКИ:</v-subheader>
              </template>
              <template v-slot:item="{item, on}">
                <v-list-item v-on="on">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="'Залишок: '+ item.amount"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              </v-select>-->

              <v-spacer />
              
              <v-btn 
                v-if="user && user.user_type != 2"
                icon @click="$refs.addPays.show(abonent, inetPays)"> 
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn 
                icon 
                :loading="loading"
                @click="getPays()">
                <v-icon>autorenew</v-icon>
              </v-btn>
              <v-btn  
                icon 
                :disabled="loading"
                @click="$refs.filtersPays.show()"
              >
                <v-badge
                :value="countFilters > 0"
                color="accent"
                text="primary"
                :content="countFilters"
                dark
              >
                <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
              

            </v-toolbar>
            
          </template>
          <template
            v-if="countFilters > 0"
            v-slot:header="{ props: { headers } }"
          >
            <thead>
              <tr>
                <th :colspan="headers.length">
                  Фільтри: 
                  <keep-alive>
                    <FiltersChips :filtersComponents="filtersComponents" v-on:clear-filter="clearFilter" :is_close="true" v-on:open-filters="$refs.filtersPays.show()"/>
                  </keep-alive>
                </th>
              </tr>
            </thead>
          </template>

          <!--<template v-slot:body.prepend="{ headers }">
            <tr>
              <td :colspan="headers.length">
                  <v-chip 
                    v-for="(year, key) of [2005,2006,2007,2008,2009,2006,2007,2008]" 
                    :key="key"
                    color="primary lighten-1"
                    class="ma-2"
                    dark
                    >
                    {{ year }}
                  </v-chip> 
              </td>
            </tr>
          </template>-->

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.index="{ item }">
              <span>{{ pays.indexOf(item) + paginations.start_index }}</span> 
        </template>
        <template #item.date="{ item }">
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.date }}</span>
            </template>
            <span>Дата проведення платежу:<br>{{ item.date_pay }}</span>
          </v-tooltip>
        </template>
        <template #item.service="{ item }">
          <v-icon v-if="item && item.service == 1" color="green darken-2" >mdi-television</v-icon> 
          <v-icon v-else color="light-blue darken-2" >mdi-web</v-icon> 
        </template>
        <template #item.amount="{ item }">
              <span>{{ item.amount > 0 && item.amount || ''}}</span>
        </template>
        <template #item.amount2="{ item }">
              <span>{{ 0 > item.amount && item.amount || ''}}</span>
        </template>
        <template #item.type_pay="{ item }">
          <v-badge
              :value="item && item.manual"
              inline
              icon="mdi-hand-back-left"
              :title="item && item.manual && 'Ручне проведення'"
            >
          {{ item && item.type_pay && pays_type.find(x => x.id == item.type_pay).name}}
          </v-badge>
        </template>
        <template #item.memo="{ item }">
            <span :title="item.memo">{{ item && item.memo && formatMemo(item.memo) }} </span>
          </template>
        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
          {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
        </template>
        </v-data-table>
      </v-col>
      <keep-alive>
          <PaysTotal ref="totalPays" :toolbar_dense="true" :for_abonent="true" :abonent_pcode="abonent.user_pcode" v-on:view-act="viewActPays = true"/>
        </keep-alive>
      </v-row>
  </div>
</template>

<script>
  import axios from "axios"
  import { mapGetters } from "vuex"
  import Filters from '@/components/Abonents/Filters.vue'
  import FiltersChips from '@/components/Abonents/FiltersChips.vue'
  import PaysTotal from '@/components/Pays/PaysTotal.vue'
  import TableActPays from '@/components/AbonentInfo/TableActPays.vue'

  
  //import AddUserModal from '@/components/Admin/Menu/AddUserModal.vue'
  export default {
    props: ['abonent', 'user'],
    name: "abonent-tab-pays",
    components: {
      PaysTotal,
      Filters,
      FiltersChips,
      TableActPays
    },
    data: () => ({
      viewActPays: false,
      inetPays: false,
      updateTable: 0,
      pays: [],
      loading: false,
      options: {},
      paginations: {},
      countFilters: 0,
      filterYears: [
        {title: 2009, amount: -238},
        {title: 2010, amount: 0},
        {title: 2011, amount: -23},
        {title: 2012, amount: 148},
      ],
      selectYear: null,
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'Дата', value: 'date',  },
        { text: 'Послуга', value: 'service', sortable: false, },
        { text: 'Нарахування', value: 'amount2', cellClass: 'text-end', sortable: false, },
        { text: "Оплата", value: 'amount', cellClass: 'text-end' },
        { text: 'Тип', value: 'type_pay', },
        { text: 'Обробив', value: 'user_worker', },
        { text: 'Примітки', value: 'memo', sortable: false, },
      ],
      footer: {
          "items-per-page-options": [10,25,50],
          "items-per-page-text": " ", //"Абонентів на сторінці:"
          "items-per-page-all-text": "Всі",
          "show-first-last-page": true,
          "show-current-page": true,
      },
    }),
    computed: {
      ...mapGetters({
        pays_type: "filters/pays_type"
      }), 
      service_inet() {
        return this.abonent?.user_service?.find(x => x.service == 2)
      },
      service_tv() {
        return this.abonent?.user_service?.find(x => x.service == 1)
      },
      actualServiceInternet(){
        return !this.service_tv &&  this.service_inet 
      }
    },
    watch: {
      options: {
        handler (val, oldVal) {
          oldVal.page && this.getPays();
        },
        deep: true,
      },
      updateTable(){
        this.updateTable && this.getPays();
      }
    },
    activated() {
      this.inetPays = this.actualServiceInternet && true || false
      this.viewActPays = false
      this.pays = []
      //if(this.service_inet) this.inetPays = true

      this.checkGetPays()
    },
    deactivated(){
      this.inetPays = false
    },
    methods: {
      updateTotal(){
        this.$refs.totalPays.getTotalPays(this.$refs.filtersPays.filters, this.inetPays) 
      },
      formatMemo(memo){
        if(memo.length > 9 ){
          return memo.substr(0, 9) + '...'
        }
        else return memo
      },
      checkGetPays(){
        this.updateTotal()
        if(this.options.page == 1){
          this.getPays()
        }else{
          this.options.page = 1;
        }
      },
      clearFilter(filter_name){
        this.$refs.filtersPays.filters.find(x => x.name == filter_name).value = ''
        this.$refs.filtersPays.apply()
      },
      getFiltersOfComponents(){
        return this.$refs.filtersPays.filters.filter(x => x.value && x.value != '') 
      },
      filterFormat() {
        this.countFilters = 0
        const temp = {};
        temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
        temp.itemsPerPage = this.options.itemsPerPage;
        temp.page = this.options.page;
        
        //if(this.search) temp.search = this.search
        
        /* получение значений фильтрации и просчет колличества фильтров */
        this.filtersComponents = this.getFiltersOfComponents()
        this.filtersComponents.forEach(filter => temp[filter.name] = filter.value + '');
        this.countFilters = this.filtersComponents.length
        
        return temp; 
      },
      getPays(){
        this.loading = true 
        if(this.inetPays){
          axios({
              method: "get",
              url: this.$router.app.devUrlBillingAPI,
              params: {req_cmd: "getUserPayments", pcode: this.abonent?.user_pcode, ...this.filterFormat() },
              
          })
          .then( response =>{
            //console.log(response.data)
            if(response.data.resp_status == "OK"){
              this.pays = response.data.resp_result.result
              //this.paginations.count = response.data.resp_result.length
              this.paginations = response.data.resp_result.paginations
            }else{
              this.pays = []
              this.paginations = {}
            }
          })
          .catch(() => {})
          .finally(() => (this.loading = false)) 
        }else{
          axios.get(`/api/cabletv/get_user/${this.$route.params.id}/pays/`, {params: this.filterFormat()})
          .then( response =>{
            //console.log(response.data)
            this.pays = response.data.results
            this.paginations = response.data.paginations
          })
          .catch(() => {})
          .finally(() => (this.loading = false)) 
        }
      },
    } 
  }
</script>

  
