<template>
  <div>
    
    <v-row>
      <v-col class="pt-0">
        <v-list three-line class="px-0">
      <!--<v-row justify="space-between" class="ml-3 mb-1" :class="$vuetify.breakpoint.smAndDown ? 'mt-1' : ''"> <v-subheader><h3 >Коментарі до заявки:</h3></v-subheader> <v-subheader><v-btn fab small color="primary"><v-icon @click="$emit('open-modal')">mdi-plus</v-icon></v-btn></v-subheader></v-row>-->
          <template v-for="(item, index) in commentsHouse">

            <v-list-item
              v-if="!item.delete || showDeleteComment"
              :key="index + 'house_comment'"
              class="px-0"
            >
              <v-list-item-avatar :color="item.delete ? 'grey' : icons[item.comment_subj].color">
                <v-icon
                  dark
                >
                {{ icons[item.comment_subj].icon }}
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content :style="{'color': item.delete ? 'grey' : ''}">
                <v-list-item-subtitle ><span class="grey--text">{{ item.date }}</span> <v-chip v-if="item.delete" class="mb-1 ml-1" x-small outlined color="error">Видалений</v-chip> </v-list-item-subtitle>

                <v-list-item-title>{{ item.comment_subj }} 
                  <v-btn v-if="item.pictures && item.delete" small title="Зображення" icon :color="item.delete ? 'grey' : primary" @click="dialogPicturesSrc = item.pictures, dialogPictures = true">
                      <v-icon>mdi-image-outline</v-icon>
                    </v-btn> </v-list-item-title>
                
                <span>
                  <span style="white-space: break-spaces;" class="">{{ item.comment }}
                </span> 
                <small :class="item.delete ? 'grey--text' : 'primary--text' "> ({{ item.user }})</small>
                <div class="d-flex justify-center my-2" v-if="item.pictures && !item.delete">
                    <v-img 
                      lazy-src=""
                      
                      max-width="250"
                      :src="item.pictures"
                      @click="dialogPicturesSrc = item.pictures, dialogPictures = true"
                    ></v-img>

                </div>
              </span>
              </v-list-item-content>
              <v-list-item-action v-if="!item.delete">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-1" color="grey" v-bind="attrs" v-on="on" @click="1+1" icon> 
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="$parent.$parent.$refs.changeCommentHouseSet.show(item)">
                      <v-icon class="pr-3">mdi-pencil</v-icon>
                      <v-list-item-title>Змінити</v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                      target="_blank" 
                      :href="`https://t.me/share/url?url=${encodeURIComponent(`Пересланий коментар будинку: `)}&text=${encodeURIComponent(`**\n${item.street_name }, ${item.house }${item.letter }**\n( ${item.comment_subj} ): ${item.comment}`)}`" 
                      >
                      <v-icon class="pr-3">mdi-share-variant</v-icon>
                      <v-list-item-title>Поділитись</v-list-item-title>
                    </v-list-item>
                    <v-divider class=""></v-divider>
                    <v-list-item @click="openConfirmDialog(item)">
                      <v-icon class="pr-3">mdi-delete-outline</v-icon>
                      <v-list-item-title>Видалити</v-list-item-title>
                    </v-list-item>
                    
                  </v-list>
                </v-menu>
                
                
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="item.lenght != 0"
              :key="index"
              :inset="true"
            ></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog_delete" persistent max-width="330">      
        <v-card>
          <v-card-title class="text-h5 text-center">
            Видалити коментар?
          </v-card-title>
          <v-card-text class="text-center">Ви впевнені, що хочете видалити коментар?</v-card-text>
          <v-card-actions>
            <v-btn
              color="deep-orange darken-1"
              text
              @click="closeConfirmDialog"
            >
              Ні
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary lighten-1"
              text
              @click="deleteComment"
            >
              Так
            </v-btn>
          </v-card-actions>
        </v-card>   
      </v-dialog>  
    <v-dialog
      v-model="dialogPictures"
      width="500"
    >
      <div class="d-flex justify-center" v-if="dialogPicturesSrc">
        <v-img
          lazy-src=""
          :src="dialogPicturesSrc"
          @click="dialogPictures = false"
        >
      </v-img>
      </div>
    </v-dialog>  
  </div>
</template>
<script>
import axios from "axios"
import { mapGetters } from "vuex"
export default {
  
    name: 'house-comments-details',
    props: ['commentsHouse', 'showDeleteComment'],
    data: () => ({
      //showDeleteComment: false,
      deleteItem: {},
      dialog_delete: false,
      dialogPictures: false,
      dialogPicturesSrc: '',
      icons: {
        "Доступ": { icon: "mdi-key-outline", color: "deep-orange"},
        "Управителі": {icon: "mdi-account-box-outline", color: "blue"},
        "Технічні данні": {icon: "mdi-tools", color: "primary"},
        "Інше": {icon: "mdi-text", color: "grey"},
        "PON": {icon: "mdi-connection", color: "light-green"},
      }
    }),
    computed: {
        ...mapGetters({
          job_comment_type_house: "job_types/comment_type_house"
        }),
    },
    methods:{
      openConfirmDialog(item){
        this.deleteItem = item 
        this.dialog_delete = true
      },
      closeConfirmDialog(){
        this.deleteItem = {}
        this.dialog_delete = false
      },
      deleteComment(){
        axios({
            headers: { "Content-type": "application/json; charset=UTF-8" },
            method: 'DELETE',
            url: `/api/service/house_comments/${this.deleteItem.id}/`,
        })
        .then(() => {
            this.$emit('update-comment');
            this.$router.app.$snack.show("Коментар видалено!");
            this.closeConfirmDialog()
        })
        .catch(err => {
            this.$router.app.$sheet.show("Помилка!", err)
        })
      },
    }
}
</script>

