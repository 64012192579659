var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('keep-alive',[_c('Filters',{ref:"filtersConnect",attrs:{"for":"connects_abonent"},on:{"apply-filters":function($event){return _vm.getUserConnect()}}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 row-cursor",class:_vm.$vuetify.breakpoint.smAndDown ? _vm.$vuetify.theme.dark && 'td_background_dark' || 'td_background':'',attrs:{"headers":_vm.headers,"loading-text":"Завантаження...","no-data-text":"Нема данних для відображення","server-items-length":_vm.paginations.count,"items":_vm.connects,"options":_vm.options,"footer-props":_vm.footer,"loading":_vm.loading,"focusable":"","disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"secondary lighten-1","dark":"","dense":""}},[_c('v-toolbar-title',{},[_c('h3',{staticClass:"mr-6 accent--text"},[_vm._v("Підключення")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.getUserConnect()}}},[_c('v-icon',[_vm._v("autorenew")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$refs.filtersConnect.show()}}},[_c('v-badge',{attrs:{"value":_vm.countFilters > 0,"color":"accent","text":"primary","content":_vm.countFilters,"dark":""}},[_c('v-icon',[_vm._v("mdi-filter")])],1)],1)],1)]},proxy:true},(_vm.countFilters > 0)?{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":headers.length}},[_vm._v(" Фільтри: "),_c('keep-alive',[_c('FiltersChips',{attrs:{"filtersComponents":_vm.filtersComponents,"is_close":true},on:{"clear-filter":_vm.clearFilter,"open-filters":function($event){return _vm.$refs.filtersConnect.show()}}})],1)],1)])])]}}:null,{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.connects.indexOf(item) + _vm.paginations.start_index))])]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_c('span',{attrs:{"title":item.reason && 'Причина розриву сесії: \n\n' + _vm.codeReason[item.reason]}},[_c('v-badge',{attrs:{"color":"primary lighten-3","inline":"","content":item.reason}})],1)]}},{key:"item.traffic",fn:function(ref){
var item = ref.item;
return [(item.traffic)?_c('v-chip',{attrs:{"small":"","outlined":"","color":_vm.convertBytes(item.traffic)[1]}},[_vm._v(_vm._s(_vm.convertBytes(item.traffic)[0]))]):_vm._e()]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" із "+_vm._s(itemsLength)+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }