<template>
  <div>
    <v-row>
      <v-col class="pt-0">
        <v-list three-line class="px-0">
      <!--<v-row justify="space-between" class="ml-3 mb-1" :class="$vuetify.breakpoint.smAndDown ? 'mt-1' : ''"> <v-subheader><h3 >Коментарі до заявки:</h3></v-subheader> <v-subheader><v-btn fab small color="primary"><v-icon @click="$emit('open-modal')">mdi-plus</v-icon></v-btn></v-subheader></v-row>-->
          <template v-for="(item, index) in order.order_comment">

            <v-list-item
              :key="index + 'order_comment'"
              class="px-0"
            >
            
              
              <v-list-item-avatar>
                <img v-if="item.user_avatar" :src="$router.app.devUrl + '/media/' + item.user_avatar" alt="avatar">
                <v-icon
                v-else
                class="grey lighten-1"
                dark
              >
                mdi-account
              </v-icon>
              </v-list-item-avatar>
              

              <v-list-item-content style="position:relative;">
                <v-list-item-subtitle ><span class="grey--text">{{ item.date }}</span> </v-list-item-subtitle>

                <v-list-item-title ><v-icon style="vertical-align: text-bottom;" left :color="colors.find(x => x.id == item.tag).color">mdi-label</v-icon>{{ item.comment_subj }} </v-list-item-title>
                <span>
                  <div class="d-flex justify-center my-2" v-if="item.pictures">
                  <v-img
                    lazy-src=""
                    
                    max-width="250"
                    :src="item.pictures"
                    @click="dialogPicturesSrc = item.pictures, dialogPictures = true"
                  ></v-img>
                </div>
                  <span style="white-space: break-spaces;" class="" >{{ item.comment }}</span>
                  
                </span> 
                  <div class="d-flex justify-space-between">
                    <small class="primary--text "> ({{ item.user }})</small>
                  <v-btn
                    :absolute="$vuetify.breakpoint.mdAndUp"
                    top
                    right
                    small
                    icon
                    title="Поділитись"
                    target="_blank" 
                    :href="`https://t.me/share/url?url=${encodeURIComponent(`Пересланий коментар: `)}&text=${encodeURIComponent(`\n${'**' + order.address + '**'}\n${item.comment}${!order.user_out && '\ncrmtv.altair.kr.ua/ab/' + order.user.id || ''}`)}`" 
                  >
                    <v-icon 
                    >
                      mdi-share-variant
                    </v-icon>
                </v-btn>  
                </div>
                
              </v-list-item-content>
              
            </v-list-item>
            <v-divider
              v-if="item.lenght != 0"
              :key="index"
              :inset="true"
            ></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogPictures"
      width="500"
    >
      <div class="d-flex justify-center" v-if="dialogPicturesSrc">
        <v-img
          lazy-src=""
          :src="dialogPicturesSrc"
          @click="dialogPictures = false"
        >
      </v-img>
      </div>
    </v-dialog>
  </div>
</template>
<script>

export default {
    name: 'job-table-simple-details',
    props: ["order"],
    data: () => ({
      dialogPictures: false,
      dialogPicturesSrc: '',
      colors: [
                {color: 'deep-orange', name_color: 'Терміново', id: 1},
                {color: 'primary', name_color: 'Primary', id: 2},
                {color: 'orange', name_color: 'Orange 2', id: 3},
                {color: 'green', name_color: 'Green', id: 4},
                {color: 'pink', name_color: 'Pink', id: 5},
                {color: 'grey darken-4', name_color: 'Black', id: 6},
                {color: 'grey ', name_color: '', id: 0},
            ],
    })
}
</script>

