<template>
  
  <v-col cols=12 md=3 sm=6 :class="toolbar_dense ? '' : 'pt-0'">
    <v-card
    class="mx-auto"
  >
    <v-toolbar
      flat 
      color="secondary lighten-1"
      dark
      :dense="toolbar_dense"
    >
      <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''"><h3 class="mr-6 accent--text">Всього</h3></v-toolbar-title>
      <v-spacer />  
        
        <!--<v-btn 
          icon 
          v-on="on" 
          title="Поновити данні"
          :loading="loading"
          @click="getTotalPays()">
            <v-icon>autorenew</v-icon>
          </v-btn>-->
          <v-btn 
          v-if="for_abonent"
            icon 
            color="accent"
            title="Акт звірки платежів"
            @click="$emit('view-act')"
          >
            <v-icon>mdi-poll</v-icon>
          </v-btn>
    </v-toolbar>

    <v-list subheader dense >

      <v-list-item
        v-for="(k) in pays_type"
        :key="k.id"
        
      >
        <v-list-item-content>
          <v-list-item-title v-text="k.name +':'"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title class="text-right">{{ total[k.id] | formatSum }}</v-list-item-title>
        </v-list-item-content>   
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-overlay :value="loading" absolute >
      <v-progress-circular
        indeterminate
        size="48"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
  </v-col>
  

  
</template>
<script>
import axios from "axios"
import { mapGetters } from "vuex"
export default {
    props: ["toolbar_dense", "for_abonent", "abonent_pcode"],
    name: 'pays-total',
    data: () => ({
      loading: false,
      total: {},
      filters: []
    }),
    computed: {
      ...mapGetters({
      pays_type: "filters/pays_type",
      }),
    },
    watch: {
      //
    },
    methods: {
      getFiltersOfComponents(){
        return this.filters.filter(x => x.value && x.value != '') 
      },
      filterFormat() {
        const temp = {};
        /* получение значений фильтрации и просчет колличества фильтров */
        this.filtersComponents = this.getFiltersOfComponents()
        this.filtersComponents.forEach(filter => temp[filter.name] = filter.value + '');
        return temp; 
      },
      getTotalPays(filters = false, inetPays){
        if(inetPays){
          this.getTotalPaysBilling(filters)
          return
        }
        let url = this.for_abonent && `/api/cabletv/get_user/${this.$route.params.id}/pays_total/` || `/api/cabletv/pays_total/`
        
        //if(this.for_abonent) return
        
        if(filters) this.filters = filters
        this.loading = true;
        axios.get(url, {params: this.filterFormat()})
        .then( response =>{
          //console.log(response.data)
          this.total = response.data.total
        })
        .catch(() => {
          //console.log(error);
          //this.errored = true;
        })
        .finally(() => (this.loading = false));
      },
      getTotalPaysBilling(filters = false){
        this.loading = true;
        //console.log(this.abonent_pcode)
        if(filters) this.filters = filters
        axios({
              method: "get",
              url: this.$router.app.devUrlBillingAPI,
              params: {req_cmd: this.for_abonent && "getUserPaymentsTotal" || "getPaymentsTotal", pcode: this.abonent_pcode, ...this.filterFormat() },
              
          })
          .then( response =>{
            if(response.data.resp_status == "OK"){
              this.total = response.data.resp_result.total
            }else{
              this.total = {}
            }
          })
          .catch(() => {})
          .finally(() => (this.loading = false)) 
      }
    }
}
</script>
