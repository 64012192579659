<template>
    <v-list two-line class="pa-0">
     
        <template v-for="(item, index) in historyJob">
          <v-list-item :key="index" class="pa-0">
            <template v-slot:default="{  }">
              <v-list-item-content>
                <v-list-item-title><div class="d-flex justify-space-between"> {{item.user}} <small class="grey--text text--darken-1">{{ item.date }}</small></div> </v-list-item-title>

                <span
                ><span>Дія: </span><span class="deep-orange--text text--darken-1">{{ item.action }}</span></span>

                <!--<v-list-item-subtitle v-text="item.action_note"></v-list-item-subtitle>-->
                <span><span>Попереднє значення: </span><span class="grey--text text--darken-1">{{ item.action_note }}</span></span>
              </v-list-item-content>

              
            </template>
          </v-list-item>

          <v-divider
            v-if="index < historyJob.length - 1"
            :key="index + 'divider'"
          ></v-divider>
        </template>
    </v-list>
</template>
<script>

export default{
    props:["historyJob"]
}
</script>
