<template>
        
        <v-dialog v-model="dialog" persistent :max-width="jobData.type == 'create' ? 800:500">
            
            <v-card>
                <v-card-title class="">
                    <span class="headline ">{{ jobData.title }} </span>
                </v-card-title>

                <div v-if="jobData.type == 'change'" class="text-center mb-3">
                    <v-chip label outlined color="primary lighten-1">
                        <v-icon left>
                            mdi-map-marker-outline
                        </v-icon>
                        {{form_data.address }}
                    </v-chip>         
                </div>

                <v-tabs :class="jobData.type == 'change' ? 'mb-3' : 'd-none'"
                fixed-tabs
                v-model="tab"
                >
                    <v-tab key="tab1" >
                        <span class="font-weight-bold">Заявка</span>
                    </v-tab>
                    <v-tab key="tab2">
                        <v-badge
                            color="primary lighten-1"
                            :value="tab == 1 && historyJob.length > 0"
                            :content="historyJob.length"
                        >
                            <span class="font-weight-bold">Історія</span>
                        </v-badge>
                        
                    </v-tab>
                    <v-tab v-if="jobData.type == 'change' && form_data.order_status == 1 || form_data.order_status == 4" key="tab3">
                        <span class="font-weight-bold">Завершити</span>
                    </v-tab>
                </v-tabs> 

             

            <v-card-text>
             
                <v-tabs-items v-model="tab" class="pt-2">
                    <v-tab-item key="tab1" transition="fade">   
                        <v-form 
                        ref="form"
                        v-model="valid"
                        lazy-validation> 
                            <v-row> 
                                <v-col cols="12" :md="jobData.type == 'create' ? 6:12" class="px-0">
                                    
                                        <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                                    <v-select v-if="field.type == 'select'"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :items="field.values"
                                    item-text="name"
                                    item-value="id"
                                    :disabled="field.disabled || changeForm && field.disableChange"
                                    :clearable="field.clearable"
                                    :multiple="field.multiple"
                                    >
                                        <!-- <template v-if="field.name == 'user_worker' || field.name == 'user_recive'" v-slot:item="{ item }">
                                            {{ item.first_name }} {{ item.last_name }}
                                        </template>
                                        <template v-if="field.name == 'user_worker' || field.name == 'user_recive'" v-slot:selection="{ item }">
                                            {{ item.first_name }} {{ item.last_name }}
                                            
                                        </template>-->

                                    </v-select>

                                    <v-textarea v-else-if="field.type == 'textarea'"
                                        dense
                                        rows="3"
                                        outlined
                                        hide-details="auto"
                                        :label="field.title"
                                        v-model="form_data[field.name]"
                                        :name="field.name"
                                        :rules="field.rules && textRules || notRules"
                                        :type="field.type"
                                        :disabled="field.disabled"
                                    ></v-textarea>
                                    <template v-else-if="field.type == 'checkbox'">
                                        <v-row justify="center" class="mb-1" :class="changeForm && field.disableChange ? 'd-none' : ''">
                                            <v-checkbox 
                                            v-model="form_data[field.name]"
                                            :disabled="field.disabled || changeForm && field.disableChange"
                                            :label="field.title"
                                            hide-details="auto"
                                            class="mt-1 mb-2"
                                            
                                            ></v-checkbox>
                                        </v-row>
                                    </template>
                                    
                                    <v-text-field v-else
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :type="field.type"
                                    :disabled="field.disabled"
                                    >
                                    </v-text-field>
                                        </v-col>
                                    
                                </v-col>
                                <v-col v-if="jobData.type == 'create'" cols="12" :md="jobData.type == 'create' ? 6:12" class="px-0">
                                    
                                 <!-- ------------  For create job not abonent -------------------- -->  
                                        
                                        <v-col  key="address-operator" cols="12" class="py-2" v-if="user.operator_id == 1">
                                            <v-select
                                            dense
                                            outlined
                                            hide-details="auto"
                                            label="Оператор"
                                            v-model="form_data.user"
                                            :rules="textRules || notRules"
                                            :items="operators_groups"
                                            name="address-operator"
                                            item-text="name"
                                            item-value="id"
                                            >
                                            </v-select>    
                                        </v-col>
                                        <v-col  key="address-street-search" cols="12" class="py-2" >
                                            <SearchStreet ref="searchStreet"  v-on:search-new-id="setSearchValue" :denseForm="true" :outlinedForm="true" :clearSearchStreet="clearSearchStreet"/>
                                        </v-col>  
                                        <v-col  key="address-house" cols="12" class="py-2" >
                                            <v-text-field 
                                            dense
                                            outlined
                                            hide-details
                                            label="Будинок"
                                            v-model="form_data.house"
                                            name="address-house"
                                            type="number"
                                            :rules="textRules || notRules"
                                            >
                                            </v-text-field>
                                        </v-col> 
                                        <v-col  key="address-letter" cols="12" class="py-2" >
                                            <v-select 
                                            dense
                                            outlined
                                            hide-details
                                            label="Літера"
                                            clearable
                                            name="address-letter"
                                            v-model="form_data.letter"
                                            :items="['а','б','в','г','д','е','ж','з','и','к','л','м','н','о','п']"
                                            >
                                            </v-select>   
                                        </v-col> 
                                        <v-col  key="address-flat" cols="12" class="py-2">
                                            <v-text-field 
                                            dense
                                            outlined
                                            hide-details
                                            label="Квартира"
                                            v-model="form_data.flat"
                                            type="number"
                                            >
                                            </v-text-field>
                                        </v-col> 
                                        
                                    
                                </v-col>
                            </v-row>
                                
                                <!------------------------------------------------------->
                            
                        </v-form>
                    </v-tab-item>
                    <v-tab-item key="tab2" transition="fade">
                       <JobHistoryList :historyJob="historyJob" />
                    </v-tab-item>
                    <v-tab-item key="tab3" transition="fade">
                       <v-form 
                        ref="form_close"
                        v-model="valid"
                        lazy-validation>
                            <v-row>   
                                
                                <v-col v-for="field in fields_close" :key="field.name" cols="12" class="py-2 mb-2">
                                
                                    <v-textarea 
                                        dense
                                        rows="4"
                                        outlined
                                        hide-details="auto"
                                        :label="field.title"
                                        v-model="form_data_close[field.name]"
                                        :name="field.name"
                                        :rules="field.rules && textRules || notRules"
                                        :type="field.type"
                                        :disabled="field.disabled"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <template v-if="tab == 2">
                    
                    <v-btn v-if="form_data.order_status == 1" color="primary lighten-1" :loading="loadingCancel" @click="sendCompliteJob()">Завершити</v-btn>
                    <v-spacer />
                    <v-btn color="orange lighten-1" dark :loading="loadingCancel" @click="sendCancelJob()">Зняти</v-btn>
                    </template>
                
                <template v-else> 
                    <v-btn v-if="jobData.method == 'POST'" :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Створити</v-btn>
                    <v-btn v-else-if="tab != 1 && (form_data.order_status == 1 || form_data.order_status == 4)" :loading="loadingSubmit" color="primary " :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Змінити</v-btn>
                </template>
                
            </v-card-actions> 
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    import { mapGetters } from "vuex"
    import JobHistoryList from '@/components/Job/JobHistoryList.vue'

	export default {
        name: 'job-modal-add',
		data: () => ({
            tab: null,
            monters: [],
            loadingSubmit:false,
            loadingCancel:false,
            changeForm: false,
            clearSearchStreet: 0,
            item: {},
            abonent: null,
            historyJob: [],
            jobData: {},
            defaultJobData: {
                add: {title: "Створення заявки", method: "POST", readonly: false, message: "Заявку створено!", type: 'add', url: '/api/service/create_service'},
                change: {title: "Редагування заявки", method: "PUT", readonly: true, message: "Заявку змінено!", type: 'change', url: '/api/service/edit_service/'},
                create: {title: "Створення заявки", method: "POST", readonly: true, message: "Заявку створено!", type: 'create', url: '/api/service/create_service'},
            },
            dialog: false,
            valid: true,
            form_data_close: {},
            fields_close: [
                
                {title: "Текст звіту / Причина зняття",    name: "order_done_text",     rules: true,  type: "textarea", disabled: false, value: "" },
            ],
            form_data: {},
            fields: [
                {title: "Дата призначення",    name: "date_due",       rules: true,  type: "date",     disabled: false, value: "" },
                {title: "Послуга", name: "order_service",  rules: true,  type: "select",   disabled: false, disableChange: true, value: "", values: []},
                {title: "Тип",     name: "order_type",     rules: true,  type: "select",   disabled: false, disableChange: true, value: "", values: []},
                //{title: "Виконавець",  name: "user_worker",    rules: false, type: "select",   disabled: false, clearable: true, value: "", values: [] },
                {title: "Виконавці",  name: "order_worker",    rules: false, type: "select",   disabled: false, clearable: true, value: "", multiple: true, values: [] },
                //{title: "Статус",  name: "order_status",   rules: true,  type: "select",   disabled: false, value: "", values: []},
                {title: "Опис",    name: "order_text",     rules: true,  type: "textarea", disabled: false, value: "" },
                //{title: "Причина скасування", name: "order_done_text",rules: true,  type: "textarea", disabled: false, value: "" },
                {title: "Потребує перевірки",  name: "need_check",   rules: false,  type: "checkbox",   disabled: false, disableChange: true, value: false},


            ],
            
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        components:{
            JobHistoryList
        },
        computed: {
            ...mapGetters({
                job_type: "job_types/type",
                job_service: "job_types/service",
                job_status:"job_types/status",
                users: "users/users",
                operators: "filters/operators",
                operators_groups: "filters/operators_groups",
                user: "auth/user"
            }),
        },
        watch:{
            tab(){
                this.tab == 1 && this.dialog == true && this.getHistoryJob()
            },
            'form_data.order_service'(val){
                if(this.jobData.type != 'change'){
                    console.log(val)
                    this.form_data.order_type = null
                    let jobTypesForService = []
                    if(val == 1) jobTypesForService = this.job_type.filter(x => x.parametr == 3)
                    if(val == 2) jobTypesForService = this.job_type.filter(x => x.parametr == 2)
                    this.fields.find(x => x.name == "order_type").values = jobTypesForService.concat(this.job_type.filter(x => x.parametr == 1))
                }
                
            }
        },
        mounted(){
            this.jobData = JSON.parse(JSON.stringify(this.defaultJobData.add));
            this.createJobTypes()

        },
        methods: {
            show(type, item){
                
                //console.log(order);
                
                this.form_data.user_out = false
                this.form_data.user = null
                this.form_data.date_due = ''
                delete this.form_data.flat
                //console.log(this.form_data)

                this.abonent = null
                this.clearModal()
                this.tab = 0
                if(type == "change"){
                    this.jobData = JSON.parse(JSON.stringify(this.defaultJobData.change));
                    this.changeForm = true;
                    this.form_data = JSON.parse(JSON.stringify(item))
                    //вставляем данные в селект поля
                    this.form_data.order_service = this.job_service.find(x => x.name == item.order_service).id
                    this.form_data.order_type = this.job_type.find(x => x.name == item.order_type).id
                    this.form_data.order_status = this.job_status.find(x => x.name == item.order_status).id
                    //this.form_data.user_worker = item.user_worker?.id
                    this.form_data.order_worker = item.order_worker?.map(x => x.worker)
                    //console.log(item.order_worker?.map(x => x.worker))
                    if(item.order_status == 'Знята' || item.order_status == 'Виконана'){
                        this.tab = 1
                    }
                    
                    //

                }else if(type == "add"){
                    this.form_data.user = item.id
                    this.form_data.need_check = false
                    this.abonent = item //Если создание то получаем абонента в item
                    this.form_data.date_due = new Date().toDateInputValue()
                    //this.form_data.operator = item.operator
                }else if(type == "create"){
                    this.jobData = JSON.parse(JSON.stringify(this.defaultJobData.create));
                    this.form_data.date_due = new Date().toDateInputValue()
                    this.form_data.need_check = false
                    this.form_data.user = this.user.operator_id
                    this.form_data.user_out = true
                    
                    this.form_data.letter = ''
                }
                this.dialog = true;
                //console.log(this.item)
                
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.tab = 0
                this.form_data = {}
                this.clearSearchStreet += 1
                this.$refs.form_close && this.$refs.form.reset();
                this.$refs.form_close && this.$refs.form_close.reset();
                this.$refs.form.reset()
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                if(this.$route.name == 'abonent_info' || this.$route.name == 'abonent_info_single' ) {
                    this.$emit('update-abonent')
                }
                
                //console.log(this.tab)
                
            },
            clearModal(){
                this.changeForm = false
                this.jobData = JSON.parse(JSON.stringify(this.defaultJobData.add))
            },
            createJobTypes(){
                //this.fields.find(x => x.name == "order_status").values = this.job_status
                if(this.jobData.type == 'change'){
                    this.fields.find(x => x.name == "order_type").values = this.job_type
                }else{
                    this.fields.find(x => x.name == "order_type").values = this.job_type.filter(x => x.parametr == 1)
                }
                
                this.fields.find(x => x.name == "order_service").values = this.job_service
                //this.fields.find(x => x.name == "user_worker").values = this.users && this.users.filter(x => x.user_type == 2 )
                this.fields.find(x => x.name == "order_worker").values = this.users && this.users.filter(x => x.user_type == 2 )
            },
            changeTabs(tab){
                this.tab = tab
            },
            setSearchValue(val){
                //console.log("This is a setSearchValue", val, name)
                this.form_data.street = val
                //this.filters.find(x => x.name == "street").value = val
                //this.filters.find(x => x.name == "street").street_name = name

            },
            getHistoryJob() {
                this.loadingCancel = true
                axios({
                    headers: { "Content-type": "application/json; charset=UTF-8" },
                    method: "",
                    url: `/api/service/get_service_history/${this.form_data.id}/`,
                })
                .then(response => {
                    this.historyJob = response.data
                })
                .catch(err => {
                    this.$router.app.$sheet.show("Помилка!", err);
                })
                .finally(() => (this.loadingCancel = false))
                
            },
            getFieldsOfFormData(){
                // убираем пустые поля из объекта form_data
                let temp = {}
                for (let element in this.form_data) {
                    if (this.form_data[element] || this.form_data[element] === false) temp[element] = this.form_data[element]
                }
                return temp
            },
            sendForm() {
                //console.log(this.form_data)
                if(this.jobData.type == "create" && !this.form_data.street){
                    this.$router.app.$sheet.show("Помилка!", "Введіть вулицю");
                    return 0
                }
                if(this.$refs.form.validate()){

                    /* Поиск оператора относительно типа услуги */
                    
                    /*----------------------------------------- */
                    
                    this.loadingSubmit = true;
                    let now_url = `${this.jobData.url}${this.jobData.type == 'change' && this.form_data.id || ''}/`
                    //this.form_data.operator = this.operators.find(x => x.name == this.user.operator).id 
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: this.jobData.method,
                        url: now_url,
                        data: this.getFieldsOfFormData(), 
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-job')
                        this.$router.app.$snack.show(this.jobData.message)
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                }
            },
            sendCancelJob() {
                if(this.$refs.form_close.validate()){
                    this.loadingCancel = true;
                    //console.log(this.form_data_close.order_done_text)
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "PUT",
                        url: `/api/service/cancel_service/${this.form_data.id}/`,
                        data: {"order_done_text": this.form_data_close.order_done_text},
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-job');
                        this.$router.app.$snack.show("Заявку знято!");
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingCancel = false))
                }
            },
            sendCompliteJob() {
                if(this.$refs.form_close.validate()){
                    this.loadingCancel = true;
                    //console.log(this.form_data_close.order_done_text)
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "PUT",
                        url: `/api/service/done_cc_service/${this.form_data.id}/`,
                        data: {"order_done_text": this.form_data_close.order_done_text},
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-job');
                        this.$router.app.$snack.show("Заявку завершено!");
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingCancel = false))
                }
            }

        }
    }
</script>