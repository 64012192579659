<template>
        
        <v-dialog v-model="dialog" persistent max-width="600">
            
            <v-card :loading="loading">
            <v-card-title class="">
            <span class="headline ">Коментарі</span>
            </v-card-title>
            <div class="text-center mb-3">
                <v-chip v-if="tab == 0" label outlined color="primary lighten-1">
                    <v-icon left>
                        mdi-map-marker-outline
                    </v-icon>
                    {{item && item.address }}
                </v-chip>         
                <v-chip v-else label outlined color="primary lighten-1">
                    <v-icon left>
                        mdi-home-outline
                    </v-icon>
                    {{ item && item.street_name }}, {{ item && item.house }}{{ item && item.letter }}
                </v-chip>  
            </div>
            <v-tabs
            fixed-tabs
            v-model="tab"
            class="mb-3"
            >
                <v-tab key="tab1" @click="getCurrentOrderComment()">
                    <v-badge
                        color="primary lighten-1"
                        :value="tab == 0 && item && item.order_comment.length > 0"
                        :content="item && item.order_comment.length"
                    >
                        <span class="font-weight-bold">До заявки</span>
                    </v-badge>
                    
                </v-tab>
                <v-tab key="tab2" @click="getCurrentHouseComment()">
                    <v-badge
                        color="primary lighten-1"
                        :value="tab == 1 && house_comments.length > 0"
                        :content="house_comments.length"
                    >
                    <span class="font-weight-bold">До будинку</span>
                    </v-badge>
                    
                </v-tab>
            </v-tabs>
           
            <v-card-text>
             
            
                <v-tabs-items v-model="tab" class="pt-2 pb-2">
                    <v-tab-item key="tab1" transition="fade">
                        
                        <JobTableSimpleDetails :order="item" />
                        <div class="text-center"><v-btn @click="$parent.$refs.addJobCommentSet.show(item)" color="primary lighten-1" icon><v-icon>mdi-plus</v-icon></v-btn></div>
                    </v-tab-item>
                    <v-tab-item key="tab2" transition="fade">
                        <HouseCommentsDetails v-on:update-comment="getCurrentHouseComment()" :commentsHouse="house_comments" />
                        <div class="text-center"><v-btn @click="$parent.$refs.addCommentHouseSet.show(item)" color="primary lighten-1" icon><v-icon>mdi-plus</v-icon></v-btn></div>
                    </v-tab-item>
                </v-tabs-items>
            
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                
                
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import JobTableSimpleDetails from '@/components/Job/JobTableSimpleDetails.vue'
    import HouseCommentsDetails from '@/components/Job/HouseCommentsDetails.vue'
    import axios from "axios"
    import { mapGetters } from "vuex"
	export default {
        name: 'job-modal-add-comment',
        props: ["updateTable"],
		data: () => ({
            tab: 0,
            loading:false,
            dialog: false,
            valid: true,
            item: null,
            house_comments: []
            
        }),
        components:{
            JobTableSimpleDetails,
            HouseCommentsDetails
        },
        computed: {
            ...mapGetters({
                user: "auth/user",
            }),
        },
        watch:{
            updateTable(){
                if(this.updateTable && this.dialog && this.tab == 0 ) this.getCurrentOrderComment()
                if(this.updateTable && this.dialog && this.tab == 1 ) this.getCurrentHouseComment()
            },
            tab(){
                //if(this.tab == 0 && this.dialog) this.getCurrentOrderComment()
                //if(this.tab == 1 && this.dialog) this.getCurrentHouseComment()
            }
        },
        methods: {
            show(item){
                this.tab = 0
                this.house_comments = []
                this.item = item
                this.getCurrentOrderComment()
                this.dialog = true;
            }, 
            closeDialog (){
                this.dialog = false
            },
            getCurrentOrderComment(){
                axios.get(`/api/service/order_comments/${this.item.id}/`,)
                .then( response =>{
                    //console.log(response.data) 
                    this.item.order_comment = response.data
                })
                .catch(() => {
                    //
                })
                .finally(() => (this.loading = false));
            },
            getCurrentHouseComment(){
                axios.get(`/api/service/house_comments/?street=${this.item.street}&house=${this.item.house}&letter=${this.item.letter}`)
                .then( response =>{
                    //console.log(response.data) 
                    this.house_comments = response.data.results
                })
                .catch(() => {
                    //
                })
                .finally(() => (this.loading = false));
            }
        }
    }
</script>