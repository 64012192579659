<template>
        
        <v-dialog v-model="dialog" persistent max-width="500">
            
            <v-card>
            <v-card-title class="">
            <span class="headline">{{ abonentData.title }} </span>
            </v-card-title>
            
            <v-card-text>
                
            <v-container class="">
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                        <v-spacer />
                        <v-col cols="12" md=10>
                            <div class="my-4 text-center">
                                <h4>{{ abonentData.header }}</h4>
                            </div>
                            <v-col v-for="field in fieldsService" :key="field.name" cols="12" class="py-2">
                                <v-select v-if="field.type == 'select'"
                                dense
                                outlined
                                hide-details="auto"
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :name="field.name"
                                :rules="field.rules && textRules || notRules"
                                :items="field.values"
                                item-text="name"
                                item-value="id"
                                :disabled="field.disabled"
                                >
                                </v-select>

                                <v-switch v-else-if="field.type == 'switch'"
                                dense
                                hide-details
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :disabled="field.disabled"
                                ></v-switch>
                                
                                <v-text-field v-else
                                dense
                                outlined
                                hide-details="auto"
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :name="field.name"
                                :rules="field.rules && textRules || notRules"
                                :type="field.type"
                                :disabled="field.disabled"
                                >
                                </v-text-field>
                            
                            </v-col>
                            <v-col cols="12" class="py-2">
                                <keep-alive>
                                    <AbonentTarifSumm ref="AbonentTarifSumm" :abonent_id="abonent && abonent.id" :form_data="form_data"/>
                                </keep-alive>
                            </v-col>
                        </v-col>
                        <v-spacer />
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn :loading="loadingSubmit" :disabled="loadingSubmit"
                color="primary lighten-1" @click="sendForm()">Додати</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    import AbonentTarifSumm from '@/components/AbonentInfo/AbonentTarifSumm.vue'
    import { mapGetters } from "vuex"
	export default {
        components:{
            AbonentTarifSumm
        },
        name: 'create-service-modal',
        props: ["abonent"],
		data: () => ({
            loadingSubmit:false,
            item: {},
            abonentData: {},
            countChangeAbonentData: 0,
            defaultAbonentData: {
                tv: {title: "Додати Послугу",   method: "POST", readonly: false, message: "Послугу додано!", url: "", header: "ТЕЛЕБАЧЕННЯ"},
                inet: {title: "Додати Послугу", method: "POST", readonly: true,  message: "Послугу додано!", url: "", header: "ІНТЕРНЕТ"}
            },
            fieldsService: [],
            dialog: false,
            valid: true,
            form_data: {},
            form_data_tv:{
                date_tarif_change: "",
                tarif: "Новий",
                status: false,
                novakom: false,
                service: 1,
                contract: ''
            },
            form_data_internet:{
                date: "",
                contract: "",
                login: "",
                passwd: "",
                user_id: "",
            },
            fieldsTv: [
                {title: "№ Договору", name: "contract",      rules: false,  type: "number",     disabled: false, value: "" },
                {title: "Дата подключения", name: "date_tarif_change",      rules: true,  type: "date",     disabled: false, value: "" },
                {title: "Тариф",         name: "tarif",     rules: true,  type: "select",   disabled: false, value: "", values: []},
                //{title: "Сумма",         name: "balance",   rules: false,  type: "number",   disabled: true, value: "", },
                //{title: "Статус",        name: "status",    rules: false,  type: "switch",   disabled: false,  value: "" },
                //{title: "Квартиранти",   name: "owner",     rules: false,  type: "switch",   disabled: false, value: "" },
                //{title: "НоваКом",       name: "novakom",   rules: false,  type: "switch",   disabled: false, value: "" },
                //{title: "Борг",       name: "balance_novakom",   rules: false,  type: "switch",   disabled: false, value: "" },
            ],
            fieldsInternet: [
                {title: "Дата Договору",     name: "date",     rules: false,  type: "date",     value: ""},
                {title: "Тариф",             name: "tarif",    rules: false,  type: "select",   value: "", disabled: false, values: []},
                {title: "№ Договору",        name: "contract", rules: false,  type: "text",     value: ""},
                {title: "Логін",             name: "login",    rules: false,  type: "text",     value: ""},
                {title: "Пароль",            name: "passwd",   rules: false,  type: "text",     value: ""},
                {title: "Особистий рахунок", name: "user_id",  rules: false,  type: "text",     value: ""},
            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            ...mapGetters({
                rates: "filters/rates" 
            }),
        },
        
        mounted(){
            //this.fields.find(x => x.name == 'street').values = this.streets 
            this.fieldsTv.find(x => x.name == "tarif").values = this.rates
            this.fieldsInternet.find(x => x.name == "tarif").values = this.rates
            this.abonentData = []

        },
        watch:{
            form_data:{
                handler () {
                    //console.log("form_data GHANGE!")
                    this.countChangeAbonentData += 1
                    //console.log(this.$router)
                    //this.countChangeAbonentData > 1 && this.$refs.AbonentTarifSumm.getSumm()
                },
                deep: true,
            },
            
        },
        methods: {
            show(type){
                //console.log(order);
                this.clearModal()
                
                if(type == "tv"){
                    this.abonentData = JSON.parse(JSON.stringify(this.defaultAbonentData.tv));
                    this.fieldsService = JSON.parse(JSON.stringify(this.fieldsTv));
                    this.form_data = JSON.parse(JSON.stringify(this.form_data_tv));
                    
                }else if(type == "inet"){
                    this.abonentData = JSON.parse(JSON.stringify(this.defaultAbonentData.inet));
                    this.fieldsService = JSON.parse(JSON.stringify(this.fieldsInet));
                    this.form_data = JSON.parse(JSON.stringify(this.form_data_inet));
                }
                this.form_data.date_tarif_change = new Date().toDateInputValue()
                this.countChangeAbonentData = 0
                this.dialog = true;
                
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog(){
                this.dialog = false
                this.reset() 
                
            },
            clearModal(){
                this.abonentData = []
            },
            sendForm() {

                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;

                    this.form_data.user = this.abonent.id
                    
                    
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "POST",
                        url: `/api/cabletv/create_user_service/`,
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.changeTarif()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                } 
            },
            changeTarif() {
                    this.loadingSubmit = true;
                    axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: "POST",
                        url: `/api/service/change_tarif/${this.abonent.id}/`,
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-abonent');
                        this.$router.app.$snack.show("Заявку на підключення стровено!");
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        this.closeDialog()
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
            }

        }
    }
</script>