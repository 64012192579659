<template>
        
        <v-dialog v-model="dialog" persistent max-width="400">
            
            <v-card :loading="loadingSubmit">
            <v-card-title class="">
            <span class="headline ">Кредит</span>
            </v-card-title>
            
            <v-card-text>
                
            <v-container class="mt-6">
                <v-form 
                ref="form"
                v-model="valid"
                @submit.prevent="type == 'inet' && sendForm() || sendFormTv()"
                lazy-validation>
                    <v-row>     
                        <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                            
                            <v-text-field v-if="field.name == 'amount'"
                            dense
                            outlined
                            hide-details="auto"
                            :label="field.title"
                            v-model="form_data[field.name]"
                            :name="field.name"
                            :rules="field.rules && textRules || notRules"
                            :type="field.type"
                            :disabled="loadingSubmit"
                            :pattern="field.type == 'text' && '^([A-F0-9]{2}\-){5}[A-F0-9]{2}$'"
                            required
                            >
                            </v-text-field>
                            <v-text-field v-if="field.name == 'date_due'"
                            dense
                            outlined
                            hide-details="auto"
                            :label="field.title"
                            v-model="form_data[field.name]"
                            :name="field.name"
                            :rules="field.type == 'date' && dateRules || notRules"
                            :type="field.type"
                            :disabled="loadingSubmit"
                            :pattern="field.type == 'text' && '^([A-F0-9]{2}\-){5}[A-F0-9]{2}$'"
                            required
                            >
                            </v-text-field>
                            <v-textarea v-else-if="field.type == 'textarea' && type == 'inet'"
                                dense
                                rows="3"
                                outlined
                                hide-details="auto"
                                :label="field.title"
                                v-model="form_data[field.name]"
                                :name="field.name"
                                :rules="field.rules && textRules || notRules"
                                :type="field.type"
                                :disabled="field.disabled"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
                <span v-if="type == 'tv'">* Якщо не вибрана дата зняття кредиту - кредит буде додано на постійній основі і він автоматично не зникне.</span>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn v-if="type == 'inet' && checkCredit"  color="error lighten-1" text @click="sendForm(false, true)">Зняти</v-btn>
                <v-btn v-if="type == 'tv' && checkCreditTv" color="error lighten-1" text @click="sendFormTv(true)">Зняти</v-btn>
                <v-spacer />
                <v-btn v-if="type == 'inet'" :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Змінити</v-btn>
                <v-btn v-if="type == 'tv'" :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendFormTv()">Змінити</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
	export default {
        name: 'add-credit-modal',
        props: ['pcode', "service_tv", "service_inet", "service_inet_billing"],
		data: () => ({
            type: null,
            loadingSubmit:false,
            item: {},
            dialog: false,
            valid: true,
            form_data: {
            },
            fields: [
                {title: "Сума кредиту", name: "amount",  rules: true,  type: "number", value: "" },
                {title: "Дата зняття кредиту", name: "date_due",  rules: true,  type: "date", value: "" },
                {title: "Примітки", name: "description",     rules: true,  type: "textarea", disabled: false, value: "" },
            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            dateRules: [
                v => !!v && new Date(new Date(v).toDateString()) - new Date(new Date().toDateString()) > 0 || !!v && 'Дата має бути у майбутньому часі' || true
            ]
            
        }),
        computed: {
            checkCredit(){
                return this.service_inet_billing?.balance_additional?.credit != 0
            },
            checkCreditTv(){
                return this.service_tv?.balance_additional?.credit != 0
            }
        },
        mounted(){
            //
        },
        methods: {
            show(type){
                this.type = type
                let amount = 0

                if(this.type == 'inet'){
                    amount = this.service_inet_billing?.balance_additional?.credit
                    this.form_data.description = this.service_inet_billing?.balance_additional?.credit_descr
                }else if(this.type == 'tv'){
                    amount = this.service_tv?.balance_additional?.credit
                }
                
                if(amount && Number(amount)) this.form_data.amount = Number(amount)
                this.dialog = true;
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                this.form_data = {}
                this.type = null
            },
            async sendForm(accepted = false, reset = false){ 
                if(reset){
                    let question = await this.$parent.$refs.confirmDialog.open("Зняти кредит?", "Ви впевнені, що хочете зняти кредит у абонента?")
                    if(question) {
                        this.form_data.amount = 0
                        this.form_data.description = "Знімаю кредит"
                    }
                    else return
                }
                if(this.$refs.form.validate() || accepted || reset){
                    this.loadingSubmit = true
                    axios({
                        method: "post",
                        url: this.$router.app.devUrlBillingAPI,
                        data: {req_cmd: "addCredit", pcode: this.pcode, ...this.form_data, accepted: accepted, reset: reset }
                    })
                        .then(response => {
                        if(response.data.resp_status == "OK"){
                            if(response.data.resp_yesno){
                                this.$parent.$refs.confirmDialog.open(this.form_data.amount == 0 && 'Зняти кредит?' || 'Додати кредит?', response.data.resp_yesno)
                                .then(result => {
                                    result && this.sendForm(true)
                                })
                                return
                            }else{
                                this.sendFormTvToInet() 
                            }
                            
                            console.log(response.data)
                        }else if(response.data.resp_status == "REJECT"){
                            this.$router.app.$snack.show(response.data.resp_errmsg, 'deep-orange')
                        }else{
                            this.$router.app.$snack.show("Помилка зміни кредиту", 'deep-orange')
                        }
                        })
                        .catch(err => {
                        //console.log(err);
                            this.$router.app.$sheet.show("Помилка! (billing)", err);
                        })
                        .finally(() => ( this.loadingSubmit = false ))
                }
            },
            async sendFormTv(reset = false){
                if(reset){
                    let question = await this.$parent.$refs.confirmDialog.open("Зняти кредит?", "Ви впевнені, що хочете зняти кредит у абонента?")
                    if(question) this.form_data.amount = 0
                    else return
                }
              if(this.$refs.form.validate() || reset){
                this.loadingSubmit = true
                axios({
                    method: "put",
                    url: `/api/cabletv/get_user/${this.service_tv.id}/add_credit/`,
                    data: { credit: this.form_data.amount, date_due: this.form_data.date_due}
                })
                  .then(response => {
                  if(response){
                    this.$emit('update-abonent')
                    this.$router.app.$snack.show(reset && "Кредит знято!" || "Кредит додано!")
                    this.closeDialog()
                  }else{
                      this.$router.app.$snack.show("Помилка", 'deep-orange')
                  }
                  })
                  .catch(err => {
                  //console.log(err);
                      this.$router.app.$sheet.show("Помилка!", err);
                  })
                  .finally(() => ( this.loadingSubmit = false ))
                }
            },
            async sendFormTvToInet(){
                this.loadingSubmit = true
                axios({
                    method: "put",
                    url: `/api/cabletv/get_user/${this.service_inet.id}/add_credit/`,
                    data: { credit: this.form_data.amount, date_due: this.form_data.date_due}
                })
                  .then(response => {
                  if(response){
                    this.$emit('update-abonent')
                    this.$router.app.$snack.show(this.form_data.amount == 0 && "Кредит знято!" || "Кредит додано!")
                    this.closeDialog()
                  }else{
                      this.$router.app.$snack.show("Помилка", 'deep-orange')
                  }
                  })
                  .catch(err => {
                  //console.log(err);
                      this.$router.app.$sheet.show("Помилка!", err);
                  })
                  .finally(() => ( this.loadingSubmit = false ))
            }
        }
        
    }
</script>