<template>
    <span>
        <span 
            
            class="d-flex justify-space-between align-center"
            >
            <template v-if="tryGetPass && !passwordBilling">
                <small class="grey--text">Не знайдено</small>
            </template>
            <template v-else>
                {{ passwordBilling || '******' }}
            </template>
            <span>
                <v-btn
                @click="getPassBilling"
                small
                icon
                :loading="loading"
                >
                <v-icon>autorenew</v-icon>
                </v-btn>
                
                <v-btn
                color="primary"
                class="ml-2"
                title="Змінити Пароль"
                small
                icon
                @click="modalChangePass.show()"
                >
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </span>
        </span>
        <span v-if="passwordBillingStatistic && passwordBillingStatistic != passwordBilling">{{ passwordBillingStatistic }} <small class="grey--text">(статистика)</small></span>
    </span>
</template>
<script>
import axios from "axios"
export default {
    props: ["password", "modalChangePass", "abonent_pcode"],
    data: () => ({
        loading: false,
        passwordBilling: "",
        passwordBillingStatistic: "",
        tryGetPass: 0,
        showPassword: false
    }),
    mounted(){
        this.tryGetPass = 0
    },
    watch:{
        password(){
            this.password && this.getPassBilling()
        }
    },
    methods:{
        getPassBilling(){
            this.tryGetPass += 1
            this.loading = true
            axios({
                    method: "post",
                    url: this.$router.app.devUrlBillingAPI,
                    data: {req_cmd: "getUserPassword", pcode: this.abonent_pcode}
                })
                    .then(response => {
                        if(response.data.resp_status == "OK"){
                            this.loading = false
                            this.passwordBilling = response.data.resp_result.password
                            this.passwordBillingStatistic = response.data.resp_result.st_password
                            //console.log(response.data)
                        }else if(response.data.resp_status == "REJECT"){
                            this.loading = false
                            this.$router.app.$snack.show(response.data.resp_errmsg)
                        }else{
                            this.loading = false
                            this.$router.app.$snack.show("Помилка отримання паролю Billing")
                        }
                    })
                    .catch(err => {
                        //console.log(err);
                        this.loading = false
                        this.$router.app.$sheet.show("Помилка! (billing)", err);
                    })
        }
    }
}
</script>
