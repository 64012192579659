<template>
    <span 
        
        class="d-flex justify-space-between align-center"
        >
        <template v-if="password">
            {{ showPassword && password || '******' }}
        </template>
        <template v-else>
            <small class="grey--text">Не задано</small>
        </template>
        <span>
            <v-btn
            @click="showPassword = !showPassword"
            small
            icon
            >
            <v-icon v-if="password">{{ showPassword && 'mdi-eye' || 'mdi-eye-off'}}</v-icon>
            </v-btn>
            
            <v-btn
            color="primary"
            class="ml-2"
            title="Змінити Пароль"
            small
            icon
            @click="modalChangePass.show()"
            >
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </span>
        </span>
</template>
<script>

export default {
    props: ["password", "modalChangePass"],
    data: () => ({
        showPassword: false
    })
}
</script>
