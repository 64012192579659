<template>
  
  <div> 
    <v-row justify="center" class="">
      <v-col  >
        <v-data-table
          :headers="headers"
          loading-text="Завантаження..."
          no-data-text="Немає даних для відображення"
          :server-items-length="paginations.count"
          :items="log"
          :options.sync="options"
          :footer-props="footer"
          :loading="loading"
          :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
          class="elevation-1 row-cursor"
          focusable
          disable-sort
        >
          <template v-slot:top>

            <v-toolbar flat color="secondary lighten-1" dark dense>
              <v-toolbar-title class=""><h3 class="mr-6 accent--text">Лог</h3></v-toolbar-title>
            
              <v-spacer/>
              <v-btn :icon="$vuetify.breakpoint.mobile" :outlined="inetPays && !$vuetify.breakpoint.mobile" small :color="!inetPays ? 'accent' : 'grey'"
              :class="!inetPays ? 'primary--text' : ''"
              @click="inetPays = false, checkGetLog()"
              >
                 <v-icon v-if="$vuetify.breakpoint.mobile">mdi-television</v-icon> 
                 <span v-else>ТБ</span>
              </v-btn>
              <v-btn v-if="service_inet" :icon="$vuetify.breakpoint.mobile" :outlined="!inetPays && !$vuetify.breakpoint.mobile" small :color="inetPays ? 'accent' : 'grey'"
              :class="inetPays ? 'primary--text' : ''"
              class="ml-3"
              @click="inetPays = true, checkGetLog()"
              >
                <v-icon v-if="$vuetify.breakpoint.mobile">mdi-web</v-icon> 
                 <span v-else>Інтернет</span>
              </v-btn>
              <v-spacer />

            </v-toolbar>
            
          </template>

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

        <template #item.index="{ item }">
              <span>{{ log.indexOf(item) + paginations.start_index }}</span>
        </template>
        

        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
            {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      </v-row>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    name: "abonent-tab-log",
    props: ['abonent'],
    data: () => ({
      inetPays: false,
      updateTable: 0,
      log: [],
      paginations: {},
      loading: false,
      options: {},
      headers: [
        { text: '#', value: 'index', sortable: false,  width: 50},
        //{ text: '#', value: 'id', sortable: false,  width: 50},
        { text: 'Дата', value: 'date', },
        { text: 'Змінив', value: 'user', },
        { text: 'Дія', value: 'action', },
        { text: 'Попереднє значення', value: 'action_note', },
        { text: 'Поточне значення', value: 'action_note_now', }
      ],
      footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
      },
    }),
    computed:{
      service_inet() {
        return this.abonent?.user_service?.find(x => x.service == 2)
      },
      service_tv() {
        return this.abonent?.user_service?.find(x => x.service == 1)
      },
    },
    watch: {
      options: {
        handler () {
          this.getLog();
        },
        deep: true,
      },
    },
    activated() {
     
      this.log = []
      this.checkGetLog()
    },
    deactivated(){
      //
    },
    methods: {
      
      checkGetLog(){
        if(this.options.page == 1){
          this.getLog()
        }else{
          this.options.page = 1;
        }
      },
      filterFormat() {
        const temp = {};
        temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
        temp.itemsPerPage = this.options.itemsPerPage;
        temp.page = this.options.page;
        return temp; 
      },
      getLog(){
        if(this.inetPays){
          axios({
              method: "get",
              url: this.$router.app.devUrlBillingAPI,
              params: {req_cmd: "getUserHistory", pcode: this.abonent?.user_pcode, ...this.filterFormat() },
              
          })
          .then( response =>{
            //console.log(response.data)
            if(response.data.resp_status == "OK"){
              this.log = response.data.resp_result.result
              this.paginations = response.data.resp_result.paginations
            }else{
              this.log = []
              this.paginations = {}
            }
          })
          .catch(() => {})
          .finally(() => (this.loading = false)) 
        }else{
        this.loading = true;
        axios.get(`/api/cabletv/get_user_history/${this.$route.params.id}/`, {params: this.filterFormat()}) // this.$route.params.id
        .then( response =>{
          //console.log(response.data)
          this.log = response.data.results
          this.paginations = response.data.paginations
        })
        .catch(() => {
          //console.log(error);
          //this.errored = true;
        })
        .finally(() => (this.loading = false))
      }
      }
    },
  }
</script>

  
<style scoped>
.text-ex-space{
  white-space: nowrap !important /* Запрещаем перенос слов */
}
</style>