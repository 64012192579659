<template>
    <v-text-field 
    title="Сума до сплати або залишок коштів вираховуються з обраної дати до кінця місяця, з урахуванням залишку на рахунку та з повною абонплатою на наступний місяць."
        :loading="loading"
        :background-color="form_data.tarif ? 'accent darken-1' : $vuetify.theme.dark && 'grey darken-2' || 'grey lighten-2' "
        light
        filled
        single-line
        rounded
        hide-details="auto"
        label="Розрахунок суми оплати"
        :prefix="prefixSummForPays"
        v-model="summFormat"
        name="summ_tarif"
        readonly
        append-icon="mdi-magnify"
        class="mt-3"
        :disabled="!form_data.tarif"
        :class="{'custom_prefix_field_ligth' : !$vuetify.theme.dark, 'custom_prefix_field_dark' : $vuetify.theme.dark}"
        @mousedown="getSumm()"
        @click:append="getSumm()"
        >
        </v-text-field>
</template>

<script>
import axios from "axios"
export default {
    name: 'abonent-tarif-summ',
    props: ["form_data", "abonent_id",],
    data: () => ({
        summ: null,
        summFormat: null,
        loading: false
    }),
    computed:{
        prefixSummForPays(){
            if(this.$vuetify.breakpoint.smAndUp) return (this.summ && this.summ > 0) && 'Сума до сплати:  ' || (this.summ || this.summ == 0) && 'Залишок коштів:  ' || ''
            else return (this.summ && this.summ > 0) && 'До сплати:  ' || (this.summ || this.summ == 0) && 'Залишок:  ' || ''
        }
    },
    activated(){
        this.clearSumm()
        this.loading = false
    },

    methods:{
        clearSumm(){
            this.summ = null
            this.summFormat = null
        },
        getSumm(){
            
            let data_send = {
                date_tarif_change: this.form_data.date_tarif_change,
                tarif: typeof(this.form_data.tarif) == 'object' && this.form_data.tarif?.id || this.form_data.tarif,
                service: 1, // #hardcode
            }
            //console.log(data_send)
            if (!data_send.date_tarif_change) return
            if (!data_send.tarif) return

            this.loading = !this.loading

            axios({
                headers: { "Content-type": "application/json; charset=UTF-8" },
                method: 'POST',
                url: `/api/service/change_tarif_calc/${this.abonent_id}/`,
                data: data_send,
            })
            .then(response => {
                //console.log(response);
                this.summ = response.data.amount
                this.summFormat = response.data.amount + ' грн.'
            })
            .catch(err => {
                //reject(err)
                //console.log(err);
                this.clearSumm()
                this.$router.app.$sheet.show("Помилка!", err);
                
                //console.log(this.$route.query.next)
            })
            .finally(() => (this.loading = false))
        }
    }
    
}
</script>
<style>
input[name="summ_tarif"]{
    cursor: pointer !important;
    padding-left: 7px;
}
</style>