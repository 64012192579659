var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"label":_vm.phoneData.memo || ("Телефон " + (_vm.keyPhoneData + 1)),"rules":_vm.textRules || _vm.notRules,"loading":_vm.loading,"hide-details":"auto","type":"text","outlined":"","dense":"","disabled":_vm.globalEventChangePhoneData && !_vm.eventChangePhoneData},scopedSlots:_vm._u([{key:"prepend",fn:function(){return undefined},proxy:true},{key:"append",fn:function(){return [(_vm.phoneData.spam)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(false)?_c('v-chip',_vm._g({attrs:{"color":"red","outlined":"","small":""}},on)):_vm._e(),_c('v-icon',_vm._g({attrs:{"color":"red","small":""}},on),[_vm._v("mdi-email-off-outline")])]}}],null,false,508540630)},[_vm._v(" Розсилка заборонена ")]):_vm._e(),(_vm.phoneData.main)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ml-1",attrs:{"color":_vm.$vuetify.theme.dark ? 'accent' : 'error',"outlined":"","small":""}},on),[_vm._v(" Основний ")])]}}],null,false,2979549270)},[_vm._v(" Основний номер ")]):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3"},on),[_vm._v(" mdi-dots-vertical ")])]}}]),model:{value:(_vm.menuPhone),callback:function ($$v) {_vm.menuPhone=$$v},expression:"menuPhone"}},[_c('v-list',{attrs:{"dense":""}},[(!_vm.phoneData.main)?_c('v-list-item',{on:{"click":function($event){return _vm.menuSettingMain()}}},[_c('v-list-item-title',[_vm._v(" Зробити основним ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.menuSettingSpam()}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.phoneData.spam ? 'Дозволити розсилку' : 'Заборонити розсилку')+" ")])],1),_c('v-edit-dialog',{attrs:{"return-value":_vm.phoneData.memo,"cancel-text":"Ні","save-text":"Так","large":""},on:{"update:returnValue":function($event){return _vm.$set(_vm.phoneData, "memo", $event)},"update:return-value":function($event){return _vm.$set(_vm.phoneData, "memo", $event)},"save":function($event){_vm.menuPhone = false, !_vm.eventChangePhoneData && _vm.sendPhone()},"cancel":function($event){_vm.menuPhone = false}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Примітка","autofocus":""},model:{value:(_vm.phoneData.memo),callback:function ($$v) {_vm.$set(_vm.phoneData, "memo", $$v)},expression:"phoneData.memo"}})]},proxy:true}])},[_c('v-list-item',{key:"4",on:{"click":function($event){1+1}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.phoneData.memo ? 'Змінити примітку' : 'Додати примітку')+" ")])],1)],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":_vm.emitChangePhone}},on),[_vm._v(" "+_vm._s(_vm.phoneData.phone_delete && 'mdi-arrow-u-left-top' || 'mdi-minus')+" ")])]}}])},[_vm._v(" "+_vm._s(_vm.phoneData.phone_delete && 'Скасувати видалення' || 'Видалити номер')+" ")])]},proxy:true}]),model:{value:(_vm.phoneData.phone),callback:function ($$v) {_vm.$set(_vm.phoneData, "phone", $$v)},expression:"phoneData.phone"}}),(_vm.eventChangePhoneData)?_c('v-row',{staticClass:"justify-center mt-2 mb-5"},[(_vm.phoneData.phone_delete)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"red lighten-1","small":"","dark":""},on:{"click":function($event){return _vm.sendPhone(_vm.method='DELETE')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_vm._v(" Видалити ")],1):_c('v-btn',{class:_vm.$vuetify.theme.dark ? '' : 'lighten-1',attrs:{"color":"primary ","small":""},on:{"click":function($event){return _vm.sendPhone()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save ")]),_vm._v(" Зберегти ")],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }