<template>      
  <v-dialog v-model="dialog" class="benefits" persistent :max-width="typeForm == 'change' || showMoreFields ? 1000:500">
    <v-card >
      <v-card-title class="">
        <span class="headline">{{ abonentData.title }}</span>
      </v-card-title>
      <v-card-text :class="$vuetify.breakpoint.smAndDown ? '':''">
      <v-container :class="typeForm == 'change' ? 'mt-1 pt-1' : 'mt-4'">
        <v-form 
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-spacer />
            <v-col cols="12" :md="typeForm == 'change' || showMoreFields ? 5:12">  
              <div v-if="typeForm == 'change'" class="mb-4 text-center">
                  <h4>ІНФОРМАЦІЯ</h4>
              </div>
              <v-row>  
                <v-col v-for="field in fields1" :key="field.name" :cols="field.cols || 12" class="py-2">

                    <!-- Search Street -->
                    <template v-if="field.name == 'street' && !changeForm">
                      
                        <SearchStreet  v-on:search-new-id="setSearchValue" :denseForm="true" :outlinedForm="true" :clearSearchStreet="clearSearchStreet"/>
                      
                    </template>
                    <v-text-field v-else-if="field.name == 'street'"
                      v-model="form_data.street.street"
                      :label="field.title"
                      :name="field.name"
                      hide-details="auto"
                      disabled
                      outlined
                      dense
                    ></v-text-field>
                    <!-- / Search Street -->

                    <v-select v-else-if="field.type == 'select' && (!field.changeDisabled || typeForm == 'add')"
                      v-model="form_data[field.name]"
                      :disabled="changeForm && field.changeDisabled"
                      :rules="field.rules && textRules || notRules"
                      :label="field.title"
                      :items="field.values"
                      hide-details="auto"
                      clearable
                      outlined
                      dense
                    >

                    </v-select>
                    
                    <v-text-field v-else
                      v-model="form_data[field.name]"

                      :disabled="changeForm && field.changeDisabled"
                      :rules="field.rules && textRules || notRules"
                      :filled="field.filled"
                      :label="field.title"
                      :name="field.name"
                      :type="field.type"
                      hide-details="auto"
                      min="0"
                      outlined
                      
                      dense
                    ></v-text-field>
                </v-col>
                <template v-if="typeForm == 'add'">
                <v-col  v-for="field in fields3" :key="field.name" :cols="field.cols || 12" class="py-2">
                  <v-text-field
                      v-model="form_data[field.name]"
                      :disabled="changeForm && field.changeDisabled"
                      :rules="field.rules && textRules || notRules"
                      :filled="field.filled"
                      :label="field.title"
                      :name="field.name"
                      :type="field.type"
                      hide-details="auto"
                      min="0"
                      outlined
                      dense
                    ></v-text-field>
                </v-col>
                </template>
                <v-col cols=12>
                <div v-if="typeForm == 'add'" class="text-center">
                  <v-chip
                    color="primary lighten-1"
                    class="ma-2"
                    
                    label
                    @click="showMoreFields = !showMoreFields"
                  >
                    <v-icon v-if="showMoreFields"  left>
                      mdi-chevron-left
                    </v-icon>
                    {{ showMoreFields ? 'Менше' : 'Більше' }}
                    <v-icon v-if="!showMoreFields"  right>
                      mdi-chevron-right
                    </v-icon>
                  </v-chip>
                </div>
                </v-col>
                
              </v-row>
              
            </v-col>
            
            <v-spacer />
            <v-divider vertical inset/>
            <v-spacer />
            
            <v-col v-if="typeForm == 'change' || showMoreFields" cols="12" md=5 class="pt-3"> 
              
              <div v-if="typeForm == 'change'" class="mb-4 text-center">
                  <h4>ДОДАТКОВА ІНФОРМАЦІЯ</h4>
              </div>
              <v-row>
                <v-col v-for="field in fields2" :key="field.name" :cols="field.cols || 12" class="py-2">
                  
                  <v-checkbox v-if="field.type == 'checkbox'"
                    v-model="form_data[field.name]"
                    :disabled="changeForm && field.changeDisabled"
                    :label="field.title"
                    hide-details="auto"
                    class="mt-0 mb-0"
                  ></v-checkbox>
                  <v-select v-else-if="field.type == 'select'"
                      v-model="form_data[field.name]"
                      :disabled="changeForm && field.changeDisabled"
                      :rules="field.rules && textRules || notRules"
                      :multiple="field.multiple"
                      :label="field.title"
                      :items="field.values"
                      hide-details="auto"
                      clearable
                      outlined
                      dense
                    ></v-select>
                    <v-select v-else-if="field.type == 'select2'"
                      v-model="form_data[field.name]"
                      :disabled="changeForm && field.changeDisabled"
                      :rules="field.rules && textRules || notRules"
                      :multiple="field.multiple"
                      :label="field.title"
                      :items="field.values"
                      item-text="name"
                      item-value="id"
                      hide-details="auto"
                      
                      clearable
                      outlined
                      dense
                      >
                      </v-select>
                  <v-text-field v-else-if="field.name == 'password'"
                    v-model="form_data[field.name]"
                    :disabled="changeForm && field.changeDisabled"
                    :rules="countRules || notRules"
                    :label="field.title"
                    :name="field.name"
                    :type="field.type"
                    hide-details="auto"
                    autocomplete="off"
                    clearable
                    min="0"
                    outlined
                    dense
                    :append-icon="field.type == 'text' && 'mdi-eye' || 'mdi-eye-off'"
                    @click:append="field.type = field.type == 'password' && 'text' || 'password'"
                  ></v-text-field>
                  <v-text-field v-else
                    v-model="form_data[field.name]"
                    :disabled="changeForm && field.changeDisabled"
                    :rules="field.rules && textRules || notRules"
                    :label="field.title"
                    :name="field.name"
                    :type="field.type"
                    hide-details="auto"
                    autocomplete="off"
                    min="0"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                
              </v-row>
              <template v-if="changeForm && abonent && !abonent.is_organization">
                  <v-btn :disabled="countChangeAbonentData > 1"  class="mt-5" small block title="Додати організацію" color="deep-orange lighten-1" outlined @click="$parent.$refs.addOrganizationModal.show('add')">
                    <v-icon left>mdi-plus</v-icon>Додати організацію
                  </v-btn>
                  <v-spacer />
                </template>
            </v-col>
            

            <v-spacer />
          </v-row>
        </v-form>
      </v-container>
      </v-card-text>
      <v-card-actions>
          <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
          <v-spacer />
          <template v-if="!changeForm">
            <v-btn color="primary lighten-1" text @click="reset()">Скинути</v-btn>
            <v-spacer />
          </template>
          
          <v-btn
            :disabled="countChangeAbonentData < 2"
            :loading="loadingSubmit"
            color="primary "
            :class="$vuetify.theme.dark ? '' : 'lighten-1'"
            @click="sendAbonentData"
            >
            Зберегти
          </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>

    import axios from "axios"
    import { mapGetters } from "vuex"
    
	export default {
    name: 'add-abonent-modal',
    components: {
        //
    },
    props: ["abonent"],
		data: () => ({
      showFieldCorpus: false,
      showMoreFields: false,
      addRates: [],
      selfStreers: [],
      loadingSubmit: false,
      changeForm: false,
      item: {},
      abonentData: {},
      defaultAbonentData: {
          add: {title: "Додати Абонента", method: "POST", url: "/api/cabletv/create_user", readonly: false, message: "Абонента створено!"},
          change: {title: "Картка", method: "PATCH", url: "/api/cabletv/get_user/", readonly: true, message: "Данні Абонента змінено!"}
      },
      dialog: false,
      showDialog: 0,
      typeForm: 'add',
      valid: true,
      clearSearchStreet: 0,
      countChangePhones: 0,
      countChangeAbonentData: 0,
      countFinallySend: 0,
      countErrorsSend: 0,
      activatedParrent: true,
      form_data: {},
      defaultFormData:{
          serial_number_pasport: "",
          date_pasport: null,
          user_phones: [],
          full_name: "",
          street: "",
          house: "",
          letter: "",
          flat: '',
          tenand: '',
          corpus: "",
          organization: '',
          entity: false,
          phone: ""


      },
      fields1: [
        {title: "ПІБ",            name: "full_name",            rules: true,  type: "text",     icon: 'mdi-account', },
        {title: "Вулиця",         name: "street",               rules: true,  type: "select",   icon: "mdi-home",    changeDisabled: true, values: []},
        {title: "Будинок",        name: "house",                rules: true,  type: "number",   icon: 'mdi-home',    changeDisabled: true, },
        {title: "Літера",         name: "letter",               rules: false, type: "select",   icon: 'mdi-home',    changeDisabled: true, values: ['а','б','в','г','д','е','ж','з','и','к','л','м','н','о','п',]},
        {title: "Корпус",         name: "corpus",               rules: false, type: "number",   icon: 'mdi-home',    changeDisabled: true, filled: true},
        {title: "Квартира",       name: "flat",                 rules: false, type: "number",   icon: 'mdi-home',    changeDisabled: true, },
        {title: "Наймач",         name: "tenand",               rules: false, type: "number",   icon: 'mdi-home',    changeDisabled: false, filled: true},
        
        
        //{title: "Новаком",        name: "novakom",              rules: false, type: "checkbox", icon: 'mdi-home',    changeDisabled: false, cols: 4},
      ],
      fields2: [
        {title: "Теги",       name: "tags",                     rules: false, type: "select2", multiple: true,   icon: 'mdi-home', changeDisabled: false, values: []},
        {title: "Дільниця",       name: "district",             rules: false, type: "select2",   icon: 'mdi-home', changeDisabled: false, values: []},
        //{title: "Організація",    name: "organization",         rules: false, type: "text",     icon: 'mdi-bookmark', },
        //{title: "Юр.особа",       name: "entity",               rules: false, type: "checkbox", icon: 'mdi-home',    changeDisabled: false, cols: 6},

        {title: "Паспорт (серія)",name: "serial_number_pasport",rules: false, type: "text",     icon: 'mdi-bookmark', },
        {title: "Паспорт (дата)", name: "date_pasport",         rules: false, type: "date",     icon: 'mdi-bookmark', },
        {title: "Логін",          name: "username",             rules: false, type: "text",     icon: 'mdi-bookmark', },
        {title: "Пароль",         name: "password",             rules: false, type: "password", icon: 'mdi-bookmark', },
        {title: "Квартирант",     name: "not_owner",            rules: false, type: "checkbox", icon: 'mdi-home',    changeDisabled: false, cols: 6},

      ],
      fields3: [
        {title: "Телефон",        name: "phone",                rules: false, type: "text",     icon: 'mdi-home',    changeDisabled: true, },
      ],
      
      textRules: [
        v => !!v || "Це поле обов'язкове",
      ],
      countRules: [
          v => (v || '').length >= 6 || !v ||`Мінімум 6 символів`,
          v => (v || '').length <= 16 ||`Максимум 16 символів`,
          
      ],
      notRules: [
        v => !!v || true ,
      ],
            
    }),
    computed: {
      ...mapGetters({
          //streets: "streets/streets"
          districts: "filters/districts",
          districtOfOperator: "filters/districtOfOperator",
          tags: "tags/tags"
      }),
      checkVisable(field){
        let dict_fields = {
          corpus: () => {this.showFieldCorpus}
        }
        return dict_fields[field]()
      }
    },
    mounted(){
      //this.fields1.find(x => x.name == 'street').values = this.streets 
      this.abonentData = JSON.parse(JSON.stringify(this.defaultAbonentData.add));
      //console.log("mounted addAbonentModal")
    },
    watch:{
      form_data:{
        handler () {
            //console.log("form_data GHANGE!")
            this.countChangeAbonentData += 1
            
        },
        deep: true,
      },
      abonent:{
        handler () {
          //console.log('AddAbonentModal chane abonent')
          this.dialog && this.show('change') // Обновляем данные модального окна
        },
        deep: true,
      },
      'form_data.phone'(val){
        //console.log(val)
        if(val == '3') this.form_data.phone = '+3'
        else if(val == '8') this.form_data.phone = '+38'
        else if(val == '0') this.form_data.phone = '+380'
      }

    },
    methods: {
        show(type){
          this.showDialog += 1
          let item = this.abonent
          //console.log(item);
          //this.clearModal()
          this.typeForm = type
          
          
          if(type == "change"){
              this.fields2.find(x => x.name ==  "district").values = this.districtOfOperator(this.abonent.operator_group.id)
              this.fields2.find(x => x.name ==  "tags").values = this.tags
              
              this.abonentData = JSON.parse(JSON.stringify(this.defaultAbonentData.change))
              this.changeForm = true
              this.form_data = JSON.parse(JSON.stringify(item))
              this.deleteFieldsLogPass()
          }
          else{
            this.deleteFieldsDistrict()
              //this.form_data = JSON.parse(JSON.stringify(this.defaultFormData))
          }
          this.countChangeAbonentData = 0
          this.dialog = true;
        },
        validate () {
          if (this.$refs.form.validate()) {
              this.snackbar = true;
          }
        },
        reset () {
          this.$refs.form.reset()
        },
        closeDialog (){
            this.dialog = false
            this.showMoreFields = false
            this.reset()  
        },
        clearModal(){
          this.clearSearchStreet += 1
          this.changeForm = false
          this.abonentData = JSON.parse(JSON.stringify(this.defaultAbonentData.add))
          this.form_data = JSON.parse(JSON.stringify(this.defaultFormData))
          //this.$refs.searchStreet.clearStreet()
          //console.log(this.$refs)
        },
        setSearchValue(val){
          this.form_data.street = val
        },
        getFieldsOfFormData(){
          // убираем пустые поля из объекта form_data
          let temp = {}
          for (let element in this.form_data) {
            if (this.form_data[element] || this.form_data[element] === false || element == 'district') temp[element] = this.form_data[element]
          }
          return temp
        },
        sendAbonentData() {
          //console.log(this.form_data);
          //console.log(this.form_data.flat, 'this flan form data')
          //console.log('ChangeAbonentData')
          if(this.$refs.form.validate()){
            this.loadingSubmit = true;
            
            axios({
              headers: { "Content-type": "application/json; charset=UTF-8" },
              method: this.abonentData.method,
              url: `${this.abonentData.url}${this.abonentData.method == "PATCH" ? this.form_data.id : ''}/`,
              data: this.getFieldsOfFormData(),
            })
            .then(response => {
              //console.log(response); 
              this.$router.app.$snack.show(this.abonentData.message)
              this.typeForm == 'add' && this.$router.push({ name: 'abonent_info', params: { id: response.data.id }}) // Если создали - переходим на абонента
              this.$emit('update-abonent')
              this.closeDialog()
            })
            .catch(err => {
              //console.log(err);
              this.$router.app.$sheet.show("Помилка!", err);
            })
            .finally(() => (this.loadingSubmit = false))
          } 
        },
        deleteFieldsDistrict(){
          //let login = this.fields2.indexOf(this.fields2.find(x => x.name == 'username'))
          //login != -1 && this.fields2.splice(login, 1)
          let district = this.fields2.indexOf(this.fields2.find(x => x.name == 'district'))
          district != -1 && this.fields2.splice(district, 1)
        },
        deleteFieldsLogPass(){
          //let login = this.fields2.indexOf(this.fields2.find(x => x.name == 'username'))
          //login != -1 && this.fields2.splice(login, 1)
          let password = this.fields2.indexOf(this.fields2.find(x => x.name == 'password'))
          password != -1 && this.fields2.splice(password, 1)
        }
    }
}
</script>

<style >

.v-dialog {
    /*overflow-y: scroll;*/
}
/*
.v-dialog::-webkit-scrollbar {
    width: 5px;
    background-color: rgba(46, 46, 46, 0.15);
    border-radius: 5px;
    height: 200px;
}

.v-dialog::-webkit-scrollbar-thumb {
    background: #686868;
    border-radius: 5px;
}*/
</style>