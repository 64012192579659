<template>
        
        <v-dialog v-model="dialog" persistent max-width="400">
            
            <v-card :loading="loadingSubmit">
            <v-card-title class="">
            <span class="headline ">Зміна прив`язки </span>
            </v-card-title>
            <v-alert
              v-if="conflictDataError"
              rounded="0"
              class="my-0 mb-1"
              dense
              type="warning"
            >
              {{ conflictDataError.text_err }}
              <br>
              <b class="mr-2">{{ conflictDataError.address }} </b>
              <v-btn v-if="conflictDataError.pcode" color="primary lighten-2" x-small @click="goToAbonent(conflictDataError.pcode)">Перейти</v-btn>
              <span v-else><br>Оператор: <b>{{ conflictDataError.operator }}</b></span>
            </v-alert>
            <v-card-text>
                
            <v-container class="mt-6">
                <v-row
                    class="d-none mb-6"
                    justify="center"
                >
                
                    <v-btn-toggle
                        v-model="typeConnect"
                        tile
                        color="primary"
                        group
                        mandatory
                    >
                        <v-btn value="IPoE">
                            IPoE
                        </v-btn>

                        <v-btn value="xPON">
                            xPON
                        </v-btn>

                    </v-btn-toggle>
                    
                </v-row>
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>     
                        <v-col v-for="field in fields[typeConnect]" :key="field.name" cols="12" class="py-2">
                            
                            <v-text-field 
                            dense
                            outlined
                            hide-details="auto"
                            :label="field.title"
                            v-model="form_data[field.name]"
                            :name="field.name"
                            :rules="field.rules && textRules || notRules"
                            :type="field.type"
                            :disabled="loadingSubmit"
                            :pattern="field.type == 'text' && '^([A-F0-9]{2}\-){5}[A-F0-9]{2}$'"
                            required
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Змінити</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
	export default {
        name: 'change-change-modal',
        props: ['user_pcode'],
		data: () => ({
            loadingSubmit:false,
            conflictDataError: null,
            item: {},
            dialog: false,
            valid: true,
            session_id: '',
            ip_abonent: '',
            form_data: {
            },
            typeConnect: 'IPoE',
            fields: {
                IPoE: [
                    {title: "MAC комутатора", name: "mac_switch",  rules: true,  type: "text", value: "" },
                    {title: "VLAN", name: "vlan",  rules: true,  type: "number", value: "" },
                    {title: "Порт комутатора", name: "port",  rules: true,  type: "number", value: "" },
                    {title: "MAC абонента", name: "mac_abonent",  rules: true,  type: "text", value: "" },
                ],
                xPON: [
                    {title: "VLAN", name: "vlan",  rules: true,  type: "number", value: "" },
                    {title: "MAC абонента", name: "mac_abonent",  rules: true,  type: "text", value: "" },
                ]
            },
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            macRules: [
                //v => v.match(/([0-9A-F]{2}\-){5}[0-9A-F]{2}/) || 'Невірний формат (FF-FF-FF-FF-FF-FF)'
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            //
        },
        mounted(){
            //
        },
        methods: {
            show(is_pon, session_id, ip_abonent){
                this.session_id = session_id
                this.ip_abonent = ip_abonent
                this.typeConnect = is_pon && 'xPON' || 'IPoE'
                this.dialog = true;
                this.getCallerIdBinding()
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                this.conflictDataError = null
                this.form_data = {}
            },
            sendForm(){
                if(this.$refs.form.validate()){
                    this.conflictDataError = null
                    this.loadingSubmit = true
                    axios({
                        method: "post",
                        url: this.$router.app.devUrlBillingAPI,
                        data: {req_cmd: "changeUserBinding", pcode: this.user_pcode, ...this.form_data, session_id: this.session_id,  ip_abonent: this.ip_abonent }
                    })
                        .then(response => {
                        if(response.data.resp_status == "OK"){
                            this.$emit('update-binding');
                            this.$router.app.$snack.show("Дані прив'язки змінено")
                            this.closeDialog()
                        }else if(response.data.resp_status == "REJECT"){
                            if(response.data.resp_err_conflict){
                                this.conflictDataError = response.data.resp_err_conflict
                            }else this.$router.app.$snack.show(response.data.resp_errmsg, 'deep-orange')
                        }else{
                            this.$router.app.$snack.show("Помилка зміни даних прив'язки", 'deep-orange')
                        }
                        })
                        .catch(err => {
                        //console.log(err);
                            this.$router.app.$sheet.show("Помилка! (billing)", err);
                        })
                        .finally(() => ( this.loadingSubmit = false ))
                }
            },
            getCallerIdBinding(){
                this.loadingSubmit = true
                axios({
                    method: "post",
                    url: this.$router.app.devUrlBillingAPI,
                    data: {req_cmd: "getUserBinding", pcode: this.user_pcode}
                })
                    .then(response => {
                    if(response.data.resp_status == "OK"){
                        this.form_data = response.data.resp_result[0]
                        console.log(response.data.resp_result)
                    }else if(response.data.resp_status == "REJECT"){
                        this.$router.app.$snack.show(response.data.resp_errmsg)
                    }else{
                        this.$router.app.$snack.show("Помилка отримання даних прив'язки")
                    }
                    })
                    .catch(err => {
                    //console.log(err);
                    this.$router.app.$sheet.show("Помилка! (billing)", err);
                    })
                    .finally(() => ( this.loadingSubmit = false ))
            },
            async goToAbonent(pcode){
                let { data } = await axios.get(`/api/cabletv/pcode_find/${pcode}/`)
                if (data.id){
                    window.open(`/ab/${data.id}`, '_blank')
                }
                
            }
            
        }
    }
</script>