<template>
        
        <v-dialog v-model="dialog" persistent max-width="500">
            
            <v-card :loading="loadingSubmit">
            <v-card-title class="">
            <span class="headline ">{{ itemData.title }} </span>
            </v-card-title>
            
            <v-card-text>
                
            <v-container class="mt-6">
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>    
                        <v-spacer />   
                        <v-col v-for="field in fields" :key="field.name" cols="12" md=10 class="py-2">
                            
                            <v-text-field 
                            dense
                            outlined
                            hide-details="auto"
                            :label="field.title"
                            v-model="form_data[field.name]"
                            :name="field.name"
                            :rules="field.rules && textRules && minRules  || notRules"
                            :type="field.type"
                            :disabled="field.disabled"
                            :counter="16"
                            :append-icon="field.type == 'text' && 'mdi-eye' || 'mdi-eye-off'"
                            @click:append="field.type = field.type == 'password' && 'text' || 'password'"
                            >
                            </v-text-field>
                        </v-col>
                        <v-spacer />
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn v-if="service_inet" :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="setPassBilling()">Змінити</v-btn>
                <v-btn v-else :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Змінити</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
	export default {
        name: 'change-pass-modal',
        props: ["user", "abonent", "abonent_id", "service_inet"],
		data: () => ({
            loadingSubmit:false,
            item: {},
            itemData: {},
            defaultItemData: {
                add: {title: "Змінити Пароль", method: "POST", readonly: false, message: "Пароль Змінено!", type: 'change'},
                //change: {title: "Редагування заявки", method: "PATCH", readonly: true, message: "Заявку змінено!", type: 'change'},
            },
            dialog: false,
            valid: true,
            form_data: {},
            fields: [
                {title: "Новий Пароль", name: "password",  rules: true,  type: "password", value: "" },
            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            minRules: [
                v => (v || '').length >= 6 ||`Мінімум 6 символів`
            ],
            maxRules: [
                v => (v || '').length <= 16 ||`Максимум 16 символів`
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            //
        },
        mounted(){
            this.itemData = JSON.parse(JSON.stringify(this.defaultItemData.add));
        },
        methods: {
            show(){
                this.dialog = true;
                
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                this.itemData = JSON.parse(JSON.stringify(this.defaultItemData.add))
                
            },
            setPassBilling(){
                axios({
                    method: "post",
                    url: this.$router.app.devUrlBillingAPI,
                    data: {req_cmd: "changeUserPassword", pcode: this.abonent?.user_pcode, arg: this.form_data.password }
                })
                    .then(response => {
                    if(response.data.resp_status == "OK"){
                        this.setPassword()
                    }else if(response.data.resp_status == "REJECT"){
                        this.loadingSubmit = false
                        this.$router.app.$sheet.show("Помилка", response.data.resp_errmsg)
                    }else{
                        this.loadingSubmit = false
                        this.$router.app.$sheet.show("Помилка", "Помилка зміни паролю Billing")
                    }
                    })
                    .catch(err => {
                    //console.log(err);
                    this.loadingSubmit = false
                    this.$router.app.$sheet.show("Помилка! (billing)", err);
                    })
            },
            setPassword(){
                axios({
                        headers: { "Content-type": "application/json; charset=UTF-8" },
                        method: 'PUT',
                        url: `/api/cabletv/change_password/${this.abonent_id}/`,
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-abonent');
                        this.$router.app.$snack.show(this.itemData.message);
                        this.closeDialog ()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
            },
            sendForm() {
                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;
                    if(this.service_inet){
                        this.setPassBilling()
                    }else{
                        this.setPassword()
                    }
                }
                
            }
        }
    }
</script>