<template>
        
        <v-dialog v-model="dialog" persistent max-width="500">
            
            <v-card>
            <v-card-title>
            <span class="headline ">Повідомлення</span>
            </v-card-title>
            
            <v-card-text>
                
            <v-container class="mt-6">
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>       
                        <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2">
                            <v-select v-if="field.name == 'phone'"
                            dense
                            outlined
                            hide-details="auto"
                            :label="field.title"
                            v-model="form_data[field.name]"
                            :name="field.name"
                            :rules="field.rules && textRules || notRules"
                            :items="abonent && abonent.user_phones || []"
                            item-text="phone"
                            item-value="phone"
                            :disabled="field.disabled"
                            >
                            </v-select>
                            <v-select v-if="field.name == 'template'"
                            dense
                            outlined
                            hide-details="auto"
                            :label="field.title"
                            v-model="form_data[field.name]"
                            :name="field.name"
                            :rules="field.rules && textRules || notRules"
                            :loading="loadingTemplates"
                            :items="templatesMessage"
                            item-text="describe"
                            item-value="id"
                            :disabled="field.disabled"
                            >
                            </v-select>

                            
                        </v-col>
                        <v-col v-if="form_data.template" cols="12" class="py-2">
                            <v-textarea 
                                dense
                                rows="6"
                                outlined
                                hide-details="auto"
                                label="Повідомлення"
                                :value="createTextTemplate"
                                type="textarea"
                                readonly
                            ></v-textarea>
                        </v-col>
                            
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Надіслати</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    export default {
        name: 'add-message-modal',
        props: ["abonent"],
        data: () => ({
            loadingSubmit:false,
            loadingTemplates: false,
            dialog: false,
            valid: true,
            templatesMessage: [],
            form_data: {},
            fields: [
                {title: "Телефон",    name: "phone",     rules: true,  type: "select",    disabled: false, value: "",},
                {title: "Шаблон", name: "template",     rules: true,  type: "select", disabled: false, value: "", values: [] },
            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            createTextTemplate(){
                let template = this.templatesMessage.find(x => x.id == this.form_data.template)
                return `${template.text_sms} \n\nДля Viber: \n${template.text_viber}`
            },
        },
        mounted(){
            //
        },
        methods: {
            show(phone){
                if (this.templatesMessage.length == 0) this.getTemplates()
                if(phone) this.form_data.phone = phone
                this.dialog = true
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset(){
                this.$refs.form.reset()
            },
            closeDialog (){
                this.dialog = false
                this.reset()  
            },
            
            sendForm() {
                //console.log(this.form_data)
                
                    this.loadingSubmit = true;
                    axios({
                        method: "POST",
                        url: `${this.$router.app.devUrlServiceSms}/api_messages/one_message/`,
                        data: { phone: this.form_data.phone, template: this.form_data.template, user_id: this.abonent.id }
                    })
                    .then(() => {
                        this.$router.app.$snack.show("Повідомлення створено");
                        this.closeDialog()
                    })
                    .catch(err => { this.$router.app.$sheet.show("Помилка!", err) })
                    .finally(() => (this.loadingSubmit = false))
                
            },
            getTemplates() {
                this.loadingTemplates = true;
                axios.get(`${this.$router.app.devUrlServiceSms}/api_messages/get_regular_template/`)
                .then(response => { this.templatesMessage = response.data })
                .catch(() => { })
                .finally(() => (this.loadingTemplates = false))
            },
        }
    }
</script>