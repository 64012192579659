import Vue from 'vue'
/** vue inspect
 * Подключаем компоненты
 */
const componentContext = require.context('', false, /.*\.vue$/)

componentContext.keys()
  .map(file => [file.replace(/(^.\/)|(\.vue$)/g, ''), componentContext(file)])
  .forEach(([name, component]) => {
    Vue.component(name, component.default || component)
  })